$resolution: 4;

$ratio: ".ratio";
$expander: "expander";
$content: "content";
$divider: "x";

@mixin create-expanders($breakpoint) {
  @for $i from 1 through $resolution {
    @for $j from 1 through $resolution {
      @if ($i != $j or $i == 1) {
        &__#{$expander}--#{$breakpoint}-#{$i}#{$divider}#{$j} {
          padding-bottom: percentage($j / $i);
        }
      }
    }
  }
}

#{$ratio} {
  position: relative;

  &__#{$expander} {
    height: 0;
  }

  @each $breakpoint, $size in $breakpoints {
    @include from-breakpoint($breakpoint) {
      @include create-expanders($breakpoint);
    }
  }

  &__#{$content} {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
