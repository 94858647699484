.trophy-list {
	$block: &;
	$gap--v: 30px;
	$gap--h: 60px;
	padding: 0.07px 0;

	&__inner {
		margin: -$gap--v/2 (-$gap--h/2);
		padding: 0;
		list-style: none;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
	}

	&__item {
		padding: $gap--v/2 $gap--h/2;
		width: 100%;
		display: flex;
		justify-content: center;
	}

	@include from-breakpoint("sm") {
		&__item {
			width: 50%;

			&:first-child {
				width: 100%;

				> .trophy {
					flex: 0 0 auto;
					width: 50%;
				}
			}
		}
	}
}

.trophy {
	$block: &;

	color: $color-trophy;
	display: flex;
	flex: 1 1 auto;
	justify-content: space-between;
	align-items: center;
	max-width: 400px;

	&__text {
		font-family: $font-primary;
		font-size: 18px;
		line-height: 1.44;
		text-align: center;
		text-decoration: none;
		color: inherit;

		&:link:hover {
			text-decoration: underline;
		}
	}

	&__place {
		font-weight: bold;
		font-size: 22px;
	}

	&__branch {
		flex: 0 0 auto;
		width: 42px;
		height: 94px;

		&--left {

		}

		&--right {

		}
	}
}
