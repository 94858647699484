@import '../common/_shared.scss';

.switch {
	$switch: &;

	display: inline-flex;
	align-items: flex-start;
	position: relative;
	min-height: 22px;
	vertical-align: top;
	cursor: pointer;

	input {
		/* Remove the margin for input in Firefox and Safari. */
		margin: 0;
		/* Add the correct box sizing for [type="checkbox"] in IE 10-. */
		box-sizing: border-box;
		/* Remove the padding for [type="checkbox"] in IE 10-. */
		padding: 0;

		position: absolute;
		width: 100%;
		height: 100%;
		top: 0;
		left: 0;
		opacity: 0.0001;
		z-index: 1;
		cursor: pointer;

		&:disabled,
		&[disabled] {
			cursor: default;
		}
	}

	&__toggler {
		display: inline-block;
		position: relative;

		height: 22px;
		width: 40px;

		margin-top: 0;
		margin-right: 11px;
		flex-grow: 0;
		flex-shrink: 0;
		vertical-align: middle;

		&:before,
		&:after {
			display: block;
			position: absolute;
			content: '';
			box-sizing: border-box;
		}

		// toggler track
		&:before {
			transition: background 100ms ease-in-out;

			height: 22px;
			left: 0;
			right: 0;
			border-radius: 22px * 0.5;
			background-color: $color-light;
		}

		// toggler handle
		&:after {
			width: 22px;
			height: 22px;

			top: 0;
			left: 0;

			border-width: 1px;
			border-radius: 50%;
			border-style: solid;
			background-color: $color-light;
      border-color: $color-dark;
      
      transition: transform 100ms ease-in-out;
		}
	}
	&__label {
		margin: 0 11px;
		font-size: 20px;
		line-height: 1;
		color: $color-dark;
		font-weight: normal;
	}

	input:checked ~ #{$switch}__toggler {
		&:before {
			background-color: $color-brand-primary;
		}
		&:after {
			background-color: $color-light;
			transform: translateX(18px);
		}
	}

	&:hover {
		#{$switch}__toggler:before {
			background-color: $color-gray--ll;
		}
		input:checked ~ #{$switch}__toggler {
			&:before {
				background-color: $color-quaternary--d;
			}
		}
	}

	&:focus {
		outline: none;
	}
}
