.tags {
	$color: #23256a;
	$tags: &;

	display: flex;
	flex-flow: row wrap;
	color: $color;

	@include from-breakpoint('md') {
		flex-wrap: nowrap;
	}

	&__tag {
		box-sizing: border-box;
		font-weight: 600;
		font-size: 11px;
		line-height: 11px;
		margin-bottom: 14px;
		margin-top: 0;
		margin-right: 15px;

		@include from-breakpoint('md') {
			font-size: 14px;
			line-height: 18px;
			margin-right: 30px;
		}

		a {
			color: inherit;
			padding: 7px 12px;
			border: 1px solid $color;
			border-radius: 8px;
		}

		span {
			font-weight: inherit;
			font-size: inherit;
			line-height: inherit;
		}

		& + & {
			margin-top: 0;
		}

		//&:hover {
		//	color: $color-primary;
		//	a {
		//		border-color: $color-primary;
		//	}
		//}
  }


  &--contrast {
    color: inherit;
  }

  &--quiet {
    #{$tags}__tag {
      a {
        border: none;
      }
    }
  }
}
