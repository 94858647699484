@import './shared.scss';

.team-list {
	margin-left: $team-gutter * -0.5;
	margin-right: $team-gutter * -0.5;
	margin-bottom: 48px;

	@include from-breakpoint('sm') {
		display: flex;
		flex-wrap: wrap;
	}

	&__member {
		display: flex;
		@include from-breakpoint('sm') {
			display: block;
			width: calc(50% - #{$team-gutter});
		}
		@include from-breakpoint('md') {
			width: calc(33.33% - #{$team-gutter});
		}
		@include from-breakpoint('lg') {
			width: calc(25% - #{$team-gutter});
		}
		margin-left: $team-gutter * 0.5;
		margin-right: $team-gutter * 0.5;
		margin-bottom: 24px;
		text-decoration: none;
		color: inherit;
	}

	&__preview {
		min-width: 64px;
		flex-basis: 64px;
		height: 64px;
		@include from-breakpoint('sm') {
			height: 0;
			padding-bottom: 100%;
		}
		position: relative;
		background: #ccc;
		overflow: hidden;
	}

	&__summary {
		flex-grow: 1;
		margin-left: 16px;
		@include from-breakpoint('sm') {
			margin-left: 0;
			margin-top: 16px;
		}
	}

	&__name {
		font-size: 20px;
		font-weight: bold;
		margin-top: 4px;
		margin-bottom: 4px;
	}

	&__position {
		font-size: 18px;
	}
}
