// Hamburger
// ==================================================
.hamburger {
	padding: $hamburger-padding-y $hamburger-padding-x;
	display: inline-block;
	cursor: pointer;

	transition-property: opacity, filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;

	// Normalize (<button>)
	font: inherit;
	color: $color-light;
	text-transform: none;
	background-color: $color-black;
	border: 0;
	margin: 0;
	overflow: visible;

	@include from-breakpoint('md') {
		padding: $hamburger-padding-y--md $hamburger-padding-x--md;
	}

	&:hover {
		@if $hamburger-hover-use-filter == true {
			filter: $hamburger-hover-filter;
		} @else {
			opacity: $hamburger-hover-opacity;
		}
	}

	&.is-active {
		&:hover {
			@if $hamburger-hover-use-filter == true {
				filter: $hamburger-active-hover-filter;
			} @else {
				opacity: $hamburger-active-hover-opacity;
			}
		}

		.hamburger-inner,
		.hamburger-inner::before,
		.hamburger-inner::after {
			background-color: $hamburger-active-layer-color;
		}
	}
}

.hamburger-box {
	width: $hamburger-layer-width;
	height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
	display: inline-block;
	position: relative;
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: $hamburger-layer-height / -2;

	&,
	&::before,
	&::after {
		width: $hamburger-layer-width;
		height: $hamburger-layer-height;
		background-color: $hamburger-layer-color;
		border-radius: $hamburger-layer-border-radius;
		position: absolute;
		transition-property: transform;
		transition-duration: 0.15s;
		transition-timing-function: ease;
	}

	&::before,
	&::after {
		content: '';
		display: block;
	}

	&::before {
		top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}

	&::after {
		bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
	}
}
