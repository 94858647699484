$header-height-per-breakpoints: (
	xs: $custom__header-height,
	sm: $custom__header-height,
	md: $custom__header-height--md,
	lg: $custom__header-height--md,
	xl: $custom__header-height--md,
	xxl: $custom__header-height--md,
);

.scene {
	position: relative;

	$joined-breakpoints-selector: ();

	&__fixer {
		// https://github.com/philipwalton/flexbugs#flexbug-3
		display: flex;
		flex-flow: column nowrap;
	}

	@each $breakpoint, $size in $breakpoints {
		&--#{$breakpoint} {
			@include from-breakpoint($breakpoint) {
				min-height: 100vh;
				padding: #{map-get($header-height-per-breakpoints, $breakpoint)} 0;
			}
		}
	}

	&--compact {
		min-height: 0;
	}

	&--xs, &--sm {
		@include from-breakpoint("md") {
			padding: $custom__header-height--md 0;
		}
	}
}
