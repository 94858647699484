.card {
	display: block;
	height: 100%;
	color: $color-text;
	background: $color-background;
	text-decoration: none;
	box-shadow: 0 $base * 0.25 $base * 0.5 0 rgba(0, 0, 0, 0.16);
	transition-property: transform, box-shadow;
	transition-duration: 0.2s;
	transition-timing-function: ease;

	&:hover,
	&:focus {
		transform: translate(0, -8px);
		box-shadow: 0 $base * 0.5 $base rgba(0, 0, 0, 0.16);
	}

	&__preview {
		position: relative;
		overflow: hidden;
		padding-bottom: 178.44 / 288.66 * 100%;
	}

	&__image {
		position: absolute;
		overflow: hidden;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__details {
		padding-bottom: $base * 1.5;
		padding-top: $base * 0.5;
	}

	&__info {
		display: flex;
		justify-content: space-between;
	}

	&__stage {
		margin: $base * 0.25 $base;
	}

	&__date {
		display: block;
		font-size: $base * 0.88;
		color: $color-text--muted;
	}

	&__author {
		font-size: $base * 0.88;
		color: $color-text--muted;
		text-align: right;
	}

	&__title {
		font: 600 20px/1 $font-primary;

		@include from-breakpoint('lg') {
			font-size: 24px;
		}
	}

	&__teaser {
		margin-top: $base * 0.75;
		font-size: $base * 0.88;
		color: $color-text--muted;
	}
}
