.advantages {
	position: relative;
	z-index: 10;
	padding: 80px 0;
	background-color: $color-black;

	@include from-breakpoint('md') {
		padding: 120px 0;
	}

	@include from-breakpoint('xl') {
		padding: 160px 0;
	}

	&__title {
		margin-bottom: 50px;
		width: 100%;
		aspect-ratio: 8.3;
		overflow: hidden;
		clip-path: url(#svgPathAdvantagesTitle);

		img {
			position: relative;
			z-index: 1;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		@include from-breakpoint('md') {
			margin-bottom: 60px;
		}

		@include from-breakpoint('xl') {
			margin-bottom: 85px;
		}
	}

	&__video-container {
		position: relative;
		top: -100%;
		width: 100%;
		height: 100%;

		video {
			display: block;
		}
	}
	&__fallback-image {
		display: none;
		width: 100%;
		height: auto;
	}

	&__content-wrapper {
		border-top: 1px solid rgba($color-light, 0.2);

		@include from-breakpoint('xl') {
			border: none;
		}
	}

	&__list {
		@include reset-list;

		padding-top: 40px;
		display: flex;
		flex-direction: column;
		gap: 32px;

		@include from-breakpoint('md') {
			flex-direction: row;
			flex-wrap: wrap;
			row-gap: 40px;
			column-gap: 24px;
		}

		@include from-breakpoint('xl') {
			border-top: 1px solid rgba($color-light, 0.2);
		}
	}

	&__item {
		@include from-breakpoint('md') {
			width: 31%;
		}
	}

	&__number {
		@include body-1;

		margin-bottom: 20px;
		padding: 17px;
		width: 60px;
		height: 60px;
		display: flex;
		align-items: center;
		justify-content: center;
		color: $color-light;
		border: 1px solid rgba($color-light, 0.2);
		border-radius: 50%;
	}

	&__description {
		@include body-1;

		color: $color-light;
	}
}
