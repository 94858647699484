.showcase {
	$block: &;

	padding: 100px $base 200px;
	// overflow: hidden;

	&__item-fixer {
		// https://github.com/philipwalton/flexbugs#flexbug-3
		display: flex;
		flex-flow: column nowrap;
		margin-bottom: 64px;

		&:nth-child(1) #{$block}__cover {
			background-color: $color-brand-primary-new;
			color: $color-dark-1;
		}

		&:nth-child(2) #{$block}__cover {
			background-color: $color-dark-1;
			color: $color-brand-primary-new;
		}

		&:nth-child(3) #{$block}__cover {
			background-color: $color-dark-1;
			color: $color-brand-primary-new;
		}
	}

	&__item {
		display: flex;
		align-items: center;
		position: relative;
		flex: 0 0 auto; // https://github.com/philipwalton/flexbugs#flexbug-3
	}

	&__card {
		position: relative;
		border-top: 1px solid $color-gray--dd;
	}

	&__cover {
		position: relative;
		background-repeat: no-repeat;
		background-size: 100% 100%;

		img {
			width: 100%;
			height: 100%;
			display: block;
			object-fit: cover;
		}

		.picture {
			transition: transform 0.3s;
			transform: scale(0.75);
			overflow: hidden;

			#{$block}__item:hover & {
				transform: scale(1);

				@include from-breakpoint(xl) {
					transform: scale(1);
				}
			}
		}
	}

	&__icon {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__details {
		display: flex;
		flex-flow: column nowrap;
		padding: 24px 0 0;
		color: inherit;
		text-decoration: none;

		@include from-breakpoint('md') {
			padding: 24px 50px 32px 0;
		}
	}

	&__title {
		margin: 0 0 10px;
		font-size: 32px;
		font-weight: 600;
		font-family: $font-primary;
		line-height: 110%;
		transition: color 0.3s;
	}

	&__section {
		@include from-breakpoint('md') {
			margin-bottom: $base * 2;
		}
	}

	&__section-content {
		line-height: 1.6;
	}

	&__awards {
		display: none;
		position: absolute;
		right: -26px;
		top: -26px;

		@include from-breakpoint('lg') {
			display: block;
		}

		&-icon {
			width: 52px;
			height: 52px;
			background-image: url('../images/award.png');
			background-repeat: no-repeat;
			background-size: contain;
		}
	}

	&__row {
		color: inherit;
		text-decoration: none;

		@include before-breakpoint('md') {
			flex-direction: column-reverse;
			flex-wrap: nowrap;
		}

		&:hover {
			#{$block}__title {
				color: $color-primary;
			}
		}
	}

	&__cta {
		position: relative;
		margin-top: 160px;
		padding-right: 30px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		gap: 20px;
		color: $color-black;
		text-decoration: none;

		@include from-breakpoint('md') {
			margin-top: 165px;
			padding-left: 95px;
			padding-right: 50px;
		}

		@include from-breakpoint('lg') {
			margin: 0 50px 0 140px;
			padding: 0;
			transform-style: preserve-3d;
			perspective: 50px;
			will-change: transform;
			transition: transform $transition-default;
		}

		@include from-breakpoint('xl') {
			margin-left: 120px;
			margin-right: 0;
		}

		&:hover,
		&:focus-visible {
			&::before {
				transform: translate3D(-2%);
			}

			#{$block}__cta-text {
				color: $color-contrast;
			}
		}

		svg {
			margin-top: 15px;
			width: 60px;
			height: 60px;

			@include from-breakpoint('md') {
				margin-top: 0;
				width: 200px;
				height: 220px;
			}

			@include from-breakpoint('lg') {
				margin-right: 25px;
				width: 180px;
				height: 190px;
				transform: translateZ(-10px);
				transition: transform $transition-default;
			}
		}
	}

	&__cta-bg {
		z-index: -1;
		position: absolute;
		right: 0;
		top: -66px;
		width: 250px;
		height: 220px;
		display: block;
		background-color: $color-brand-primary-new;

		@include from-breakpoint('md') {
			top: -40px;
			right: 20px;
			width: 500px;
			height: 300px;
		}

		@include from-breakpoint('lg') {
			top: -135px;
			right: -340px;
			width: 1180px;
			height: 465px;
			transition: transform $transition-default;
			transform: translateZ(-50px);
		}

		@include from-breakpoint('xl') {
			width: 1280px;
			height: 504px;
			right: -260px;
			top: -195px;
			height: 600px;
		}
	}

	&__cta-text {
		@include h2;

		text-transform: uppercase;
		transition: color $transition-default;

		@include from-breakpoint('md') {
			max-width: 302px;
		}

		@include from-breakpoint('lg') {
			transition: transform $transition-default;
			transform: translateZ(-10px);
		}

		@include from-breakpoint('xl') {
			max-width: 416px;
		}
	}
}
