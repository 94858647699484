@import './shared.scss';

.team-popup {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.98);
	z-index: 101;
	display: none;
	overflow: auto scroll;

	&--active {
		display: block;
		@include from-breakpoint('sm') {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}

	&__content {
		width: 100%;
		max-width: 960px;
		padding: 0 $team-gutter * 0.5;
	}

	&__header {
		position: relative;
		padding-top: 24px;
		padding-bottom: 24px;
	}

	&__name {
		font: 600 24px/1.33 $font-secondary;
		@include from-breakpoint('sm') {
			font-size: $h1-font-size--lg;
		}
		letter-spacing: 0.02em;
	}

	&__position {
		font-size: 16px;
		color: $color-gray--d;
	}

	&__body {
		margin-left: $team-gutter * -0.5;
		margin-right: $team-gutter * -0.5;
		@include from-breakpoint('sm') {
			display: flex;
		}
	}

	&__preview-social {
		@include from-breakpoint('sm') {
			min-width: calc(50% - #{$team-gutter});
			flex-basis: calc(50% - #{$team-gutter});
		}
		@include from-breakpoint('lg') {
			min-width: calc(33% - #{$team-gutter});
			flex-basis: calc(33% - #{$team-gutter});
		}

		margin-left: $team-gutter * 0.5;
		margin-right: $team-gutter * 0.5;
	}

	&__preview {
		height: 0;
		padding-bottom: 100%;
		position: relative;
		background: #ccc;
		overflow: hidden;
		margin-bottom: 16px;
		@include from-breakpoint('sm') {
			margin-bottom: 24px;
		}
	}

	&__social {
		margin: 16px 0;
		@include from-breakpoint('sm') {
			margin: 24px 0;
		}
		justify-content: flex-start !important;
	}
	&__social-link {
		margin-right: 24px;
	}

	&__summary {
		flex-grow: 1;
		margin-left: $team-gutter * 0.5;
		margin-right: $team-gutter * 0.5;
		@include from-breakpoint('sm') {
			font-size: 20px;
		}
		p {
			margin-top: 0;
		}
	}

	&__close {
		position: absolute;
		right: 0;
		top: 30px;
		width: 14px;
		height: 14px;
		@include from-breakpoint('sm') {
			top: 36px;
			width: 24px;
			height: 24px;
		}
		fill: black;
		color: black;
	}
}
