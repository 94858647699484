.tags {
	font-size: 0;

	&__inner {
		margin: -4px;
		padding: 0;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
	}

	&__cell {
		padding: 4px;
	}
}

.tag {
	$block: &;

	user-select: none;
	display: inline-block;

	&__label {
		display: inline-block;
		cursor: pointer;
		border-radius: 8px;
		font-size: 14px;
		padding: 8px 12px;
		border: solid 1px currentColor;
		color: $color-text--muted;
		font-family: $font-primary;
		font-weight: 500;
		transition-duration: 300ms;
		transition-property: color, background, border-color, border-radius;
		transition-timing-function: ease;
	}

	&--radio {
		#{$block} {
			&__label {
				&:hover {
					border-color: $color-tertiary;
					color: $color-tertiary;
				}
			}
		}

		input {
			@include hidden-accessible;

			&:checked + #{$block}__label {
				border-color: $color-tertiary;
				background: $color-tertiary;
				color: $color-background;
			}
		}
	}

	&--current-color {
		color: inherit;

		#{$block}__label {
			color: currentColor;
		}
	}

	&--light {
		#{$block}__label {
			color: $color-background;
		}
	}

	&--dark {
		#{$block}__label {
			color: $color-dark;

			&:hover {
				border-radius: 8px 8px 0 0;
				border-top-color: transparent;
				border-left-color: transparent;
				border-right-color: transparent;
			}
		}
	}

	&--transparent-to-dark {
		#{$block}__label {
			color: $color-dark;
			border-color: transparent;
		}

		&:hover,
		&#{$block}--active {
			#{$block}__label {
				border-color: $color-dark;
			}
		}
	}

	&--link {
		&:focus,
		&:hover {
			#{$block}__label {
				border-radius: 8px 8px 0 0;
				border-top-color: transparent;
				border-left-color: transparent;
				border-right-color: transparent;
			}
		}
	}

	&--underline {
		#{$block}__label {
			border: none;
			position: relative;

			&::after {
				content: '';
				display: block;
				position: absolute;
				left: 50.5%;
				right: 50.5%;
				height: 2px;
				background-color: currentColor;
				transition-property: left, right;
				transition-duration: 0.3s;
				transition-timing-function: ease;
				bottom: 0;
			}
		}

		&:hover {
			#{$block}__label {
				&::after {
					left: 38%;
					right: 38%;
				}
			}
		}
		&:focus {
			#{$block}__label {
				&::after {
					left: 38%;
					right: 38%;
				}
			}
		}
		&#{$block}--active {
			#{$block}__label {
				&::after {
					left: 0;
					right: 0;
				}
			}
		}
	}

	&--hash {
		#{$block} {
			&__label {
				border: none;
				padding: 0;

				&::before {
					content: '# ';
				}
			}
		}
	}
}
