@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
	$font-path: '~../fonts/';

	@font-face {
		font-family: quote($font-name);
		src: url($font-path+$file-name+'.woff2') format('woff2'),
			url($font-path+$file-name+'.woff') format('woff'),
			url($font-path+$file-name+'.otf') format('opentype');
		font-weight: $weight;
		font-style: $style;
		font-display: swap;
	}
}

@include font-face('TT-Firs-Neue', 'TT_Firs_Neue_Normal', 400);
@include font-face('TT-Firs-Neue', 'TT_Firs_Neue_DemiBold', 600);
@include font-face('TT-Firs-Neue', 'TT_Firs_Neue_DemiBold_Italic', 600, italic);
