.homepage-social {
	row-gap: 24px;

	&__link {
		@include button-text;
		height: 60px;
		padding: 20px;
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: black;
		color: white;
		text-decoration: none;
		text-transform: uppercase;
		border: 1px solid black;
		transition: background-color 0.3s, color 0.3s;

		&-vc svg {
			width: 22px;
			height: 20px;
		}

		&-habr svg {
			width: 50px;
			height: 20px;
		}

		&-blog svg {
			height: 20px;
			width: 14px;
			margin-left: 10px;
		}

		&:hover {
			background-color: transparent;
			color: black;
		}
	}
}
