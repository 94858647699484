@import '../common/_shared.scss';

.tabs {
	&__controls {
		margin-left: auto;
		margin-right: auto;

		display: flex;
		justify-content: flex-start;
		flex-flow: row nowrap;

		overflow-x: scroll;
		overflow-y: visible;
		white-space: nowrap;

		margin-bottom: 36px;
		max-width: 100%;
		width: 100%;
		height: 32px;

		@include from-breakpoint('sm') {
			width: auto;
			overflow: visible;
			justify-content: center;
		}
	}
	&__control {
		cursor: pointer;
		padding: 0 10px;
		position: relative;
		height: 24px;

		&::after {
			bottom: -8px;
			left: 0;
			content: '';
			position: absolute;

			background-color: $color-dark;
			width: 100%;
			height: 2px;
			transform: scaleX(0);
			transform-origin: center;

			transition-property: transform;
			transition-duration: 0.3s;
			transition-timing-function: ease;
		}

		& + & {
			margin-top: 0;
			margin-left: 38px;
		}

		&:hover {
			&::after {
				transform: scaleX(0.3);
			}
		}

		&--active {
			&::after {
				transform: scaleX(1);
			}
			&:hover {
				&::after {
					transform: scaleX(1);
				}
			}
		}
	}
	&__content {
		display: none;

		&--active {
			display: block;
		}
	}
}
