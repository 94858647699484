.rates {
	$block: &;

	margin-bottom: 80px;

	@include from-breakpoint('lg') {
		margin-bottom: 120px;
	}

	&__title {
		@include h2;

		margin: 0;
		margin-bottom: 65px;
		text-transform: uppercase;

		@include from-breakpoint('md') {
			margin-bottom: 55px;
		}
	}

	&__list {
		@include reset-list;
	}

	&__item {
		margin-bottom: 50px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__subtitle {
		margin: 0;
		margin-bottom: 14px;
		font-size: 24px;
		font-weight: 600;
		line-height: 1.2;
	}

	&__container {
		display: flex;
		flex-direction: column;
		gap: 24px;

		@include from-breakpoint('md') {
			flex-direction: row;
			justify-content: flex-start;
		}
	}

	&__block {
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		&:last-child {
			@include from-breakpoint('md') {
				#{$block}__first-col {
					display: none;
				}

				#{$block}__second-col,
				#{$block}__period {
					margin-left: 30px;
				}

				#{$block}__period {
					padding-left: 0;
				}
			}
		}

		@include from-breakpoint('md') {
			width: 50%;
		}

		@include from-breakpoint('lg') {
			width: min-content;
		}
	}

	&__period {
		@include body-2;

		height: 48px;
		padding-left: 132px;
		display: flex;
		align-items: center;
	}

	&__line {
		display: flex;
		align-items: center;
		gap: 2px;
		flex-basis: 100%;
		height: 48px;

		&--caption {
			@include caption;

			height: 36px;
			color: $color-caption;
		}
	}

	&__first-col {
		width: 135px;
	}

	&__second-col {
		width: 100px;
	}

	&__third-col {
		width: 110px;
	}
}
