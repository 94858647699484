.pagination {
	$block: &;
	display: flex;
	justify-content: center;

	&__item {
		display: block;
		font-size: 14px;
		padding: $base * 0.25 $base * 0.75;
		color: $color-text--muted;
		text-decoration: none;

		&--active {
			color: $color-text;
			box-shadow: inset 0 0 0 1px $color-primary;
		}
  }
}
