.case-list {
	$block: &;
	$vertical-offset: 65px;

	display: block;
	// padding-bottom: $vertical-offset;

	&__list {
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 0;
		display: flex;
		flex-flow: row wrap;

		.cssgrid & {
			display: grid;
			/* autoprefixer: ignore next */
			grid: 342px / repeat(auto-fill, minmax(480px, 1fr));
		}
	}

	&__slot {
		& + & {
			margin-top: $vertical-offset;
		}
	}

	&__item {
		//min-width: 475px;
		//width: 50%;
		flex: 1 0 480px;
		//width: 1 / 3 * 100%;
		//flex: 0 0 (1 / 3 * 100%);
	}

	&__more {
		text-align: center;
	}

	&__typography {
	}
}
