.embed {
	$block: &;

	position: relative;
	padding-bottom: 9 / 16 * 100%;

	> * {
		position: absolute;
		width: 100%;
		height: 100%;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
	}
}
