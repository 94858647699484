@import '../common/_shared.scss';

.achievements {
	$this: &;

	padding: 0 0 160px;

	&--team {
		padding: 0;
	}

	&--flat {
		padding: 0;
	}

	&__highlight {
		list-style: none;
		padding: 0;
		margin: 0 0 50px;
		display: flex;
		flex-wrap: wrap;
		gap: 24px;

		@include from-breakpoint('md') {
			align-items: flex-end;
			flex-wrap: nowrap;
		}

		@include from-breakpoint('lg') {
			margin-bottom: 80px;
		}
	}

	&__highlight-item {
		position: relative;
		padding: 25px;
		min-height: 250px;
		display: flex;
		flex-direction: column;
		gap: 20px;
		justify-content: flex-end;
		aspect-ratio: 0.65;
		color: $color-light;

		@include from-breakpoint('md') {
			flex-grow: 1;
		}

		&:first-child {
			width: 100%;
			gap: 40px;
			aspect-ratio: 1;
			color: $color-black;
			background-color: #f3f3f3;

			#{$this}__highlight-bottom-image {
				width: 132px;
				height: 143px;
				margin-right: 24px;
			}

			@include from-breakpoint('md') {
				width: calc(42% - 12px);
				height: 400px;
				gap: 50px;
				aspect-ratio: unset;

				#{$this}__highlight-bottom-image {
					width: 145px;
					height: 157px;
				}
			}

			@include from-breakpoint('lg') {
				height: 508px;

				#{$this}__highlight-bottom-image {
					width: 222px;
					height: 240px;
				}
			}

			@include from-breakpoint('xl') {
				width: calc(46% - 12px);
				height: 568px;

				#{$this}__highlight-bottom-image {
					width: 319px;
					height: 345px;
					margin-left: 118px;
				}
			}
		}

		&:nth-child(2n) {
			width: calc(50% - 12px);
			background-color: #c8af72;

			@include from-breakpoint('md') {
				width: calc(25% - 12px);
				height: 320px;
				gap: 20px;
				aspect-ratio: unset;
			}

			@include from-breakpoint('lg') {
				height: 400px;
			}

			@include from-breakpoint('xl') {
				height: 480px;
			}
		}

		&:nth-child(3n) {
			width: calc(50% - 12px);
			background-color: #5eaed0;

			@include from-breakpoint('md') {
				width: calc(25% - 12px);
				height: 270px;
				gap: 20px;
				aspect-ratio: unset;
			}

			@include from-breakpoint('lg') {
				height: 340px;
			}

			@include from-breakpoint('xl') {
				height: 400px;
			}
		}
	}

	&__highlight-top-image {
		position: absolute;
		right: 24px;
		top: 24px;
		width: 71px;
		height: 71px;
		object-fit: contain;
		object-position: top right;

		@include from-breakpoint('md') {
			width: 97px;
			height: 97px;
		}

		@include from-breakpoint('lg') {
			width: 152px;
			height: 152px;
		}
	}

	&__highlight-bottom-image {
		width: 85px;
		height: 93px;
		object-fit: contain;
		object-position: left bottom;

		@include from-breakpoint('md') {
			width: 126px;
			height: 137px;
		}

		@include from-breakpoint('lg') {
			width: 154px;
			height: 168px;
		}
	}

	&__highlight-title {
		margin: 0;
		font-size: 20px;
		line-height: 1.2;
		font-weight: 600;

		@include from-breakpoint('md') {
			font-size: 24px;
		}

		@include from-breakpoint('lg') {
			font-size: 32px;
		}

		@include from-breakpoint('xl') {
			font-size: 40px;
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&__item-block {
		border-bottom: 1px solid $color-border;

		&:first-child {
			border-top: 1px solid $color-border;
		}

		p {
			margin: 0;
		}
	}

	&__item {
		text-align: center;
		margin: 16px 0;
	}

	&__link {
		@include body-2;

		padding: 20px 0;
		display: flex;
		flex-direction: column;
		gap: 14px;
		color: #141414;
		text-decoration: none;

		@include from-breakpoint('md') {
			padding: 36px 0;
			flex-direction: row;
			flex-wrap: nowrap;
			gap: 30px;
		}

		@include from-breakpoint('lg') {
			@include body-1;
		}

		&:hover,
		&:focus-visible {
			#{$this}__logo {
				opacity: 1;
			}
		}
	}

	&__wrapper {
		display: flex;
		justify-content: space-between;

		@include from-breakpoint('md') {
			width: 23%;
			margin-left: auto;
			gap: 30px;
		}
	}

	&__item-title {
		@include body-2;

		margin: 0;

		@include from-breakpoint('md') {
			width: 26%;
		}

		@include from-breakpoint('lg') {
			@include body-1;

			display: flex;
			justify-content: space-between;
			align-items: flex-start;
			gap: 10px;
		}
	}

	&__description {
		color: rgba(#141414, 0.5);

		@include from-breakpoint('md') {
			width: 40%;
			color: #141414;
		}
	}

	&__preview {
		margin-bottom: 16px;
		position: relative;
		padding-bottom: 42.85%;
	}

	&__subtitle {
		margin: 0 0 8px;
		font-size: 12px;
		font-family: $font-primary;
		font-weight: bold;
		text-transform: uppercase;
	}

	&__summary {
		margin: 0;
		font-size: 12px;
		font-family: $font-primary;
		line-height: 1.33;
	}

	&__logo {
		display: none;

		@include from-breakpoint('lg') {
			position: relative;
			max-width: 144px;
			max-height: 75px;
			width: 50%;
			height: 100%;
			display: block;
			opacity: 0;
		}

		.picture--absolute.picture,
		.picture--absolute .picture__el,
		.picture--absolute img {
			object-fit: contain;
			object-position: top right;
		}

		.ls-blur-up-img {
			display: none;
		}
	}

	&__rating {
		@include from-breakpoint('md') {
			flex-shrink: 0;
		}
	}

	&__year {
		@include from-breakpoint('md') {
			flex-shrink: 0;
			margin-left: auto;
		}
	}
}
