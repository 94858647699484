.iframe {
	position: relative;

	&__el {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: block;
		width: 100%;
		height: 100%;
		margin: 0;
	}
}
