.solutions {
	margin-top: 40px;
	margin-bottom: 60px;
}

// List
.solutions__list {
	padding: 0;
	list-style: none;
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
	column-gap: 30px;
	row-gap: 32px;

	@media (max-width: 600px) {
		grid-template-columns: 1fr;
		width: calc(100vw - 2 * 15px);
		margin: 0 auto;
	}
}

.solutions__list-item {
	background: #fcfcfc;
	padding: 32px 25px;
}

//Text
.solutions__title {
	font-family: $font-secondary;
	font-size: 16px;
	font-weight: 500;
	color: #242021;
	margin-bottom: 15px;
	margin-top: 0;
}

.solutions__description {
	font-size: 12px;
	line-height: 1.5;
	font-family: $font-primary;
	color: #000000;

	@include from-breakpoint('lg') {
		font-size: 14px;
	}
}
