// card radius
$border-radius: 0; //$base * 0.5;

$custom__header-height: 70px;
$custom__header-height--md: 81px;

$custom__limiter--typography: 690px + $base * 2;
$custom__limiter: 960px + $base * 2;

$timing: 351ms;

$grid-limit: 690px;
$grid-limit--wide: 960px;
$grid-limit--landing: 1110px;

$service-page-umb-grid-max-width: 630px;

$transition-default: 0.3s ease;
