.homepage-services {
	$this: &;
	margin-bottom: 160px;

	@include before-breakpoint('lg') {
		padding: 0;
	}

	&__item {
		position: relative;
		padding-bottom: 20px;
		position: relative;

		@include from-breakpoint('md') {
			padding: 0 40px;
		}

		@include from-breakpoint('lg') {
			padding: 0 60px;
		}

		@include from-breakpoint('xl') {
			padding: 0;
		}

		&:nth-child(1n) #{$this}__icon {
			background-image: url('../images/services-1.svg');
		}

		&:nth-child(2n) #{$this}__icon {
			background-image: url('../images/services-2.svg');
		}

		&:nth-child(3n) #{$this}__icon {
			background-image: url('../images/services-3.svg');
		}

		&:nth-child(4n) #{$this}__icon {
			background-image: url('../images/services-4.svg');
		}

		&:nth-child(5n) #{$this}__icon {
			background-image: url('../images/services-5.svg');
		}

		&:first-child {
			#{$this}__icon {
				top: 0;
			}

			#{$this}__block {
				padding-top: 0;
				border: none;
			}

			@include from-breakpoint('lg') {
				margin-top: 16px;
			}
		}
	}

	&__icon {
		position: absolute;
		top: 40px;
		right: 16px;
		display: block;
		background-size: contain;
		background-repeat: no-repeat;
		width: 64px;
		height: 69px;
		color: $color-black;
		transition: transform 2s ease;

		@include from-breakpoint('md') {
			right: unset;
			left: 40px;
			top: 60px;
		}

		@include from-breakpoint('lg') {
			left: 60px;
			top: 80px;
		}

		@include from-breakpoint('xl') {
			left: 0px;
		}
	}

	&__block {
		display: block;
		padding: 40px 0 60px;
		text-decoration: none;
		color: inherit;
		height: 100%;
		border-top: 1px solid #e5e5e5;

		@include from-breakpoint('md') {
			padding: 60px 0 60px 94px;
		}

		@include from-breakpoint('lg') {
			padding: 80px 0 80px 94px;
			display: flex;
			gap: 30px;
			justify-content: space-between;
		}

		@include from-breakpoint('xl') {
			padding: 75px 0 60px 94px;
		}
	}

	&__link:hover {
		#{$this}__title {
			color: $color-primary;
		}
	}

	&__title {
		margin-top: 0;
		margin-bottom: 30px;
		margin-right: 80px;
		max-width: 224px;
		font-size: $h3-font-size--lg;
		font-weight: 600;
		line-height: 1.1;
		transition: color 0.3s;
		letter-spacing: 0.01em;
		hyphens: manual;

		@include from-breakpoint('md') {
			max-width: unset;
		}

		@include from-breakpoint('xl') {
			max-width: 528px;
			font-size: $h3-font-size--xl;
		}
	}

	&__text {
		@include body-1;

		margin: 0;

		@include from-breakpoint('lg') {
			max-width: 390px;
		}

		@include from-breakpoint('xl') {
			max-width: 528px;
		}
	}

	&__discuss {
		display: flex;
		justify-content: center;
		align-items: center;
		height: 100%;
	}
}
