@import '../common/_shared.scss';
@import '../common/fonts';

$card-shadow: 0px 0px 20px rgba(0, 0, 0, 0.14);
$color-fuse8: #1475e8;
$color-fors: #ff0000;

.fors-team,
.fors-result-image,
.fors-process-info,
.fors-process-stages,
.fors-audit-slider,
.fors-design {
	padding-top: 0;
	padding-bottom: 0;
}

.fors-about,
.fors-result-image,
.fors-prototype,
.fors-design-tabs {
	@include from-breakpoint('xl') {
		position: relative;
		overflow: visible;

		&::after {
			position: absolute;
			content: '';

			background-position: 100% 0;
			background-repeat: no-repeat;
			background-size: contain;

			z-index: -1;
		}
	}
}

.fors-case {
	scroll-behavior: smooth;

	&__link {
		color: $color-fuse8 !important;
		@include from-breakpoint('xl') {
			font-size: 20px;
			margin-top: 6px;
		}
	}
	&__title-label {
		font-size: 16px;
		font-weight: bold;
		color: $color-gray;

		@include from-breakpoint('md') {
			font-size: 24px;
		}

		& + h3 {
			margin-top: 0;

			@include from-breakpoint('md') {
				margin-top: 10px;
			}
		}
	}

	&__highlighted {
		box-shadow: $card-shadow;
		padding: 20px 28px;
		font-style: italic;
		font-weight: 300;
		font-size: 18px;
		line-height: 1.6;
		margin-top: 20px;

		@include from-breakpoint('xl') {
			font-size: 22px;
			margin-top: 24px;
		}
	}
}

.fors-top {
	position: relative;
	overflow: visible;

	&::after {
		position: absolute;
		content: '';
		top: -8%;
		right: 0;

		width: 108px;
		height: 124px;

		background-image: url('../images/fors/fors-bg-1.jpg');
		background-position: 100% 0;
		background-repeat: no-repeat;
		background-size: contain;

		z-index: -1;

		@include from-breakpoint('md') {
			top: -40%;
			width: 578px;
			height: 649px;
		}
	}

	padding-top: 0;
	margin-top: 40px;

	@include from-breakpoint('md') {
		margin-top: 70px;
	}

	&__logo {
		margin-left: 0 !important;
		width: 163px;

		@include from-breakpoint('md') {
			width: auto;
		}
	}
	h1 {
		margin-top: 20px;

		@include from-breakpoint('md') {
			margin-top: 50px;
		}
	}
	&__image {
		display: none;

		@include from-breakpoint('xl') {
			display: block;
			width: 440px;
			.umb-macro-image-with-offset {
				max-width: 100%;
			}
			img {
				margin-left: 0;
				max-width: 100%;
			}
		}
		@include from-breakpoint('xxl') {
			width: auto;
			.umb-macro-image-with-offset {
				max-width: 440px;
			}
			img {
				margin-left: auto;
				max-width: 440px;
			}
		}
	}
	&__button {
		padding: $base * 0.625 $base;
		@include from-breakpoint('sm') {
			&--contured {
				padding: $base * 0.625 $base * 1.4375;
			}
		}
		@include from-breakpoint('md') {
			display: none;
		}
		@include from-breakpoint('xl') {
			margin-top: 18px;
			margin-left: auto;
			display: inline-block;
		}
	}
}

.fors-about {
	padding-top: 14px;
	padding-bottom: 18px;

	@include from-breakpoint('md') {
		padding-top: 34px;
	}

	@include from-breakpoint('xl') {
		padding-top: 50px;
		padding-bottom: 24px;

		&::after {
			top: 0;
			left: -18%;
			width: 286px;
			height: 705px;
			background-image: url('../images/fors/fors-bg-2.jpg');
		}
	}

	@include from-breakpoint('xxl') {
		&::after {
			left: 0;
		}
	}

	&__specs {
		display: flex;
		flex-flow: row nowrap;
		font-size: 14px;

		@include from-breakpoint('md') {
			font-size: 20px;
		}

		p + p {
			margin-left: 24px;
			margin-top: 0;

			@include from-breakpoint('lg') {
				margin-left: 30px;
			}
		}

		p:last-of-type {
			text-align: right;
			flex-grow: 1;
		}
	}
	&__stages {
		margin-top: 24px;
		box-shadow: $card-shadow;
		background-color: $color-light;
		display: flex;
		flex-flow: row nowrap;
		border: none;
		padding: 20px 0;
	}

	.stage {
		flex-grow: 1;
		text-align: center;
		width: 50%;
		box-sizing: border-box;
		margin-top: 0;
		border-left: 1px solid $color-gray--l;

		&:first-of-type {
			border-left: none;
		}

		&__point {
			width: 10px;
			height: 10px;
			border-radius: 50%;
			margin: auto;

			&--design {
				background-color: $color-fuse8;
			}
			&--development {
				background-color: $color-fors;
			}
		}

		&__time {
			color: $color-gray--d;
			font-size: 14px;
			margin-top: 3px;
		}
	}
}

.fors-team {
	&__subtitle {
		&--fuse8 {
			color: $color-fuse8;
			font-weight: bold;
		}
		&--fors {
			color: $color-fors;
			font-weight: bold;
			margin-top: 10px;
			@include from-breakpoint('md') {
				margin-top: 0;
			}
		}
	}
	&__position {
		font-weight: bold;
		margin-top: 14px;
		@include from-breakpoint('md') {
			margin-top: 22px;
		}
	}
	&__name {
		margin-top: 4px;
	}
}

.fors-result {
	padding-top: 34px;
	padding-bottom: 0;
	@include from-breakpoint('xl') {
		padding-top: 50px;
	}
}
.fors-result-image {
	@include from-breakpoint('xl') {
		&::after {
			top: 80%;
			right: 0;

			width: 368px;
			height: 749px;

			background-image: url('../images/fors/fors-bg-3.jpg');
		}
	}
}

.fors-how-it-started {
	padding-top: 34px;
	padding-bottom: 0;

	@include from-breakpoint('xl') {
		padding-top: 50px;
	}
}

.fors-process {
	padding-top: 34px;
	padding-bottom: 0;

	@include from-breakpoint('xl') {
		padding-top: 50px;
	}

	&__stage {
		& + & {
			margin-top: 14px;

			@include from-breakpoint('md') {
				margin-top: 0;
			}
		}
	}

	&__stage-inner {
		box-shadow: $card-shadow;
		box-sizing: border-box;
		padding: 26px 34px 34px;

		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		@include from-breakpoint('md') {
			padding: 56px 28px 56px 30px;
			height: 250px;
			flex-direction: column;
			align-items: flex-start;
		}
	}

	&__number {
		color: $color-gray;
		font-size: 40px;
		font-weight: bold;
		line-height: 1;
	}
	&__label {
    max-width: 100%;
    margin-left: 32px;

    @include from-breakpoint('md') {
			margin-left: 0;
		}
	}
}

.fors-process-stages,
.fors-audit-audience {
	padding-top: 20px;
	padding-bottom: 20px;
	@include from-breakpoint('xl') {
		padding-top: 40px;
		padding-bottom: 40px;
	}
}

.fors-audit {
	padding-top: 34px;
	padding-bottom: 0;

	@include from-breakpoint('xl') {
		padding-top: 50px;
	}

	&__report {
		img {
			margin-left: 0;
		}
	}
}

.fors-audit-audience-type {
	box-shadow: $card-shadow;
	box-sizing: border-box;
	padding: 22px 10px 22px 24px;

	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	@include from-breakpoint('md') {
		padding: 76px 30px 80px;
		height: 400px;
		flex-direction: column;
		align-items: flex-start;
	}

	&__text {
    max-width: 100%;
		margin-left: 20px;
		@include from-breakpoint('md') {
			margin-top: 28px;
			margin-left: 0;
		}

		p {
			margin-top: 8px;
			@include from-breakpoint('md') {
				margin-top: 14px;
			}
		}
	}
}

.fors-audit-subtitle {
	padding-top: 0;
	padding-bottom: 18px;

	@include from-breakpoint('xl') {
		padding-bottom: 28px;
	}
}

.fors-prototype {
	@include from-breakpoint('xl') {
		position: relative;
		overflow: visible;

		&::after {
			top: 50%;
			right: 0;
			width: 396px;
			height: 593px;

			background-image: url('../images/fors/fors-bg-4.jpg');
		}
	}

	@include from-breakpoint('xxl') {
		&::after {
			top: 20%;
			width: 853px;
			height: 1275px;
		}
	}

	padding-top: 34px;
	padding-bottom: 0;

	@include from-breakpoint('xl') {
		padding-top: 50px;
	}
}

.fors-design-tabs {
	@include from-breakpoint('xl') {
		position: relative;
		overflow: visible;

		&::after {
			top: 50%;
			left: -18%;

			width: 333px;
			height: 907px;

			background-image: url('../images/fors/fors-bg-5.jpg');
		}

		&::before {
			position: absolute;
			top: 30%;
			bottom: 20%;
			left: 26px;
			right: 26px;
			content: '';
			background-image: url('../images/fors/dotts.png');
		}
	}

	@include from-breakpoint('xxl') {
		&::after {
			left: 0;
		}
	}
}

.fors-review {
	&__title {
		padding-bottom: 14px;
		border-bottom: 1px solid $color-gray--l;
		position: relative;

		&::after {
			position: absolute;
			content: '';
			height: 3px;
			background-color: $color-primary;
			width: 130px;
			bottom: 0;
			left: 0;

			@include from-breakpoint('md') {
				width: 234px;
			}
		}

		@include from-breakpoint('md') {
			padding-bottom: 20px;
		}
	}
}
