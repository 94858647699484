.homepage-team {
	$block: &;

	margin: 0 0 160px;
	padding: 0 15px 60px;
	background: linear-gradient(
		to bottom,
		white 0px,
		white 80px,
		$color-brand-primary-new 80px,
		$color-brand-primary-new 100%
	);

	@include from-breakpoint('md') {
		padding: 0 15px 80px;
	}

	@include from-breakpoint('xl') {
		padding-bottom: 145px;
	}

	&--about {
		#{$block}__container {
			padding: 0;

			@include from-breakpoint('xl') {
				max-width: calc(1200px - #{$side-padding} * 2);
			}
		}

		#{$block}__photo-wrapper {
			margin-bottom: 25px;
			padding: 18px;
			height: 445px;
			aspect-ratio: unset;

			@include from-breakpoint('md') {
				margin-bottom: 20px;
			}

			@include from-breakpoint('lg') {
				margin-bottom: 30px;
			}

			@include from-breakpoint('xl') {
				height: 589px;
				margin-bottom: 45px;
			}
		}

		#{$block}__photo {
			background-image: url('../images/about.png');
			background-position: center;
			background-repeat: no-repeat;
			background-size: cover;
		}

		#{$block}__title {
			@include h2;

			@include from-breakpoint('md') {
				max-width: 552px;
			}

			@include from-breakpoint('lg') {
				font-size: 28px;
				width: 38%;
			}

			@include from-breakpoint('xl') {
				width: 40%;
			}
		}

		#{$block}__description {
			gap: 40px;
		}

		#{$block}__footer {
			order: -1;
		}
	}

	&__container {
		position: relative;
		z-index: 2;
		margin: 0 auto;
		max-width: 576px;

		@include from-breakpoint('sm') {
			padding: 0 15px;
		}

		@include from-breakpoint('md') {
			max-width: 768px;
		}

		@include from-breakpoint('lg') {
			max-width: 1024px;
		}

		@include from-breakpoint('xl') {
			max-width: 1200px;
		}
	}

	&__photo-wrapper {
		position: relative;
		z-index: 1;
		margin: 0 auto 45px;
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		aspect-ratio: 1.06;
		background-image: url('../images/team.webp');
		background-position: center;
		background-repeat: no-repeat;
		background-size: cover;

		@include from-breakpoint('md') {
			aspect-ratio: 1.27;
			margin-bottom: 50px;
		}

		@include from-breakpoint('lg') {
			margin-bottom: 70px;
			aspect-ratio: 2;
			perspective: 100px;
			transform-style: preserve-3d;
			transition: transform 0.3s ease;
		}
	}

	&__title {
		position: relative;
		z-index: 3;
		margin: 0;
		width: 90%;
		font-size: $h2-font-size--md;
		font-weight: 600;
		line-height: 1;
		letter-spacing: -0.02em;
		color: $color-light;
		text-transform: uppercase;
		text-align: center;

		@include from-breakpoint('md') {
			font-size: $h1-font-size--lg;
		}

		@include from-breakpoint('lg') {
			width: 50%;
			font-size: 55px;
			transform: translateZ(50px);
		}
	}

	&__description {
		padding-bottom: 65px;
		display: flex;
		flex-direction: column;
		gap: 36px;

		@include from-breakpoint('md') {
			gap: 50px;
			padding-bottom: 100px;
		}

		@include from-breakpoint('lg') {
			flex-direction: row;
			justify-content: space-between;
			gap: 95px;
			padding-bottom: 60px;
		}

		p {
			margin: 0;
			font-size: 20px;
			line-height: 1.2;
			font-weight: 600;

			@include from-breakpoint('lg') {
				font-size: 24px;
			}
		}
	}

	&__footer {
		display: flex;
		flex-direction: column;
		gap: 36px;

		@include from-breakpoint('md') {
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-end;
		}

		@include from-breakpoint('lg') {
			flex-direction: column;
			gap: 40px;
			justify-content: flex-start;
		}
	}

	&__button {
		@include button-text;

		display: inline-flex;
		align-items: center;
		justify-content: center;
		padding: 20px;
		text-align: center;
		text-transform: uppercase;
		text-decoration: none;
		white-space: nowrap;
		transition: background-color 0.3s, color 0.3s;

		.icon {
			display: inline-block;
			width: 14px;
			height: 20px;
			margin-left: 10px;
		}

		&:hover {
			background-color: transparent;
			color: black;
		}
	}

	&__icon {
		position: absolute;
		top: 0;
		left: -1px;
		width: 32px;
		height: 100%;
		color: $color-brand-primary-new;

		@include before-breakpoint('md') {
			transform: rotate(-90deg) scaleX(-1);
			transform-origin: 0 0;
			height: 111%;
			left: 0;
			top: -1px;
		}
	}

	&__social {
		& > .social-icons {
			justify-content: center;
			gap: 18px;
		}

		.social-icons--black .social-icons__link:hover {
			color: white;
		}
	}

	&__features {
		@include reset-list;

		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 32px;

		@include from-breakpoint('md') {
			order: -1;
			flex-direction: row;
			gap: 54px;
		}

		@include from-breakpoint('lg') {
			margin-bottom: 0;
			margin-left: 20px;
			justify-content: flex-start;
			align-items: flex-start;
		}

		@include from-breakpoint('xl') {
			gap: 80px;
		}
	}

	&__features-item {
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
	}

	&__features-icon {
		position: absolute;
		left: 50%;
		top: 10px;
		width: 180px;
		height: 43px;
		color: $color-light;
		transform: translateX(-50%);
	}

	&__features-title {
		position: relative;
		z-index: 1;
		margin: 0;
		font-size: $h2-font-size--lg;
		line-height: 1;
	}

	&__features-text {
		@include body-2;

		position: relative;
		z-index: 1;
		max-width: 170px;
		text-align: center;
	}
}
