.technologies {
	$this: &;
	margin-bottom: 160px;
	color: white;
	background-color: black;

	&__container {
		position: relative;
		padding-top: 80px;
		padding-bottom: 100px;

		@include from-breakpoint('md') {
			padding-bottom: 30px;
		}

		@include from-breakpoint('lg') {
			padding-top: 100px;
			padding-bottom: 100px;
		}

		@include from-breakpoint('xl') {
			padding-top: 120px;
			padding-bottom: 120px;
		}
	}

	&__content {
		position: relative;
		margin-top: -45px;

		@include from-breakpoint('md') {
			display: grid;
			grid-template-columns: 1fr 1fr;
			grid-template-rows: 1fr 1fr;

			&::before {
				content: '';
				position: absolute;
				top: 50%;
				left: 0;
				right: 0;
				height: 1px;
				background-color: rgba($color-light, 0.2);
			}

			&::after {
				content: '';
				position: absolute;
				top: 25px;
				bottom: 25px;
				left: 50%;
				width: 1px;
				background-color: rgba($color-light, 0.2);
			}
		}

		@include from-breakpoint('lg') {
			&::before {
				left: 25px;
				right: 25px;
			}
		}

		@include from-breakpoint('xl') {
			&::before {
				left: 0;
			}
		}
	}

	&__block {
		display: flex;
		flex-direction: column;
		gap: 16px;
		padding-top: 20px;
		padding-bottom: 64px;
		border-bottom: 1px solid rgba($color-light, 0.2);

		&:last-child {
			padding-bottom: 0;
			border: none;
		}

		@include from-breakpoint('md') {
			padding: 0 30px 55px;
			border: none;

			&:nth-child(n + 3) {
				padding-top: 40px;
			}
		}

		@include from-breakpoint('xl') {
			padding: 0 55px 55px;

			&:nth-child(odd) {
				padding-left: 0px;
			}
		}

		&:nth-child(1n) #{$this}__title::before {
			background-image: url('../images/services-light-1.svg');
		}

		&:nth-child(2n) #{$this}__title::before {
			background-image: url('../images/services-light-2.svg');
		}

		&:nth-child(3n) #{$this}__title::before {
			background-image: url('../images/services-light-3.svg');
		}

		&:nth-child(4n) #{$this}__title::before {
			background-image: url('../images/services-light-4.svg');
		}

		&:nth-child(5n) #{$this}__title::before {
			background-image: url('../images/services-light-5.svg');
		}
	}

	&__title {
		z-index: 2;
		margin: 22px 0 0;
		display: flex;
		align-items: center;
		gap: 30px;
		font-size: $h3-font-size--lg;

		&::before {
			content: '';
			width: 64px;
			height: 64px;
			background-size: contain;
			background-repeat: no-repeat;
		}

		@include from-breakpoint('xl') {
			font-size: $h3-font-size--xl;
		}
	}

	&__list {
		margin: 0;
		padding: 14px 0 0;
		display: flex;
		flex-wrap: wrap;
		gap: 8px;
		align-content: flex-start;
	}

	&__item {
		display: inline-block;
		padding: 8px;
		background-color: $color-dark-2;
		font-size: 20px;
		font-weight: 600;
		line-height: 1.2;

		@include from-breakpoint('md') {
			height: 40px;
		}
	}
}
