.contact-info {
	$block: &;

	&__contacts {
		padding: 0.07px 0;
	}

	&__contacts-inner {
		padding: 0;
		list-style: none;
		text-decoration: none;
		font-family: $font-primary;
		margin: -8px;
		display: flex;
		flex-flow: row wrap;

		> #{$block}__contact {
			padding: 8px;
		}
	}

	&__contact {
		$font-size: 18px;

		margin: 0;
		font-size: $font-size;
		color: $color-dark;
		width: 100%;

		@include from-breakpoint('md') {
			$font-size: 24px;
			font-size: $font-size;
		}
		&--compact {
			@include from-breakpoint('md') {
				max-width: 33.33%;
			}
			@include from-breakpoint('lg') {
				max-width: 18%;
			}
		}

		&--fourth {
			max-width: 100%;
		}

		a {
			color: inherit;
			text-decoration: none;
			display: inline-flex;
			align-items: center;
			flex-flow: row nowrap;

			&:focus,
			&:hover {
				text-decoration: underline;
			}

			.icon {
				$size: px2em($font-size, $font-size);

				margin-right: px2em(8px, $font-size);
				width: $size;
				height: $size;
				padding: 0;

				display: inline-block;
				vertical-align: bottom;
				flex: 0 0 auto;

				@include from-breakpoint('md') {
					padding: px2em(2px, $font-size);
				}
			}
		}
	}

	&__icon {
		color: $color-gray--d;
		overflow: visible;
	}

	&__icon--vk {
		color: $color-social--vk;
	}

	&__icon--facebook {
		color: $color-social--facebook;
	}

	&__icon--instagram {
		color: $color-social--instagram;
	}

	&__icon--behance {
		color: $color-social--behance;
	}

	&__icon--youtube {
		color: $color-social--youtube;
	}

	&__icon--vc {
		color: $color-social--vc;
	}
}
