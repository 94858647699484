$color-primary: #ca132a;
$color-secondary: #284f01;
$color-tertiary: #23256a;
$color-quaternary: #ffd954;
$color-quaternary--d: #f3c72c;

$color-light: #fff;
$color-gray--lll: #f6f6f6;
$color-gray--ll: #f2f2ed;
$color-gray--l: #dfdfdf;
$color-gray: #d4d4d4;
$color-gray--dd: #c5c5c5;
$color-gray--d: #878787;
$color-dark: #242021;
$color-dark-1: #353535;
$color-dark-2: #2d2c2c;
$color-gray-dark: #1e1e1e;
$color-black: #000000;
$color-contrast: #de2451;
$color-border: #e5e5e5;
$color-label: #141414;
$color-caption: #868686;

$color-brand-primary: #ffd541;
$color-brand-primary-new: #f8d75f;
$color-brand-secondary: $color-dark;

$color-gradient--start: #c6cfe8;
$color-gradient--finish: #98a1b8;

// functional
$color-text: $color-dark;
$color-text--muted: #989898;
$color-text--muted-2: #626262;
$color-text--contrast: white;

$color-background: $color-light;
$color-background--muted: #f7f6f6;

$color-disabled: $color-gray;
$color-error: $color-primary;
$color-success: #44b72c;

$color-trophy: #eca724;

// social
$color-social--twitter: #1da1f2;
$color-social--google: #dd4b39;
$color-social--facebook: #3b5998;
$color-social--vk: #45668e;
$color-social--youtube: #ff0000;
$color-social--instagram: #f00075;
$color-social--behance: #1769ff;
$color-social--vc: #000000;
