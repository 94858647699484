@mixin picture__img {
	.picture {
		img {
			@content;
		}
	}
}

.picture {
	$block: &;

	&, img, &__el {
		display: block;
	}

	img {
		object-fit: cover;
	}

	&.lazyloaded {
		img.ls-blur-up-img {
			filter: blur(5px);
			opacity: 0;
		}
	}

	&__sizer {
		display: block;
		// https://stackoverflow.com/questions/8673356/ie-doesnt-support-height-auto-for-images-what-should-i-use
		height: auto !important;
		//width: auto !important;
		max-width: 100% !important;
		opacity: 0;
	}

	&__el {
		&--script {
			.no-js & {
				/* noscript pattern */
				display: none !important;
			}

			img {
				transition: opacity .5s, filter .5s;

				&.lazyload,
				&.lazyloading {
					filter: blur(10px);
					opacity: 1;
				}
			}

			+ img.ls-blur-up-img {
				filter: blur(10px);
				opacity: 1;
			}
		}

		&--noscript {
		}
	}

	&--static {
		&#{$block}, img, #{$block}__el {
			position: relative;
			max-width: 100%;
			max-height: 100%;
			//width: 100%;
			//object-fit: none;
			display: block;
			margin: auto;
			transform: translateZ(0);
		}

		.lazysizes-display-clone,
		#{$block}__el {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		img {
			height: 100%;
			object-fit: contain;
		}

		img {
			&.ls-blur-up-img {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
			}
		}
	}

	&--absolute {
		&#{$block}, img, #{$block}__el {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			margin: 0;
		}
	}

	// modify object-fit through mixin
}
