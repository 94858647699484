html, body {
	height: 100%;
}

html {
	//overflow-x: hidden;
}

.page {
	height: 100%;
	display: flex;
	flex-direction: column;

	&__header {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		z-index: 100;

		&--static {
			position: static;
		}
	}

	&__header,
	&__header-additional {
		transition: transform 0.2s ease-in-out;

		&--hidden {
			transform: translate(0, -100%);
		}
	}

	&__header-main {
		position: relative;
		z-index: 1;
	}

	&__main {
		flex: 1 0 auto;

		&--flex {
			display: flex;
			justify-content: center;
		}
	}

	&__footer {
		flex: 0 0 auto;
	}
}
