.overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	opacity: 0;
	visibility: hidden;
	z-index: 100;
	transform: translateZ(0);
	background: rgba($color-black, 0.7);
	will-change: transform;
	transition: opacity 0.2s linear, visibility 0.2s linear;

	&.is-visible {
		opacity: 1;
		visibility: visible;
	}
}