.grid-view {
  max-width: 930px; // magic number
  margin-left: auto;
  margin-right: auto;

  img {
    max-width: 100%;
    display: block;
  }
  &__column {
    padding: 2em 0;
  }
}