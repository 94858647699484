.services {
	$block: &;

	position: relative;

	&__animation-helper {
		@include from-breakpoint('lg') {
			position: absolute;
			left: 0;
			top: 0px;
			bottom: -95px;
			right: 28%;
			z-index: 1;
		}

		@include from-breakpoint('xl') {
			bottom: -195px;
		}
	}

	.container {
		@include from-breakpoint('sm') {
			width: unset;
		}

		@include from-breakpoint('lg') {
			max-width: $container-max-width;
		}
	}

	&__hero {
		position: relative;
		margin-top: 65px;

		overflow: hidden;

		@include from-breakpoint('md') {
			margin-top: 40px;
		}

		@include from-breakpoint('lg') {
			margin-top: 30px;
		}
	}

	&__icons-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		display: flex;
		height: 373px;
		min-width: fit-content;
		align-items: center;
		color: $color-gray--lll;

		@include from-breakpoint('md') {
			height: 522px;
		}
	}

	&__svg {
		margin-left: -1px;
		width: 1598px;
		height: 373px;

		@include from-breakpoint('md') {
			width: 2236px;
			height: 522px;
		}

		@include from-breakpoint('lg') {
			height: 578px;
			width: 2476px;
			animation: spotlight 30s infinite linear;
		}
	}

	&__hero-content {
		width: 100%;
		height: 100%;
		padding: 80px 20px 120px;

		@include from-breakpoint('md') {
			padding: 150px 40px;
		}

		@include from-breakpoint('lg') {
			padding: 200px 60px;
			display: flex;
			align-items: center;
			gap: 118px;
		}

		@include from-breakpoint('xl') {
			gap: 246px;
		}

		p {
			@include body-2;

			margin: 0;
			padding-left: 92px;
			max-width: 500px;
			flex-shrink: 0;

			@include from-breakpoint('md') {
				max-width: 404px;
				padding: 0;
			}

			@include from-breakpoint('lg') {
				max-width: 385px;
			}
		}
	}

	&__hero-title {
		@include h1;

		position: relative;
		margin: 20px 0 8px;
		display: flex;
		gap: 16px;
		align-items: center;

		&::before {
			content: '';
			display: block;
			flex-shrink: 0;
			width: 76px;
			height: 76px;
			background-image: url('../images/services-2.svg');
			background-repeat: no-repeat;
			background-size: 100% 100%;

			@include from-breakpoint('md') {
				width: 80px;
				height: 80px;
			}

			@include from-breakpoint('lg') {
				width: 96px;
				height: 96px;
			}
		}

		@include from-breakpoint('md') {
			margin-top: 0;
			margin-bottom: 40px;
			gap: 24px;
		}

		@include from-breakpoint('lg') {
			margin-bottom: 0;
		}

		@include from-breakpoint('xl') {
			gap: 32px;
		}
	}

	&__wrapper {
		margin: 0 -15px 100px;
		padding-top: 90px;

		@include from-breakpoint('md') {
			margin: 0 25px 100px;
			padding-top: 0;
		}

		@include from-breakpoint('lg') {
			margin: 0 45px 100px;
		}

		@include from-breakpoint('xl') {
			margin: 0 45px 200px;
		}

		@include from-breakpoint('xxl') {
			margin: 0 -15px 200px;
		}
	}
	&__wrapper {
		padding-top: 50px;

		@include from-breakpoint('md') {
			padding-top: 80px;
		}

		@include from-breakpoint('lg') {
			padding-top: 150px;
		}
	}

	&__solutions {
		margin-bottom: 12px;
	}

	&__list-wrapper {
		margin: 24px auto 0;

		@include from-breakpoint('lg') {
			margin-top: 66px;
		}
	}

	&__list {
		display: flex;
		flex-direction: column;
		margin: 0;
		padding: 0;
		list-style: none;

		@include from-breakpoint('md') {
			flex-direction: row;
			flex-wrap: wrap;
		}
	}

	&__list-item {
		border-top: 1px solid $color-border;

		&:last-child {
			border: none;
		}

		@include from-breakpoint('md') {
			width: 33%;
			flex-grow: 1;
			border-top: none;
			border-right: 1px solid $color-border;

			&:nth-child(3n) {
				border: none;
			}

			&:nth-child(1),
			&:nth-child(5n),
			&:nth-child(9n) {
				#{$block}__link {
					padding-left: 0;
				}
			}

			&:nth-child(3),
			&:nth-child(11) {
				#{$block}__link {
					padding-right: 0;
				}
			}
		}

		&--service {
			&:nth-child(1n) {
				#{$block}__icon {
					background-image: url('../images/services-1.svg');
				}
			}

			&:nth-child(2n) {
				#{$block}__icon {
					background-image: url('../images/services-2.svg');
				}
			}

			&:nth-child(3n) {
				#{$block}__icon {
					background-image: url('../images/services-3.svg');
				}
			}

			&:nth-child(4n) {
				#{$block}__icon {
					background-image: url('../images/services-4.svg');
				}
			}

			&:nth-child(5n) {
				#{$block}__icon {
					background-image: url('../images/services-5.svg');
				}
			}
		}

		&--banner {
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			overflow: hidden;

			@include from-breakpoint('md') {
				width: 100%;
				border-top: 1px solid $color-border;
				border-bottom: 1px solid $color-border;
				border-right: none;
			}
		}

		&--button {
			padding: 0;
			cursor: pointer;
		}
	}

	&__link {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		padding: 48px 20px;
		color: inherit;
		text-decoration: none;

		&:hover,
		&:focus-visible {
			#{$block}__title {
				color: $color-contrast;
			}
		}

		@include from-breakpoint('md') {
			padding: 48px 24px;
		}
	}

	&__icon {
		margin-bottom: 90px;
		width: 50px;
		height: 50px;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		transform-style: preserve-3d;

		@include from-breakpoint('md') {
			margin-bottom: 140px;
			width: 71px;
			height: 71px;
		}

		@include from-breakpoint('lg') {
			margin-bottom: 190px;
			width: 75px;
			height: 75px;
		}

		@include from-breakpoint('xl') {
			margin-bottom: 295px;
		}
	}

	&__title {
		@include h3;

		margin-top: 0;
		margin-bottom: 20px;
		hyphens: manual;
		transition: color $transition-default;

		@include from-breakpoint('md') {
			margin-bottom: 15px;
		}

		@include from-breakpoint('lg') {
			margin-bottom: 20px;
		}

		@include from-breakpoint('xl') {
			margin-bottom: 25px;
		}
	}

	&__description {
		@include body-2;
	}

	&__banner-img {
		position: relative;
		width: 100%;
		padding: 80px 0;
		display: flex;
		justify-content: center;

		@include from-breakpoint('lg') {
			perspective: 100px;
			transform-style: preserve-3d;
			transition: transform $transition-default;
			will-change: transform;

			svg {
				max-width: 689px;
			}

			#{$block}__banner-bg {
				max-width: 1378px;
			}
		}

		svg {
			width: 100%;
			height: auto;
		}
	}

	&__banner-bg {
		position: relative;
		z-index: -1;

		@include from-breakpoint('lg') {
			transform: translateZ(-100px);
			transform-style: preserve-3d;
		}
	}

	&__banner-text {
		position: absolute;
		left: 0;
		top: 50%;
		left: 50%;
		z-index: 1;
		width: 100%;
		margin: 0 auto;
		transform: translateX(-50%) translateY(-50%);

		@include from-breakpoint('lg') {
			top: 50%;
			transform: translateX(-50%) translateY(-50%) translateZ(-2px);
			transform-style: preserve-3d;
		}
	}

	&__button {
		@include h2;

		position: relative;
		width: 100%;
		height: 100%;
		margin: 0;
		padding: 40px 24px;
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		text-transform: uppercase;
		border: none;
		color: $color-black;
		background-color: transparent;
		cursor: pointer;

		&:hover,
		&:focus-visible {
			span {
				color: $color-contrast;
			}
		}

		span {
			position: relative;
			z-index: 1;
			display: block;
			max-width: 180px;
			text-align: left;
			transition: color $transition-default;
		}

		@include from-breakpoint('md') {
			padding: 48px 24px;
			flex-direction: column;
			font-size: $h2-font-size--xs;
		}

		@include from-breakpoint('lg') {
			font-size: $h2-font-size--md;
			perspective: 100px;
			will-change: transform;
			transform-style: preserve-3d;
			transition: transform $transition-default;

			span {
				transform: translateZ(-1px);
			}
		}

		@include from-breakpoint('xl') {
			font-size: $h2-font-size--lg;
		}
	}

	&__button-bg {
		position: absolute;
		left: 0;
		top: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		background-color: $color-brand-primary-new;

		@include from-breakpoint('lg') {
			top: -56%;
			left: -50%;
			width: 205%;
			height: 210%;
			transform: translateZ(-100px);
		}
	}

	&__chevron {
		position: relative;
		z-index: 2;
		width: 40px;
		height: 45px;

		@include from-breakpoint('md') {
			width: 110px;
			height: 130px;
			align-self: flex-end;
		}

		@include from-breakpoint('lg') {
			width: 170px;
			height: 200px;
			transform: translateZ(-5px);
		}
	}

	&__heading {
		@include h1;

		color: #242021;
		font-weight: 500;
		line-height: 1.3;

		@include from-breakpoint('lg') {
			font-size: 46px;
		}

		&--solutions {
			@include from-breakpoint('lg') {
				font-size: 32px;
			}
		}
	}

	section + section {
		margin-top: 50px;

		@include from-breakpoint('lg') {
			margin-top: 60px;
		}
	}

	.picture img {
		object-fit: contain;
	}

	// &__link {
	// 	display: flex;
	// 	flex-direction: column;
	// 	width: 100%;
	// 	height: 100%;
	// 	padding: 20px 24px 30px;
	// 	text-decoration: none;
	// 	color: inherit;

	// 	@include from-breakpoint('lg') {
	// 		padding: 20px 20px 30px;
	// 	}

	// 	&--active:hover {
	// 		background-color: #f9faff;

	// 		#{$block}__arrow-body {
	// 			transition: all 0.3s ease;
	// 			width: 115px;
	// 		}

	// 		#{$block}__image {
	// 			&--static {
	// 				background-color: #f9faff;
	// 			}
	// 		}

	// 		@include from-breakpoint('lg') {
	// 			#{$block}__image {
	// 				&--static {
	// 					opacity: 0;
	// 					transition: opacity 0.05s linear;
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	&__arrow {
		display: none;
		margin-top: auto;

		@include from-breakpoint('lg') {
			display: block;
		}
	}

	&__arrow-body {
		border: 1px solid $color-black;
		position: relative;
		background: $color-black;
		width: 30px;
		transition: all 0.3s ease;
	}

	&__arrow-head {
		right: -4px;
	}

	&__arrow-head,
	&__arrow-head::after {
		border: 1px solid $color-black;
		border-radius: 2px;
		width: 8px;
		position: absolute;
		top: 2px;
		transform: rotate(-45deg);
		background-color: $color-black;
	}

	&__arrow-head::after {
		content: '';
		border-color: $color-black;
		right: -5px;
		top: -5px;
		transform: rotate(90deg);
	}

	&__image-holder {
		max-width: 72px;
		margin-left: -15px;

		@include from-breakpoint('lg') {
			max-width: 100px;
		}
	}

	&__image-wrapper {
		position: relative;
	}

	&__image {
		padding-bottom: 0;
		width: 72px;
		height: 72px;
		transition: opacity 0.05s linear;

		@include from-breakpoint('lg') {
			width: 100px;
			height: 100px;
		}

		&--static {
			position: absolute;
			background-color: #fcfcfc;
		}

		&--animated {
			img {
				display: none;

				@include from-breakpoint('lg') {
					display: block;
					width: 100px;
					height: 100px;
				}
			}
		}
	}

	@keyframes spotlight {
		100% {
			transform: translateX(-100%);
		}
	}
}
