@import '../common/_shared.scss';

.conversion-landing-heading {
	padding-top: 40px;
	padding-bottom: 35px;
	@include from-breakpoint('md') {
		padding-bottom: 0;
	}
	@include from-breakpoint('lg') {
		padding-top: 100px;
	}

	&__mobile-image {
		@include from-breakpoint('md') {
			display: none;
		}
	}
	h1#{&}__title {
		position: relative;
		margin-top: -20px;

		text-align: center;
		font-size: 30px;

		@include from-breakpoint('md') {
			margin-top: 50px;
			text-align: left;
		}
		@include from-breakpoint('lg') {
			font-size: 46px;
		}
	}
	&__description {
		margin-top: 20px;
		font-size: 16px;
		text-align: center;

		@include from-breakpoint('md') {
			text-align: left;
		}
		@include from-breakpoint('lg') {
			margin-top: 30px;
			font-size: 21px;
		}
	}
	&__button {
		margin-top: 30px;
		text-align: center;

		@include from-breakpoint('md') {
			text-align: left;
		}

		a {
			background-color: $color-contrast;
			color: $color-light;
			padding: 10px 26px;
		}
	}
	&__image {
		display: none;
		@include from-breakpoint('md') {
			display: block;
		}
	}
}

.conversion-landing-reasons {
	padding-top: 50px;
	padding-bottom: 0;

	&__title {
		font-size: 28px;
		line-height: 1.3;
		letter-spacing: 0.002em;

		@include from-breakpoint('lg') {
			font-size: 32px;
		}
	}
	&__info {
		font-size: 16px;
		line-height: 1.4;

		@include from-breakpoint('lg') {
			font-size: 21px;
			line-height: 1.6;
		}

		p + p {
			margin-top: 20px;
		}
	}
}

.conversion-landing-reason-columns {
	padding-top: 8px;

	@include from-breakpoint('lg') {
		padding-top: 40px;
	}

	&__reason {
		padding-bottom: 40px;
	}
	&__reason-icon {
		width: 40px;
		height: 40px;
	}
	h3#{&}__reason-title {
		margin-top: 15px;
		font-size: 16px;
		line-height: 1.5;

		@include from-breakpoint('lg') {
			font-size: 21px;
		}
	}
	p#{&}__reason-description {
		margin-top: 15px;
		font-size: 16px;
		line-height: 1.5;

		@include from-breakpoint('lg') {
			font-size: 21px;
			line-height: 1.6;
		}
	}
}

.conversion-landing-example {
	$this: &;

	@include from-breakpoint('md') {
		padding-top: 0;
	}

	&__title {
		font-size: 28px;
		line-height: 1.3;
		letter-spacing: 0.002em;

		@include from-breakpoint('md') {
			padding-top: 40px;
		}
		@include from-breakpoint('lg') {
			font-size: 32px;
		}
	}
	&__info {
		margin-top: 20px;
		@include from-breakpoint('lg') {
			margin-top: 30px;
		}

		p + p {
			margin-top: 20px;
			@include from-breakpoint('lg') {
				margin-top: 30px;
			}
		}
	}
	&__button {
		opacity: 0;
		pointer-events: none;

		margin-top: 30px;

		transition: 0.3s 0.3s ease-in;

		@include before-breakpoint('md') {
			display: none;
		}

		&--mobile {
			text-align: center;

			@include before-breakpoint('md') {
				display: block;
			}

			@include from-breakpoint('md') {
				display: none;
			}
		}

		&--visible {
			opacity: 1;
			pointer-events: all;
		}

		a {
			background-color: $color-contrast;
			color: $color-light;
			padding: 10px 26px;
		}
	}

	&__scheme {
		padding-top: 40px;

		@include from-breakpoint('md') {
			padding-top: 0;
		}
		@include from-breakpoint('lg') {
			padding-left: 95px;
		}
	}
	&__state-title {
		&--before {
			display: block;
		}
		&--after {
			display: none;
		}
	}
	&__state-data {
		position: relative;
		z-index: 1;
		margin-top: -1.5em;
		text-align: center;

		&:before,
		&:after {
			position: absolute;
			content: '';
			z-index: -1;
			bottom: 0;

			width: 1px;
			height: 90%;
			border: 1px dashed #dee7ee;
		}

		&:before {
			top: 46px;
			left: 27px;
			transform: rotate(354deg);

			@include from-breakpoint('sm') {
				top: 46px;
				left: 43px;
				transform: rotate(351deg);
			}
			@include from-breakpoint('md') {
				top: 42px;
				left: 34px;
				transform: rotate(352deg);
			}
		}
		&:after {
			top: 46px;
			right: 27px;
			transform: rotate(6deg);

			@include from-breakpoint('sm') {
				top: 46px;
				right: 43px;
				transform: rotate(9deg);
			}
			@include from-breakpoint('md') {
				top: 42px;
				right: 34px;
				transform: rotate(8deg);
			}
		}
	}
  &__conversion-after {
    display: none;
  }
	&__state-data-inner {
		position: relative;

		&--animated {
			#{$this}__conversion-before {
				display: none;
			}
			#{$this}__conversion-after {
				display: inline;
			}
		}
	}
	&__confetti {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: -100px;

		width: 100%;
		height: calc(100% + 100px);

		@include from-breakpoint('md') {
			bottom: -100px;
			height: calc(100% + 100px);
		}
	}
	&__state-data-item {
		position: relative;
		margin-top: 0;
		margin-bottom: 18px;

		&:before,
		&:after {
			position: absolute;
			content: '';
			left: 50%;
			transform: translateX(-50%);
		}

		&:before {
			bottom: -16px;

			width: 1px;
			height: 55px;
			box-sizing: border-box;
		}

		&:after {
			bottom: -16px;

			width: 0;
			height: 0;
			border-left: 3px solid transparent;
			border-right: 3px solid transparent;
			border-top-style: solid;
			border-top-width: 3px;
		}

		&--before {
		}
		&--after {
		}
		&--last {
			#{$this}__state-data-item-name {
				&:after {
					display: none;
				}
			}
			&:after,
			&:before {
				display: none;
			}
		}
		&--blue {
			#{$this}__state-data-item-increase {
				color: #03a9f4;
			}
			#{$this}__state-data-item-info {
				border-color: #03a9f4;
			}
			#{$this}__state-data-item-name {
				width: 100%;
				background-color: #d9eefd;
				border-color: #03a9f4;

				&:after {
					border-color: #03a9f4;
				}
			}
			&:before {
				background-color: #03a9f4;
			}
			&:after {
				border-top-color: #03a9f4;
			}
		}
		&--green {
			#{$this}__state-data-item-increase {
				color: #72c850;
			}
			#{$this}__state-data-item-info {
				border-color: #72c850;
			}
			#{$this}__state-data-item-name {
				width: 92%;
				background-color: #e5f5df;
				border-color: #72c850;

				&:after {
					border-color: #72c850;
				}
			}
			&:before {
				background-color: #72c850;
			}
			&:after {
				border-top-color: #72c850;
			}
		}
		&--yellow {
			#{$this}__state-data-item-increase {
				color: #ffc539;
			}
			#{$this}__state-data-item-info {
				border-color: #ffc539;
			}
			#{$this}__state-data-item-name {
				width: 85%;
				background-color: #fff4db;
				border-color: #ffc539;

				&:after {
					border-color: #ffc539;
				}
			}
			&:before {
				background-color: #ffc539;
			}
			&:after {
				border-top-color: #ffc539;
			}
		}
		&--orange {
			#{$this}__state-data-item-increase {
				color: #ff6f00;
			}
			#{$this}__state-data-item-info {
				border-color: #ff6f00;
			}
			#{$this}__state-data-item-name {
				width: 77%;
				background-color: #fee6db;
				border-color: #ff6f00;

				&:after {
					border-color: #ff6f00;
				}
			}
			&:before {
				background-color: #ff6f00;
			}
			&:after {
				border-top-color: #ff6f00;
			}
		}
		&--aquamarine {
			#{$this}__state-data-item-increase {
				color: #009688;
			}
			#{$this}__state-data-item-info {
				border-color: #009688;
			}
			#{$this}__state-data-item-name {
				width: 70%;
				background-color: #f5fffe;
				border-color: #009688;

				&:after {
					border-color: #009688;
				}
			}
			&:before {
				background-color: #009688;
			}
			&:after {
				border-top-color: #009688;
			}
		}
	}
	&__state-data-item-value {
		position: relative;
		font-size: 21px;
		line-height: 1.6;

		&--after {
			font-weight: bold;
		}
	}
	&__state-data-item-metric {
	}
	&__state-data-item-name {
		position: relative;
		margin: auto;
		margin-top: 0;

		border-width: 1px;
		border-style: solid;
		border-radius: 50%;
		padding: 5px 0 6px;

		font-size: 14px;
		line-height: 1;

		&:after {
			position: absolute;
			content: '';
			bottom: -3px;
			left: 50%;
			transform: translateX(-50%);

			width: 5px;
			height: 5px;
			box-sizing: border-box;

			border-radius: 50%;
			border-width: 1px;
			border-style: solid;
			background-color: $color-light;
		}
	}
	&__state-data-item-info {
		position: relative;
		display: inline-block;
		margin-top: 20px;
		padding: 2px 8px 3px;
		border-radius: 8px;

		font-size: 12px;
		line-height: 1;

		border-width: 1px;
		border-style: solid;
		background-color: $color-light;
	}
	&__state-data-item-increase {
		opacity: 0;
		position: absolute;
		top: 50%;
		margin-top: 0;
		margin-left: 15px;

		font-size: 16px;
		line-height: 1.6;
		font-weight: bold;

		word-break: keep-all;
		min-width: 100px;
		text-align: left;

		&--visible {
			animation: stateValueIncrease 0.4s ease-in 0.5s forwards;

			@keyframes stateValueIncrease {
				0% {
					opacity: 0;
					transform: translateY(0);
				}
				100% {
					opacity: 1;
					transform: translateY(-50%);
				}
			}
		}
	}
}

.conversion-landing-stages {
	padding-top: 50px;
	@include from-breakpoint('lg') {
		padding-top: 80px;
	}

	&__title {
		font-size: 28px;
		line-height: 1.3;
		letter-spacing: 0.002em;
		@include from-breakpoint('lg') {
			font-size: 32px;
		}
	}
	&__info {
		font-size: 16px;
		line-height: 1.5;
		@include from-breakpoint('lg') {
			font-size: 21px;
			line-height: 1.6;
		}

		p + p {
			margin-top: 20px;
			@include from-breakpoint('lg') {
				margin-top: 30px;
			}
		}
	}
	&__image {
		display: none;
		@include from-breakpoint('md') {
			display: block;
		}
		@include from-breakpoint('lg') {
			padding-left: 95px;
		}
	}
	&__stage {
	}
	&__note {
	}
}

.conversion-landing-stages-continuation {
	@include from-breakpoint('md') {
		padding-top: 10px;
	}
	&--last {
		@include from-breakpoint('md') {
			padding-top: 0;
			padding-bottom: 80px;
		}
	}
}

.stage {
	&--01 {
		margin-top: 30px;
	}
	&--02 {
		@include from-breakpoint('md') {
			margin-top: 150px;
		}
	}
	&--03 {
	}
	&--04 {
		@include from-breakpoint('md') {
			margin-top: 260px;
		}
	}
	&--05 {
	}

	&__heading {
		display: flex;
		flex-flow: row nowrap;
	}
	&__number {
		margin-right: 20px;
		font-weight: 900;
		font-size: 48px;
		line-height: 0.75;
		word-break: keep-all;

		&--blue {
			color: #d9eefd;
			-webkit-text-stroke: 2px #03a9f4;
			@include ie-specific {
				color: #03a9f4;
			}
		}
		&--green {
			color: #e5f5df;
			-webkit-text-stroke: 2px #72c850;
			@include ie-specific {
				color: #72c850;
			}
		}
		&--yellow {
			color: #fff4db;
			-webkit-text-stroke: 2px #ffc539;
			@include ie-specific {
				color: #ffc539;
			}
		}
		&--orange {
			color: #fee6db;
			-webkit-text-stroke: 2px #ff6f00;
			@include ie-specific {
				color: #ff6f00;
			}
		}
		&--aquamarine {
			color: #f5fffe;
			-webkit-text-stroke: 2px #009688;
			@include ie-specific {
				color: #009688;
			}
		}
	}
	&__heading-main {
		margin-top: 0;
	}
	&__title {
		margin: 0;
		font-weight: bold;
		font-size: 16px;
		line-height: 1;
		font-family: $font-primary !important;

		@include from-breakpoint('lg') {
			font-size: 21px !important;
		}
	}
	&__duration {
		margin-top: 10px !important;
		font-size: 14px;
		line-height: 1;
		color: $color-contrast;
	}
	&__about {
		margin-top: 20px;
	}
	&__about-item {
		margin-top: 20px;
		font-size: 16px;
		line-height: 1.3;

		@include from-breakpoint('lg') {
			font-size: 18px;
		}

		&:before {
			color: $color-contrast;
		}
	}

	&__result {
		position: relative;
		margin-top: 20px;
		display: flex;
		flex-flow: row nowrap;
	}
	&__result-icon {
		position: relative;
		margin-right: 10px;

		height: 32px;
		width: 32px;
		min-width: 32px;

		border-radius: 50%;
		background-color: #72c850;

		&:after {
			position: absolute;
			display: inline-block;
			content: '';

			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);

			width: 18px;
			height: 12px;

			vertical-align: top;
			background: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAMCAYAAABvEu28AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABWSURBVHgBtdLRCQAgCARQR2qERmmT2qzRrqI+QhLyogO/hAceijwEQMZMFDYbwkMKScLkPzLuW4tMIwoysetzxtLC3J2cMLDFKqxSiIFxyIaFPgWOj21Obe8hhDkGDAAAAABJRU5ErkJggg==');
		}
	}
	&__result-text {
		margin-top: 0;
		font-size: 16px;
		font-weight: bold;
		line-height: 1.3;

		@include from-breakpoint('lg') {
			font-size: 18px;
		}
	}
	&__result-path {
		@include from-breakpoint('md') {
			position: absolute;
			top: 16px;
			z-index: -1;

			width: 100%;
			height: 68px;

			&:after {
				position: absolute;
				content: '';
				bottom: -3px;

				width: 0;
				height: 0;
			}

			&--right {
				left: 16px;

				border-left: 1px solid #72c850;
				border-bottom: 1px solid #72c850;
				border-bottom-left-radius: 10px;

				&:after {
					right: -3px;
					border-top: 3px solid transparent;
					border-left: 3px solid #72c850;
					border-bottom: 3px solid transparent;
				}
			}

			&--left {
				top: 2px;
				right: 100%;

				border-top: 1px solid #72c850;
				border-left: 1px solid #72c850;
				border-top-left-radius: 10px;

				&:after {
					left: -3px;
					border-top: 3px solid #72c850;
					border-left: 3px solid transparent;
					border-right: 3px solid transparent;
				}
			}

			&--hight {
				height: 100px;
			}
		}
	}
	&__result-arrow {
	}
}

.note {
	position: relative;
	padding: 20px 15px 20px 37px;
	margin: 20px -16px 40px;
	background-color: #f7f6f6;

	@include from-breakpoint('lg') {
		padding: 20px 54px;
		margin: 0;

		&:after {
			position: absolute;
			content: '';
			z-index: -1;

			left: 0;
			top: 0;
			bottom: 0;

			height: 100%;
			width: 50vw;

			background: #f7f6f6;
		}
	}

	&__inner {
		position: relative;

		&:before {
			display: inline-block;
			position: absolute;
			content: '';

			top: 6px;
			left: -15px;
			transform: rotate(45deg);

			width: 10px;
			height: 10px;

			border-style: solid;
			border-width: 3px 3px 0 0;
			vertical-align: top;
			color: $color-contrast;

			@include from-breakpoint('lg') {
				left: -30px;
			}
		}
	}
	&__content {
		font-size: 16px;
		line-height: 1.6;
	}
	a {
		color: $color-contrast;
		text-decoration: underline;

		&:hover {
			text-decoration: none;
		}
	}
}

.conversion-landing-result {
	position: relative;
	padding-top: 30px;

	&__title {
		margin-top: 20px;
		font-size: 28px;
		line-height: 1.3;
		letter-spacing: 0.002em;

		@include from-breakpoint('md') {
			margin-top: 68px;
			font-size: 32px;
		}
	}
	&__info {
		font-size: 16px;
		line-height: 1.5;

		@include from-breakpoint('lg') {
			font-size: 21px;
		}
	}
	&__statistics-background {
		margin: 20px -16px 0;
		min-height: 350px;

		@include from-breakpoint('md') {
			margin: 0;
			min-height: auto;
		}
	}
	&__statistics {
		position: absolute;
		z-index: 1;
		transform: translateY(20px);

		@include from-breakpoint('md') {
			transform: translateY(0);
			top: 100px;
		}
	}
}
