* {
	&,
	&::before,
	&::after {
		box-sizing: border-box;
		font-feature-settings: 'ss08';
	}
}

body {
	font-family: $font-primary;
	margin: 0;
}

::selection {
	background: $color-quaternary;
}

fieldset {
	padding: 0;
	border: none;
	margin: 0;
}

.hidden {
	@include hidden-accessible;
}

ul {
	li::marker {
		color: red;
	}
}
