@import './shared.scss';

.team {
	&__header {
		padding-top: 40px;
		padding-bottom: 24px;
		@include from-breakpoint('md') {
			padding-top: 100px;
			padding-bottom: 66px;
		}
		h1 {
			@include h1;
		}
	}

	&__subtitle {
		& > h2 {
			font: 600 24px/1.33 $font-secondary;
			@include from-breakpoint('lg') {
				font-size: $h2-font-size--lg;
			}
			margin-bottom: 32px;
		}
	}

	&__list {
		margin-left: $team-gutter * -0.5;
		margin-right: $team-gutter * -0.5;
		margin-bottom: 48px;

		@include from-breakpoint('sm') {
			display: flex;
			flex-wrap: wrap;
		}
	}

	&__card-wrapper {
		display: flex;
	}

	&__card {
		$team-card: &;

		display: block;
		margin-left: $team-gutter * 0.5;
		margin-right: $team-gutter * 0.5;
		margin-bottom: 24px;
		background: white;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
		overflow: hidden;
		height: calc(100% - 24px);
		text-decoration: none;
		color: inherit;
		cursor: pointer;

		&-person {
			width: 100%;
		}
	}

	&__banner-holder {
		margin-left: $team-gutter * 0.5;
		margin-right: $team-gutter * 0.5;
		margin-bottom: 24px;
		height: calc(100% - 24px);
		min-height: 317px;
		@include from-breakpoint('md') {
			max-height: 317px;
		}
		@include from-breakpoint('xxl') {
			max-height: none;
		}
	}
	&__banner {
		width: 100%;
		height: 100%;
		overflow: hidden;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: left bottom;
		}
	}
	&__divisions {
		margin-bottom: 32px;
	}
}
