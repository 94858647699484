.faq {
	$block: &;

	padding: 80px 0;
	background-color: $color-brand-primary-new;

	@include from-breakpoint('md') {
		padding: 100px 0;
	}

	@include from-breakpoint('lg') {
		padding: 120px 0;
	}

	&--light {
		background-color: $color-light;

		#{$block}__number {
			display: none;
		}

		#{$block}__icon {
			color: $color-brand-primary-new;
		}

		#{$block}__content {
			padding-left: 0;
		}
	}

	&__title {
		@include h2;

		margin: 0;
		margin-bottom: 57px;
		text-transform: uppercase;

		@include from-breakpoint('md') {
			max-width: 600px;
		}

		@include from-breakpoint('lg') {
			font-size: $h2-font-size--lg;
			line-height: 1;
			max-width: 800px;
		}
	}

	&__list {
		@include reset-list;

		&--dark {
			margin-top: 70px;
			color: $color-light;

			#{$block}__item-title {
				color: $color-brand-primary-new;
			}

			#{$block}__number {
				display: none;
			}

			#{$block}__icon,
			#{$block}__content {
				color: $color-light;
			}

			#{$block}__content {
				max-width: 1000px;
				padding-left: 0;
			}
		}
	}

	&__item {
		border-bottom: 1px solid rgba($color-light, 0.3);
		transition: padding $transition-default;

		&:last-child {
			border: none;
		}

		&.is-active {
			padding-bottom: 30px;

			#{$block}__content {
				max-height: 4000px;
			}

			#{$block}__icon {
				transform: rotate(45deg);
			}
		}
	}

	&__header {
		margin: 0;
		padding: 30px 0;
		width: 100%;
		display: flex;
		align-items: center;
		gap: 28px;
		border: none;
		background-color: transparent;
		cursor: pointer;

		@include from-breakpoint('md') {
			gap: 90px;
		}

		@include from-breakpoint('lg') {
			gap: 150px;
		}
	}

	&__number {
		@include h3;

		min-width: 55px;
		color: $color-background--muted;

		@include from-breakpoint('md') {
			font-size: $h3-font-size--xl;
		}
	}

	&__item-title {
		@include h3;

		margin: 0;
		text-align: left;
		color: $color-black;

		@include from-breakpoint('md') {
			font-size: $h3-font-size--xl;
		}
	}

	&__icon {
		margin-left: auto;
		margin-right: 12px;
		width: 16px;
		height: 16px;
		display: block;
		color: $color-background--muted;
		transition: transform $transition-default;

		@include from-breakpoint('md') {
			margin-right: 18px;
			width: 24px;
			height: 24px;
		}
	}

	&__content {
		@include body-1;

		padding-left: 60px;
		max-width: 795px;
		max-height: 0;
		overflow: hidden;
		transition: max-height $transition-default;

		@include from-breakpoint('md') {
			padding-left: 145px;
		}

		@include from-breakpoint('lg') {
			padding-left: 205px;
		}

		p {
			& + & {
				margin: 20px 0 0;
			}
		}
	}
}
