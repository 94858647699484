.projects {
	$block: &;

	padding-top: $custom__header-height; // добавить + 40px для селекта категорий .custom__header-additional > .projects-select
	@include from-breakpoint('md') {
		padding-top: 0;
	}

	&__limiter {
		max-width: 960px;
		padding: 0 $base;
		margin: auto;
	}

	&__description {
		padding: 3 * $base 0;
		@include from-breakpoint('md') {
			padding: 4 * $base 0;
		}
	}
	&__select {
		@include from-breakpoint('md') {
			display: none;
		}
	}

	& .hero__title {
		margin-bottom: 30px;

		@include from-breakpoint('lg') {
			margin-bottom: 50px;
		}
	}
}
