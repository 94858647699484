.portfolio-card {
	$this: &;

	text-decoration: none;
	color: $color-black;
	margin-bottom: 120px;

	&:hover,
	&:focus-visible {
		#{$this}__title {
			color: $color-primary;
		}
	}

	@include from-breakpoint('xl') {
		margin-bottom: 210px;
	}

	&__image {
		margin: 0 0 25px 0;
		@include from-breakpoint('lg') {
			margin: 0;
		}

		img {
			width: 100%;
		}
	}

	&__video-container {
		position: relative;
		width: 100%;
		height: 100%;
		video {
			display: block;
		}
	}
	&__fallback-image {
		display: none;
		width: 100%;
		height: auto;
	}

	&__content {
		margin-bottom: 120px;

		@include from-breakpoint('lg') {
			margin-bottom: 0;
		}
	}

	&__title {
		margin: 0 0 20px 0;
		font-size: 32px;
		line-height: 1.1;
		font-weight: 700;
		transition: color 0.3s;

		@include from-breakpoint('lg') {
			margin: 0 0 30px 0;
			font-size: 39px;
			line-height: 1.1;
		}
	}

	&__text-1 {
		@include body-2;

		p:last-child {
			margin-bottom: 0;
		}
		p:first-child {
			margin-top: 0;
		}

		p + p {
			margin-top: 16px;
		}
	}

	&__text-2 {
		margin-top: 23px;
		font-size: $base;
		line-height: 1.6;

		p:last-child {
			margin-bottom: 0;
		}
		p:first-child {
			margin-top: 0;
		}
	}

	&__awards {
		position: relative;

		&--desktop {
			display: none !important;
		}

		@include from-breakpoint('sm') {
			padding-left: 55px;
			min-height: 140px;
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
		@include from-breakpoint('lg') {
			&--desktop {
				display: flex !important;
			}
			&--mobile {
				display: none !important;
			}
		}
	}
	&__awards-icon {
		width: 60px;
		height: 60px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: 0 0;

		img {
			width: 100%;
			height: 100%;
			object-fit: contain;
		}

		@include from-breakpoint('sm') {
			width: 140px;
			height: 140px;
			position: absolute;
			top: 0;
			left: 0;
		}
	}

	&__awards-text {
		position: relative;
		line-height: 1.6;
		p {
			margin: 0;
		}
	}

	&__image {
		position: relative;
		will-change: transform;
		transition: transform $transition-default, left $transition-default;
	}

	&--type-1 {
		position: relative;

		@include from-breakpoint('lg') {
			display: grid;
			grid-template-columns: 20% 29% 51%;
		}

		#{$this}__image {
			@include from-breakpoint('lg') {
				grid-column: 2 / span 2;
				grid-row: 1;
				position: relative;
				z-index: -1;
			}
		}

		#{$this}__content {
			@include from-breakpoint('sm') {
				display: grid;
				grid-template-columns: 60% 40%;
				position: relative;
			}

			@include from-breakpoint('lg') {
				position: static;
				grid-column: 1 / span 2;
				grid-row: 1;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 25px 0;
			}
		}

		#{$this}__awards {
			display: flex;
			align-items: center;
			gap: 25px;

			@include from-breakpoint('sm') {
				position: absolute;
				top: 0;
				right: 0;
				width: 38%;
				align-items: flex-start;
				justify-content: center;
			}

			@include from-breakpoint('lg') {
				width: 156px;
				height: 156px;
				top: 15px;
				right: 25px;

				&--with-text {
					width: 156px;
				}
			}
		}
		#{$this}__awards-icon {
			@include from-breakpoint('lg') {
				height: 156px;
				width: 156px;
			}
		}
		#{$this}__title {
			@include from-breakpoint('sm') {
				grid-column: 1;
			}
		}
		#{$this}__text-1 {
			margin-top: 23px;
			@include from-breakpoint('sm') {
				grid-column: 1;
			}
			@include from-breakpoint('lg') {
				margin-top: 0;
			}
		}
	}
	&--type-2 {
		@include from-breakpoint('lg') {
			display: grid;
			grid-template-columns: 75% 25%;
		}
		#{$this}__image {
			@include from-breakpoint('lg') {
				grid-column: 1;
				grid-row: 1;
				padding-left: 12%;
			}
		}
		#{$this}__content {
			position: relative;
			display: flex;
			flex-direction: column;

			@include from-breakpoint('sm') {
				display: grid;
				grid-template-columns: 62% 38%;
			}
			@include from-breakpoint('lg') {
				grid-column: 1 / span 2;
				grid-row: 1;
				grid-template-columns: 75% 25%;
				padding: 25px 0;
			}
		}
		#{$this}__awards {
			display: flex;
			align-items: center;
			gap: 25px;

			@include from-breakpoint('sm') {
				position: absolute;
				top: 0;
				right: 0;
				width: 38%;
				align-items: flex-start;
				justify-content: center;
			}
			@include from-breakpoint('lg') {
				width: calc(25% + 30px);
				top: 25px;
			}
		}
		#{$this}__title {
			@include from-breakpoint('sm') {
				grid-column: 1;
			}
			@include from-breakpoint('lg') {
				grid-row: 1;
				max-width: 45%;
			}
		}
		#{$this}__text-2 {
			@include from-breakpoint('sm') {
				grid-column: 1;
				margin-top: 0;
			}
			@include from-breakpoint('lg') {
				grid-column: 2;
				grid-row: 1 / span 2;
				align-self: end;
				padding: 160px 0 0 25px;
			}
		}
		#{$this}__text-1 {
			display: block;
			line-height: 1.2;
			order: 5;
			margin-top: 20px;

			@include from-breakpoint('sm') {
				grid-column: 2;
				grid-row: 1 / span 2;
				padding: 160px 0 0 55px;
				margin-top: 0;
				order: unset;
			}
			@include from-breakpoint('lg') {
				grid-column: 1;
				grid-row: 2;
				align-self: end;
				padding: 0;
				max-width: 29%;
			}
		}
	}
	&--type-3 {
		@include from-breakpoint('lg') {
			position: relative;
			display: grid;
			grid-template-columns: 67% 16% 17%;
		}
		#{$this}__image {
			@include from-breakpoint('md') {
				position: relative;
				grid-column: 1 / span 2;
				grid-row: 1;

				#{$this}__video-container--with-triangles {
					position: relative;
					&:after {
						display: block;
						content: '';
						position: absolute;
						left: 0;
						top: 0;
						height: 100%;
						width: 100%;
						z-index: 10;
						background: url('../images/two-triangles.svg') no-repeat
							0 0;
						background-size: auto 100%;
						pointer-events: none;
					}
				}
			}
		}
		#{$this}__content {
			display: flex;
			flex-direction: column;
			@include from-breakpoint('sm') {
				display: block;
			}
			@include from-breakpoint('lg') {
				grid-column: 2 / span 2;
				grid-row: 1;
				z-index: 10;
				display: flex;
				gap: 0;
				flex-direction: column;
				justify-content: space-between;
				padding: 25px 0;
			}
		}
		#{$this}__awards {
			display: flex;
			align-items: center;
			gap: 25px;
			margin-bottom: 23px;
			order: 1;

			@include from-breakpoint('sm') {
				float: right;
				width: 38%;
				margin-left: 30px;
				align-items: flex-start;
				justify-content: center;
			}
			@include from-breakpoint('lg') {
				position: absolute;
				left: 15px;
				top: 25px;
				margin-left: 0;
			}
		}
		#{$this}__title {
			order: 0;
			@include from-breakpoint('sm') {
				display: table;
			}
		}
		#{$this}__text-2 {
			display: none;
			line-height: 1.2;

			@include from-breakpoint('lg') {
				display: block;
			}

			&--mobile {
				display: block;
				@include from-breakpoint('lg') {
					display: none;
				}
			}
		}
		#{$this}__text-1 {
			@include from-breakpoint('sm') {
				display: table;
			}
			@include from-breakpoint('lg') {
				position: relative;
				display: block;
				grid-column: 1;
				grid-row: 1;
				align-self: end;
				width: 75%;
				padding: 25px 0 25px 77px;
				z-index: 5;
			}
		}
	}
	&--type-4 {
		@include from-breakpoint('lg') {
			position: relative;
			display: grid;
			grid-template-columns: 66% 17% 17%;
		}
		#{$this}__image {
			@include from-breakpoint('lg') {
				grid-column: 1 / span 2;
				grid-row: 1;
			}
		}
		#{$this}__content {
			display: flex;
			flex-direction: column;
			@include from-breakpoint('sm') {
				display: block;
			}
			@include from-breakpoint('lg') {
				grid-column: 2 / span 2;
				grid-row: 1;
				z-index: 10;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 25px 0;
			}
		}

		#{$this}__awards {
			display: flex;
			align-items: center;
			gap: 25px;
			margin-bottom: 23px;
			order: 1;

			@include from-breakpoint('sm') {
				float: right;
				width: 38%;
				margin-left: 30px;
				align-items: flex-start;
				justify-content: center;
			}
			@include from-breakpoint('lg') {
				position: absolute;
				left: 15px;
				top: 25px;
				margin-left: 0;
			}
		}

		#{$this}__title {
			order: 0;
			@include from-breakpoint('sm') {
				display: table;
			}
		}
		#{$this}__text-1 {
			order: 2;
			@include from-breakpoint('sm') {
				display: table;
			}
		}
	}
	&--type-5 {
		@include from-breakpoint('lg') {
			position: relative;
			display: grid;
			grid-template-columns: 66% 34%;
		}
		#{$this}__image {
			@include from-breakpoint('lg') {
				grid-column: 1;
				grid-row: 1;
				padding-left: 13%;
			}
		}
		#{$this}__content {
			@include from-breakpoint('sm') {
				display: grid;
				grid-template-columns: 60% 31%;
				column-gap: 9%;
			}
			@include from-breakpoint('lg') {
				grid-column: 2;
				grid-row: 1;
				z-index: 10;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 25px 0 25px 25px;
			}
		}
		#{$this}__title {
			@include from-breakpoint('sm') {
				grid-column: 1;
			}
		}
		#{$this}__text-1 {
			@include from-breakpoint('sm') {
				grid-column: 1;
				margin-top: 0;
			}
		}
		#{$this}__text-2 {
			padding-left: 60px;
			display: block;
			line-height: 1.2;
			background-image: url('../images/time.png');
			background-repeat: no-repeat;
			background-size: 70px 70px;
			background-position: top left;

			@include from-breakpoint('sm') {
				grid-column: 2;
				grid-row: 1 / span 2;
				background-size: 100px 100px;
				background-position: unset;
				padding: 81px 0 0;
				margin-top: 0;
			}

			@include from-breakpoint('lg') {
				position: absolute;
				left: -194%;
				bottom: 25px;
				background-size: 140px 140px;
				padding: 114px 0 0;
			}
		}
	}
	&--type-6 {
		@include from-breakpoint('lg') {
			position: relative;
			display: grid;
			grid-template-columns: 24% 8% 68%;
		}
		#{$this}__image {
			@include from-breakpoint('lg') {
				grid-column: 2 / span 2;
				grid-row: 1;
			}
		}
		#{$this}__content {
			display: flex;
			flex-direction: column;
			@include from-breakpoint('sm') {
				display: block;
			}
			@include from-breakpoint('lg') {
				grid-column: 1 / span 2;
				grid-row: 1;
				z-index: 10;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 25px 0;
			}
		}
		#{$this}__awards {
			display: flex;
			align-items: center;
			gap: 25px;
			margin-bottom: 23px;
			order: 1;

			@include from-breakpoint('sm') {
				float: right;
				width: 38%;
				margin-left: 30px;
				align-items: flex-start;
				justify-content: center;
			}
			@include from-breakpoint('lg') {
				position: absolute;
				margin-left: 0;
				width: 140px;
				height: 140px;
				top: 15px;
				right: 25px;

				&--with-text {
					width: 156px;
				}
			}
		}
		#{$this}__title {
			order: 0;
			@include from-breakpoint('sm') {
				display: table;
			}
		}
		#{$this}__text-1 {
			order: 2;
			@include from-breakpoint('sm') {
				display: table;
			}
		}
	}
	&--type-7 {
		@include from-breakpoint('lg') {
			display: grid;
			grid-template-columns: 74% 26%;
		}
		#{$this}__image {
			@include from-breakpoint('lg') {
				grid-column: 1;
				grid-row: 1;
				padding-left: 12%;
			}
		}
		#{$this}__content {
			position: relative;
			@include from-breakpoint('sm') {
				display: grid;
				grid-template-columns: 62% 38%;
			}
			@include from-breakpoint('lg') {
				grid-column: 1 / span 2;
				grid-row: 1;
				grid-template-columns: 74% 26%;
				padding: 25px 0;
			}
		}
		#{$this}__awards {
			display: flex;
			align-items: center;
			gap: 25px;

			@include from-breakpoint('sm') {
				position: absolute;
				top: 0;
				right: 0;
				width: 38%;
				align-items: flex-start;
				justify-content: center;
			}
			@include from-breakpoint('lg') {
				width: calc(26% + 30px);
				top: 25px;
			}
		}
		#{$this}__title {
			@include from-breakpoint('sm') {
				grid-column: 1;
			}
			@include from-breakpoint('lg') {
				grid-row: 1;
				max-width: 47%;
			}
		}
		#{$this}__text-2 {
			display: block;
			@include from-breakpoint('sm') {
				grid-column: 2;
				grid-row: 1 / span 2;
				padding: 160px 0 0 55px;
			}
			@include from-breakpoint('lg') {
				grid-column: 2;
				grid-row: 1 / span 2;
				align-self: end;
				margin: 0;
				padding: 160px 0 0 25px;
			}
		}
		#{$this}__text-1 {
			margin-top: 23px;
			@include from-breakpoint('sm') {
				grid-column: 1;
			}
			@include from-breakpoint('lg') {
				grid-column: 1;
				grid-row: 2;
				align-self: end;
				padding: 0;
				max-width: 47%;
				margin-top: 0;
			}
		}
	}
	&--type-8 {
		@include from-breakpoint('lg') {
			position: relative;
			display: grid;
			grid-template-columns: 62% 26% 12%;
		}
		#{$this}__image {
			@include from-breakpoint('lg') {
				grid-column: 1 / span 2;
				grid-row: 1;
			}
		}
		#{$this}__content {
			display: flex;
			flex-direction: column;
			@include from-breakpoint('sm') {
				display: block;
			}
			@include from-breakpoint('lg') {
				grid-column: 2 / span 2;
				grid-row: 1;
				z-index: 10;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 25px 0;
			}
		}
		#{$this}__awards {
			display: flex;
			align-items: center;
			gap: 25px;
			margin-bottom: 23px;
			order: 1;

			@include from-breakpoint('sm') {
				float: right;
				width: 38%;
				margin-left: 30px;
				align-items: flex-start;
				justify-content: center;
			}
			@include from-breakpoint('lg') {
				position: absolute;
				left: 15px;
				top: 25px;
				margin-left: 0;
			}
		}

		#{$this}__title {
			order: 0;
			@include from-breakpoint('sm') {
				display: table;
			}
		}
		#{$this}__text-1 {
			order: 2;
			@include from-breakpoint('sm') {
				display: table;
			}
		}
	}
	&--type-9 {
		@include from-breakpoint('lg') {
			position: relative;
			display: grid;
			grid-template-columns: 25% 18% 57%;
		}
		#{$this}__image {
			@include from-breakpoint('lg') {
				grid-column: 2 / span 2;
				grid-row: 1;
			}
		}
		#{$this}__content {
			display: flex;
			flex-direction: column;
			@include from-breakpoint('sm') {
				display: block;
			}
			@include from-breakpoint('lg') {
				grid-column: 1 / span 2;
				grid-row: 1;
				z-index: 10;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				padding: 25px 0;
			}
		}
		#{$this}__awards {
			display: flex;
			align-items: center;
			gap: 25px;
			margin-bottom: 23px;
			order: 1;

			@include from-breakpoint('sm') {
				float: right;
				width: 38%;
				margin-left: 30px;
				align-items: flex-start;
				justify-content: center;
			}
			@include from-breakpoint('lg') {
				position: absolute;
				margin-left: 0;
				width: 140px;
				height: 140px;
				top: 15px;
				right: 25px;

				&--with-text {
					width: 156px;
				}
			}
		}

		#{$this}__title {
			order: 0;
			@include from-breakpoint('sm') {
				display: table;
			}
		}
		#{$this}__text-1 {
			order: 2;
			@include from-breakpoint('sm') {
				display: table;
			}
			@include from-breakpoint('lg') {
				display: block;
				width: 75%;
			}
		}
	}
}
