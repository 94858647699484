// naming convention
$container: '.container';
$row: '.row';
$column: '.col';
$offset: 'offset';

// grid config
$column-count: 12;
$gutter: 30px;
$side-padding: 15px;
// $container-min-width: 320px;
$container-min-width: 1024px;
$container-max-width: 1200px;

$main-axis-alignments: (
	left: flex-start,
	center: center,
	right: flex-end,
	around: space-around,
	between: space-between,
);

$cross-axis-alignments: (
	top: flex-start,
	middle: center,
	bottom: flex-end,
	stretch: stretch,
	baseline: baseline,
);

#{$container} {
	box-sizing: border-box;
	margin-left: auto;
	margin-right: auto;
	padding-right: $gutter * 0.5;
	padding-left: $gutter * 0.5;
}

#{$row} {
	display: flex;
	flex: 0 1 auto;
	flex-flow: row wrap;
	margin-left: $gutter * -0.5;
	margin-right: $gutter * -0.5;
}

#{$column} {
	flex-grow: 1;
	flex-basis: 0;
	box-sizing: border-box;
	padding-right: $gutter * 0.5;
	padding-left: $gutter * 0.5;
	max-width: 100%;
}

@mixin limit-container($breakpoint) {
	#{$container} {
		@if ($breakpoint >= 1440px) {
			width: $container-max-width;
		} @else if ($breakpoint != 0) {
			width: $breakpoint;
		} @else {
			width: $container-min-width;
		}
		max-width: 100%;
	}
}

@mixin create-grid-alignments($breakpoint) {
	@each $suffix, $value in $main-axis-alignments {
		#{$row}-#{$breakpoint}-#{$suffix} {
			justify-content: $value;
		}
	}

	@each $suffix, $value in $cross-axis-alignments {
		#{$row}-#{$breakpoint}-#{$suffix} {
			align-items: $value;
		}

		#{$column}-#{$breakpoint}-#{$suffix} {
			align-self: $value;
		}
	}

	#{$column}-#{$breakpoint}-first {
		order: -1;
	}

	#{$column}-#{$breakpoint}-last {
		order: 1;
	}
}

@mixin create-columns($breakpoint, $column-count: $column-count) {
	@for $i from 1 through $column-count {
		#{$column}-#{$breakpoint}-#{$i} {
			flex-basis: percentage($i / $column-count);
			max-width: percentage($i / $column-count);
		}
	}
}

@mixin create-column-offsets($breakpoint, $column-count: $column-count) {
	@for $i from 1 through $column-count {
		#{$column}-#{$breakpoint}-offset-#{$i} {
			margin-left: percentage($i / $column-count);
		}
	}
}

//@mixin create-columns-order($breakpoint, $column-count: $column-count) {
//	@for $i from 1 through $column-count {
//		#{$column}-#{$breakpoint}-order-#{$i} {
//			order: $i;
//		}
//	}
//}

@each $breakpoint, $size in $breakpoints {
	@include from-breakpoint($breakpoint) {
		@include limit-container($size);
		@include create-grid-alignments($breakpoint);
		@include create-columns($breakpoint);
		@include create-column-offsets($breakpoint);
		//@include create-columns-order($breakpoint);
	}
}
