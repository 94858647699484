@import './shared.scss';

.division-page {
	padding-top: $custom__header-height;
	padding-bottom: 22px;
	@include from-breakpoint('md') {
		padding-top: $custom__header-height--md + 2 * $base;
		padding-bottom: 42px;
	}

	&__header {
		padding-top: 0;
	}

	&__description {
		font-size: 20px;
		line-height: 1.3;
		margin-bottom: 64px;
	}

	&__back {
		margin: 0 0 45px (-$base/2);
	}
}