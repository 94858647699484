.highlight {
	display: block;
	padding: $base * 1.5;
	box-shadow: 0 $base * 0.75 $base * 1.125 0 rgba(0, 0, 0, 0.3);
	text-decoration: none;
	overflow: hidden;
	background: $color-background;
	width: 340px;

	&__inner {
		margin: -$base * 1.5 / 2;
		display: flex;
		flex-flow: nowrap;
		justify-content: space-between;
		align-items: center;
	}

	&__cell {
		padding: $base * 1.5 / 2;
		flex: 1 1 100%;
	}

	&__title {
		display: block;
		white-space: pre-line;
		font-size: $base * 1.5;
		font-weight: bold;
		text-transform: uppercase;
		margin-bottom: $base * 1.5;
		color: #dbb620;

		@supports (background-clip: text) {
			background-image: linear-gradient(to right, #eac927, #dbb620);
			background-clip: text;
			color: transparent;
		}
	}

	&__picture-wrapper {
		display: block;
		position: relative;
		height: 140px;
		width: auto;

		@include picture__img() {
			object-fit: contain;
		}

		@include from-breakpoint('lg') {
			min-width: auto;
		}
	}

	&__picture {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		object-fit: contain;
		display: block;
	}
}
