.member {
	$block: &;

	display: flex;
	flex-flow: row nowrap;
	text-decoration: none;
	color: inherit;

	&__rest {
		width: $base * 4;
		height: $base * 4;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		justify-content: center;
		border-radius: 50%;
		font-family: $font-primary;
		font-size: $base * 2;
		font-weight: bold;
		line-height: 2;
		background: $color-primary;
		color: white;
	}

	&__avatar {
		position: relative;
		flex: 0 0 auto;
		width: $base * 4;
		height: $base * 4;
		margin: 0;
		border-radius: 50%;
		overflow: hidden;
		background-image: linear-gradient(to right, $color-gradient--start, $color-gradient--finish);

		@include picture__img() {
			object-position: center bottom;
			object-fit: contain;
		}

		+ #{$block}__details {
			margin-left: $base;
		}
	}

	&__details {
		font-family: $font-primary;
		font-size: 16px;
		font-weight: 500;
		align-self: center;
		line-height: 1.25;
		max-width: 100%;
	}

	&__name,
	&__position {
		display: inline-block;
	}

	&__name {
		white-space: nowrap;
	}

	&__position {
		opacity: .5;
	}

	&--compact {
		text-align: center;
		flex-direction: column;

		#{$block} {
			&__avatar {
				margin: auto;
			}

			&__details {
				margin-left: 0;
				margin-top: $base * 0.5;
			}
		}
	}

	&--contrast {
		color: $color-text--contrast;
	}

	&--big {
		#{$block} {
			&__details {
				font-size: $base;
				font-weight: 600;
			}

			&__position {
				margin-top: $base * 0.25;
			}

			&__avatar {
				width: $base * 6;
				height: $base * 6;
			}
		}
	}

	@include from-breakpoint("sm") {
		&--compact-sm {
			text-align: center;
			flex-direction: column;

			#{$block} {
				&__avatar {
					margin: auto;

					+ #{$block}__details {
						margin-left: 0;
						margin-top: $base * 0.5;
					}
				}
			}
		}
	}
}
