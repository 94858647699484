@import '../common/colors';
@import '../common/fonts';

.footer {
	padding: 60px 20px;
	background: $color-black;
	color: $color-light;

	@include from-breakpoint('md') {
		padding: 80px 60px;
	}

	&__container {
		@include from-breakpoint('lg') {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			gap: 20px;
		}

		@include from-breakpoint('xl') {
			gap: 140px 100px;
		}
	}

	form {
		display: flex;
		flex-direction: column;
		justify-content: stretch;
		width: 100%;

		@include from-breakpoint('xl') {
			max-width: unset;
		}
	}

	&__top {
		margin-bottom: 60px;

		@include from-breakpoint('lg') {
			display: flex;
			flex-direction: column;
			width: 47%;
			margin-bottom: 120px;
		}

		@include from-breakpoint('xl') {
			width: 50%;
			margin-bottom: 0;
		}
	}

	&__title {
		@include h2;

		margin: 0;
		margin-bottom: 40px;
		max-width: 350px;
		text-transform: uppercase;

		@include from-breakpoint('lg') {
			max-width: 100%;
		}
	}

	&__rating {
		margin: 0 0 40px;
		padding: 0;
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
		list-style: none;

		@include from-breakpoint('xl') {
			flex-wrap: nowrap;
		}
	}

	&__rating-item {
		@include body-2;

		width: 45%;
		color: rgba($color-light, 0.8);

		&:last-child {
			width: 60%;
		}

		@include from-breakpoint('xl') {
			width: 29%;

			&:last-child {
				width: 29%;
			}
		}
	}

	&__rating-title {
		display: block;
		margin: 0 0 5px;
		font-size: $h4-font-size--xs;
		font-weight: 600;
		color: $color-light;

		@include from-breakpoint('xl') {
			font-size: $h4-font-size--lg;
		}
	}

	&__contacts-wrapper {
		display: flex;
		flex-direction: column;
		gap: 16px;

		@include from-breakpoint('md') {
			flex-direction: row;
		}

		@include from-breakpoint('lg') {
			flex-direction: column;
			margin-top: auto;
		}

		@include from-breakpoint('xl') {
			flex-direction: row;
		}
	}

	&__contact-link {
		@include from-breakpoint('md') {
			width: 46%;
		}

		@include from-breakpoint('lg') {
			width: 100%;
		}

		@include from-breakpoint('xl') {
			width: 47%;
		}

		&:hover,
		&:focus-visible,
		&:active {
			color: $color-light;
			background-color: rgba($color-gray-dark, 0.7);
		}
	}

	&__form {
		margin-bottom: 60px;
		padding-bottom: 60px;
		width: 100%;
		border-bottom: 1px solid $color-gray-dark;

		@include from-breakpoint('lg') {
			margin-bottom: 120px;
			padding: 0;
			width: 40%;
			display: flex;
			flex-direction: column;
			justify-content: stretch;
			align-items: flex-end;
		}

		@include from-breakpoint('xl') {
			align-items: stretch;
			margin-bottom: 0;
		}
	}

	&__contact-link {
		flex-grow: 1;
		display: flex;
		gap: 8px;
		align-items: center;
		justify-content: center;
		height: 59px;
		color: $color-light;
		background-color: $color-gray-dark;
		border: 1px solid rgba($color-black, 0.15);
		text-decoration: none;

		&:first-of-type svg {
			width: 21px;
			height: 18px;
		}

		&:last-of-type svg {
			width: 21px;
			height: 17px;
		}
	}

	&__social {
		margin-bottom: 40px;
		display: flex;
		gap: 16px;

		@include from-breakpoint('md') {
			width: 100%;
		}

		@include from-breakpoint('xl') {
			width: 50%;
			margin-bottom: 0;
		}
	}

	&__social-icon {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-grow: 1;
		color: $color-text--muted;
		text-decoration: none;
		background-color: $color-gray-dark;

		svg {
			width: 24px;
			height: 22px;
		}

		&:hover,
		&:focus-visible,
		&:active {
			color: $color-text--muted;
			background-color: rgba($color-gray-dark, 0.8);
		}
	}

	&__social-text {
		@include hidden-accessible;
	}

	&__contact-info {
		@include body-2;

		margin-bottom: $base;
		color: $color-text--muted-2;
		text-align: center;

		& > span {
			display: inline-block;
			margin-right: $base * 0.5;
		}

		@include from-breakpoint('md') {
			text-align: left;
		}
	}

	&__privacy {
		@include body-2;

		display: block;
		margin: 0 auto;
		color: $color-text--muted-2;
		text-decoration: none;
		text-align: center;

		@include from-breakpoint('xl') {
			text-align: left;
		}
	}

	&__partner-logo {
		display: block;
		margin: 40px auto 0;
		text-align: center;

		@include from-breakpoint('md') {
			margin: 0;
		}
	}

	&__bottom {
		position: relative;
		width: 100%;

		@include from-breakpoint('md') {
			display: flex;
			flex-wrap: wrap;
			gap: 40px;
			justify-content: space-between;
			align-items: flex-end;
			width: unset;
		}

		@include from-breakpoint('lg') {
			width: 100%;

			&::before {
				content: '';
				position: absolute;
				top: -70px;
				left: 0;
				right: 0;
				height: 1px;
				width: 100%;
				background-color: $color-gray-dark;
			}
		}

		@include from-breakpoint('xl') {
			flex-wrap: nowrap;
			gap: 100px;
		}
	}

	&__copyright-wrapper {
		@include from-breakpoint('md') {
			display: flex;
			gap: 20px;
			justify-content: space-between;
			align-items: flex-end;
			width: 100%;
		}

		@include from-breakpoint('xl') {
			width: 40%;
		}
	}
}
