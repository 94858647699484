.ratings {
	$this: &;
	margin: 80px auto 160px;

	@include from-breakpoint('lg') {
		margin: 20px auto 110px;
	}

	&__list {
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: 35px;
		list-style: none;
		flex-wrap: wrap;
		padding: 0 10px;

		@include from-breakpoint('md') {
			flex-direction: row;
			gap: 58px;
		}

		@include from-breakpoint('lg') {
			gap: 0;
			margin: 0;
			padding: 0;
		}
	}

	&__item {
		position: relative;

		@include from-breakpoint('md') {
			&:nth-child(1),
			&:nth-child(2),
			&:nth-child(3) {
				flex-basis: 210px;
				order: inherit;
			}
		}

		@include from-breakpoint('lg') {
			flex-basis: unset;
		}
	}

	&__link {
		position: relative;
		text-align: center;
		text-decoration: none;
		color: inherit;
		display: block;
		transition: transform 0.4s ease;

		@include from-breakpoint('lg') {
			padding: 100px 30px;
			transform-style: preserve-3d;
			perspective: 25px;
			will-change: transform;
		}

		p {
			@include body-2;

			max-width: 276px;
			margin: 5px auto 0;
			color: #232566;
			text-align: center;

			@include from-breakpoint('md') {
				max-width: 210px;
			}
		}

		&:hover {
			#{$this}__header {
				color: $color-primary;
			}
		}
	}

	&__icon {
		z-index: 5;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 43px;
		color: $color-brand-primary-new;
		transition: transform $transition-default;

		@include from-breakpoint('md') {
			top: 2px;
		}

		@include from-breakpoint('lg') {
			top: 85px;
			height: 50px;
			transform: translateZ(-25px);
		}
	}

	&__header {
		display: flex;
		gap: 12px;
		align-items: center;
		justify-content: center;
		margin: 7px 0 8px;
		white-space: nowrap;
		transition: color 0.3s;

		@include from-breakpoint('md') {
			margin-top: 0;
		}
	}

	&__title {
		@include h2;

		position: relative;
		z-index: 20;
		margin: 0;
		transition: transform $transition-default;

		@include from-breakpoint('md') {
			font-size: $h2-font-size--xs;
		}

		@include from-breakpoint('lg') {
			font-size: $h2-font-size--md;
			transform: translateZ(-5px);
		}
	}

	&__chevron {
		position: relative;
		z-index: 10;
		flex-shrink: 0;
		width: 20px;
		height: 34px;
		transition: transform $transition-default;

		@include from-breakpoint('lg') {
			transform: translateZ(-10px);
		}
	}
}
