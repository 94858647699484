.panel {
	position: fixed;
	overflow: auto;
	top: 0;
	bottom: 0;
	left: 100%;
	width: 100%;
	z-index: 99;
	padding-top: 90px;
	padding-bottom: 40px;
	transition: 0.3s ease transform;
	display: flex;
	flex-direction: column;
	background: $color-background;

	&__menu {
		padding: 0;
		width: 100%;
		margin-bottom: auto;
		overflow: auto;
		position: relative;
		flex: 1 0 auto;
	}

	&__list {
		padding: 0;
		margin: 0;
		list-style: none;
		flex: 1 0 auto;
	}

	&__link {
		display: block;
		position: relative;
		font-size: $base;
		font-weight: 600;
		text-transform: uppercase;
		text-decoration: none;
		color: $color-text;
		padding: $base;
		transition: 0.3s ease background;
		overflow: hidden;

		&--outer {
			display: flex;
			align-items: center;
			svg {
				margin: -2px 0 0 12px;
			}
		}

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			left: -5px;
			bottom: 0;
			width: 5px;
			background: $color-brand-primary;
			transition: 0.3s ease transform;
		}

		&:hover,
		&:focus,
		&--active {
			background: $color-gray--l;

			&::before {
				transform: translateX(5px);
			}
		}

		&--contrast {
			color: $color-contrast;
		}
	}

	&__button {
		margin: 0 $base * 1.5 $base;
	}

	&__contacts-wrapper {
		display: flex;
		gap: 16px;
		margin: 0 $base * 1.5 20px;
	}

	&__contact-link {
		flex-grow: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 59px;
		color: $color-black;
		border: 1px solid rgba($color-black, 0.15);

		&:first-of-type svg {
			width: 21px;
			height: 18px;
		}

		&:last-of-type svg {
			width: 21px;
			height: 17px;
		}
	}

	&__contact {
		font-style: normal;
		margin: 0 $base * 1.5 $base;
		font-size: 14px;

		svg {
			width: 17px;
			height: 24px;
		}
	}

	&--active {
		transform: translateX(-100%);
	}

	@media (max-height: 480px) {
		&__menu {
			display: flex;
			flex-wrap: wrap;
		}
	}

	@include from-breakpoint('lg') {
		&--active {
			transform: translateX(0);
		}
	}
}

.menu-burger {
	position: fixed;
	top: 0;
	right: 0;
	z-index: 101;

	&__text {
		@include hidden-accessible;
	}

	@include from-breakpoint('lg') {
		display: none;
	}
}
