@import './shared.scss';

.team-member {
	$team-member: &;
	text-decoration: none;
	color: inherit;

	@include from-breakpoint('lg') {
		&__overlay-icon {
			width: 55px;
			height: 38px;
			color: white;
			fill: white;
		}

		&--clickable:hover #{$team-member}__overlay {
			display: flex;
		}
	}

	&__preview {
		height: 0;
		padding-bottom: 107.58%;
		position: relative;
		background: #ccc;
		overflow: hidden;
	}

	&__name {
		font: 600 22px/1.33 $font-secondary;
		letter-spacing: 0.02em;
		margin: 24px 18px 4px;
	}

	&__position {
		margin: 5px 18px 32px;
		font-size: 16px;
		color: $color-gray--d;
	}

	&__overlay {
		display: none;

		@include from-breakpoint('lg') {
			position: absolute;
			top: 0;
			bottom: 0;
			right: 0;
			left: 0;
			align-items: center;
			justify-content: center;
			background: rgba(#e82250, 0.86);
			pointer-events: none;
		}
	}
}
