@mixin hidden-accessible {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}

@mixin background-helper {
	// fix chrome background-size: cover; - 1px blank line appears
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	position: absolute;
	top: -1px;
	bottom: -1px;
	left: -1px;
	right: -1px;
}

@mixin absolute-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

@function px2unit($size, $base: 16px) {
	@return $size / $base;
}

@function px2em($size, $base: 16px) {
	@return $size / $base * 1em;
}

@mixin respect-safe-area($property, $side, $value) {
	// <meta name='viewport' content='viewport-fit=cover'>
	// @include respect-safe-area("padding", "left", 10px);
	#{$property}-#{$side}: $value;
	#{$property}-#{$side}: calc(#{$value} + env(safe-area-inset-#{$side}));
}

@mixin ie-specific {
	@media (-ms-high-contrast: none) {
		@content;
	}
}

@mixin reset-list {
	margin: 0;
	padding: 0;
	list-style: none;
}
