@import './shared.scss';

.division {
	$division: &;

	padding: 48px 20% 22px 24px;
	position: relative;
	transition-property: transform, box-shadow;
	transition-duration: 0.2s;
	transition-timing-function: ease;
	margin-bottom: 32px;
	min-height: 317px;
	@include from-breakpoint('md') {
		max-height: 317px;
	}
	@include from-breakpoint('xxl') {
		max-height: none;
	}

	&:hover,
	&:focus {
		transform: translate(0, -8px);
		box-shadow: 0 $base * 0.5 $base rgba(0, 0, 0, 0.16);

		#{$division}__arrow {
			opacity: 1;
		}
	}

	&__preview-holder {
		max-width: 72px;
		margin-left: 5px;
		margin-bottom: 30px;
	}
	&__preview {
		padding-bottom: 94 / 72 * 100%;
		position: relative;
	}
	&__title {
		margin-bottom: 15px;
		font-family: $font-secondary;
		font-size: 16px;
	}
	&__description {
		color: $color-gray--d;
		font-size: 12px;
		line-height: 1.5;
		font-family: $font-primary;
	}
	&__arrow {
		width: 24px;
		position: absolute;
		bottom: 30px;
		right: 24px;
		height: 17px;
		opacity: 0;
		transition: opacity 0.6s ease-in-out;
	}
}
