.articles {
	$this: &;
	margin-bottom: 160px;

	&__inner {
		margin-top: -20px;
		overflow: hidden;
	}

	&__block-title {
		width: 100%;
		font-size: 20px;
		font-weight: 600;
		line-height: 1.2;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		padding-bottom: 8px;
		border-bottom: 1px solid $color-gray--dd;
		margin: 0;
	}

	&__block {
		display: flex;
		gap: 24px;
		margin-bottom: 40px;
		flex-wrap: wrap;

		@include before-breakpoint('md') {
			flex-direction: column;
			margin-bottom: 30px;
		}

		&--1-in-row {
			@include from-breakpoint('md') {
				#{$this}__item {
					flex-basis: 100%;
					flex-grow: 0;
					flex-shrink: 0;
				}
			}
		}

		&--2-in-row {
			@include from-breakpoint('md') {
				#{$this}__item {
					flex-basis: calc((100% - 24px) / 2);
					width: calc((100% - 24px) / 2);
					flex-grow: 0;
					flex-shrink: 0;
				}
			}
		}

		&--3-in-row {
			#{$this}__item:nth-child(2) {
				color: $color-light;
				background-color: $color-black;
			}

			@include from-breakpoint('md') {
				#{$this}__item {
					flex-basis: calc((100% - 24px) / 2);
					width: calc((100% - 24px) / 2);
					flex-grow: 0;
					flex-shrink: 0;

					&:nth-child(4n) {
						flex-basis: 100%;
					}
				}
			}

			@include from-breakpoint('xl') {
				#{$this}__item {
					padding: 30px;
					flex-basis: unset;
					width: calc((100% - 24px * 2) / 3);

					&:nth-child(4n) {
						flex-basis: unset;
						width: calc((100% - 24px * 2) / 3);
					}
				}
			}
		}
	}

	&__icon {
		display: none;
	}

	&__item {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: flex-start;
		padding: 24px;
		color: inherit;
		text-decoration: none;

		@include from-breakpoint('md') {
			flex-basis: calc((100% - 24px) / 2);
			flex-shrink: 0;
			flex-grow: 0;
		}

		#{$this}__block:nth-child(1) &:nth-child(3) #{$this}__bg {
			background-color: $color-brand-primary-new;
		}

		#{$this}__block:nth-child(2) &:nth-child(2) {
			color: white;

			#{$this}__bg {
				background-color: black;
			}
		}

		#{$this}__block:nth-child(3) &:nth-child(3) #{$this}__bg {
			background-color: $color-brand-primary-new;
		}
	}

	&__bg {
		position: absolute;
		left: 0;
		top: 0;
		z-index: -1;
		width: 100%;
		height: 100%;
		background-color: $color-gray--lll;
	}

	&__text {
		position: relative;
		margin-bottom: 80px;
		z-index: 1;
	}

	&__title {
		position: relative;
		font-size: 24px;
		font-weight: 600;
		line-height: 1;
		transition: color 0.3s;
		margin: 0;
		z-index: 1;
		word-wrap: break-word;

		#{$this}__item:hover & {
			color: $color-primary;
		}

		@include from-breakpoint('lg') {
			font-size: 32px;
		}
	}

	&__text {
		display: none;
		font-size: 16px;
		font-weight: 400;
		line-height: 1.6;

		@include from-breakpoint('md') {
			display: block;
		}
	}

	&__author {
		margin-top: 60px;
		display: flex;
		gap: 8px;
		align-items: center;
		justify-content: space-between;

		@include from-breakpoint('md') {
			margin-top: auto;
		}
	}

	&__author-photo {
		position: relative;
		overflow: hidden;
		width: 48px;
		height: 48px;
		flex-basis: 48px;
		flex-grow: 0;
		flex-shrink: 0;
		border-radius: 24px;
	}

	&__author-name {
		font-size: 16px;
		line-height: 1.6;
	}

	&__chevron {
		flex-shrink: 0;
		width: 35px;
		height: 45px;
	}
}
