@import '../common/_shared';

.back-to-top {
	$block: &;

	position: sticky;
	bottom: 152px;
	max-width: $grid-limit;
	margin: auto;
	display: flex;
	z-index: 1;

	&__btn {
		$size: 48px;

		margin: auto 16px (-136px) auto;
		width: $size;
		height: $size;
		background: $color-background;
		padding: 13px;
		border: 1px solid $color-text;
		border-radius: 4px;
		z-index: 1;
		cursor: pointer;

		&:focus,
		&:hover {
			background: $color-brand-primary;
			border-color: $color-brand-primary;
		}

		&:active {
			background: $color-brand-primary;
			border-color: $color-text;
		}

		@include from-breakpoint('md') {
			margin-right: 0;
			margin-bottom: -82px;
		}

		@include from-breakpoint('lg') {
			margin-right: -$size;
			margin-bottom: -102px;
		}
		@include from-breakpoint('xl') {
			margin-right: -$size - 50px;
		}
	}

	&__icon {
		width: 20px;
		height: 20px;
	}

	@include from-breakpoint('md') {
		bottom: 98px;
	}

	@include from-breakpoint('lg') {
		bottom: 152px;
	}

	&--wide {
		transform: translateY(152px);
		transition-timing-function: ease;
		transition-duration: 0.2s;

		@include from-breakpoint('lg') {
			max-width: $grid-limit--wide;
		}
  }
	&--visible {
		transform: translateY(0);
	}
}
