@import '../common/_shared';

.header {
	display: flex;
	align-items: center;
	position: relative;
	background-color: $color-light;

	.is-homepage & {
		background-color: $color-brand-primary-new;

		&:has(.header__logo-bg--active) {
			background-color: transparent;
		}
	}

	@include from-breakpoint('lg') {
		padding: 0 $base * 1.5;
	}

	&__logo {
		display: block;
		flex: 0 0 auto;
		position: relative;
		line-height: 0;
		background: transparent;

		@include from-breakpoint('md') {
			height: $custom__header-height--md;

			svg {
				width: 110px;
				height: 81px;
			}
		}
	}

	&__logo-text {
		@include hidden-accessible;
	}

	&__logo-bg {
		background: $color-brand-primary-new;
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		width: 110px;
		height: $custom__header-height;

		@include from-breakpoint('md') {
			height: $custom__header-height--md;
		}

		@include from-breakpoint('lg') {
			margin-left: 20px;
		}
	}

	&__menu {
		display: none;
		height: 100%;
		margin-left: $base * 0.5;
		margin-right: auto;

		@include from-breakpoint('lg') {
			display: flex;
		}
	}

	&__contact {
		display: none;

		@include from-breakpoint('xxl') {
			display: block;
		}
	}

	&__link {
		display: block;
		padding: $base * 2 $base;
		font: 600 16px / 1 $font-primary;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		text-decoration: none;
		color: $color-text;
		position: relative;
		transition: color 0.3s;

		&--outer {
			display: flex;
			align-items: center;
			transition: color 0.3s;

			svg {
				margin: -2px 0 0 -6px;
			}

			&:hover {
				color: $color-contrast;
			}
		}

		&::before {
			content: '';
			position: absolute;
			display: block;
			left: 50%;
			right: 50%;
			height: 2px;
			bottom: 24px;
			background: $color-quaternary;
			transition-property: left, right;
			transition-duration: 0.1s;
			transition-timing-function: cubic-bezier(0, 0.18, 0.44, 1);
		}

		&--active,
		&:hover {
			&::before {
				content: '';
				left: $base * 1.5;
				right: $base * 1.5;
			}
		}

		&--contrast {
			color: $color-contrast;
		}

		&:hover {
			color: $color-contrast;
		}
	}

	&__search {
		line-height: 0;
	}

	&__search-icon {
		width: $base;
		height: $base;
		padding: 0;
	}

	&__page-name {
		position: relative;
		margin: 0 $base;
		text-transform: uppercase;
		font-size: 14px;
		font-weight: 500;
		text-decoration: none;
		padding: 13px;
		color: inherit;

		&:hover {
			text-decoration: underline;
		}

		@include from-breakpoint('md') {
			display: none;
		}
	}

	&__phone {
		margin-left: auto;
		margin-right: 106px;
		display: flex;
		align-items: center;
		line-height: 1;
		color: inherit;
		text-decoration: none;
		transition: color 0.3s;

		@include from-breakpoint('lg') {
			margin: 0 $base * 2 0;
		}

		&:hover {
			color: $color-contrast;
		}
	}

	&__phone-icon {
		display: block;
		line-height: 0;
		margin-right: $base * 0.5;
		width: 17px;
		height: 14px;
		flex: 0 0 auto;
		color: inherit;
	}

	&__phone-text {
		display: none;
		font-family: $font-primary;
		font-weight: 600;
		line-height: 1;
		font-size: 16px;
		letter-spacing: 0.02em;
		text-transform: none;
		text-decoration: inherit;
		text-transform: uppercase;
		color: inherit;

		@include from-breakpoint('xl') {
			display: block;
		}
	}
}
