@mixin dashed($font-size) {
	&.dashed::after {
		content: '';
		display: block;
		width: px2em(22px, $font-size);
		height: px2em(2px, $font-size);
		margin-top: $base * 1.5;
		background: $color-primary;
	}
}

.typography {
	$block: &;
	$font-size--large: 20px;
	$font-size: 18px;

	color: $color-text;
	font-family: $font-primary;
	font-size: $font-size;
	line-height: 1.44;
	word-wrap: break-word;
	word-break: break-word;

	@include from-breakpoint('md') {
		font-size: $font-size--large;
		line-height: 1.38;
	}

	* {
		margin: 0;
		padding: 0;
	}

	* {
		+ * {
			margin-top: 14px;
		}

		+ h1,
		+ h2 {
			margin-top: 70px;
		}

		+ h3 {
			margin-top: 42px;
		}
	}

	&__ignore-offset {
		// test feature
		* + * {
			margin-top: unset;
		}
	}

	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: $font-primary;
		font-weight: 600;
	}

	h1,
	h2,
	h3 {
		+ * {
			margin-top: 23px;
		}
	}

	h4,
	h5,
	h6 {
		+ * {
			margin-top: 8px;
		}
	}

	h1 {
		$font-size: 46px;
		$font-size: 24px;

		font-size: $font-size;
		line-height: 1.17;
	}

	h2 {
		font-size: 24px;
		line-height: 1.17;
	}

	h3 {
		font-size: 18px;
	}

	h4 {
		font-family: $font-primary;
		font-weight: 600;
	}

	.h1-big {
		@include h1;
	}

	.h2-big {
		@include h2;
	}

	.h3-big {
		@include h3;
	}

	.h4-big {
		@include h4;
	}

	.h5-big {
		@include h5;
	}

	.h6-big {
		@include h6;
	}

	hr {
		content: '';
		display: block;
		width: px2em(22px);
		height: px2em(2px);
		margin: $base * 1.5 auto $base * 1.5 0;
		border: 0;
		background: $color-primary;

		&.primary {
			background: $color-primary;
		}

		&.secondary {
			background: $color-secondary;
		}

		&.tertiary {
			background: $color-tertiary;
		}

		&.quaternary {
			background: $color-quaternary;
		}
	}

	ol,
	ul,
	dl {
		padding-left: 12px;
	}

	dl {
		text-shadow: none;

		dd {
			padding-left: 12px;

			> dl {
				padding-left: 0;
			}
		}

		dd,
		dl {
			margin-top: 0;
		}
	}

	ol,
	ul {
		li::marker {
			color: red;
		}
	}

	.ul--questions {
		padding: 0;

		li {
			padding-left: 22px + 14px;
			position: relative;

			&::before {
				content: '';
				position: absolute;
				top: 1px;
				left: 0;
				margin: 0;
				width: 22px;
				height: 22px;
				background: svg-load(
						'../svg/question-in-circle.svg',
						fill=#b2b3cb
					)
					no-repeat;
				vertical-align: bottom;
			}
		}
	}

	a {
		color: $color-primary;

		&:hover {
			text-decoration: none;
		}
	}

	img {
		display: block;
		margin-left: auto;
		margin-right: auto;
		max-width: 100%;

		* + img,
		+ * {
			margin-top: 24px;
		}

		&[style*='float'] {
			padding: 20px;
		}

		&.img--no-padding {
			padding: 0;
		}
	}

	figure {
		text-align: center;

		img {
			padding: 0;
		}

		figcaption {
			margin: 0;
			padding: 8px;
			line-height: 1.2;
			color: $color-text--muted;
		}
	}

	kbd,
	samp {
		color: $color-tertiary;
		line-height: normal;
		display: inline-block;
		border: 1px solid $color-tertiary;
		padding: px2em(3px) px2em(6px);
		border-radius: px2em(8px);
		font-weight: bold;
	}

	ins {
		text-decoration-color: $color-tertiary;
	}

	del {
		text-decoration-color: $color-primary;
	}

	var,
	dfn,
	q {
		font-weight: bold;
		color: $color-tertiary;
	}

	q {
		quotes: '« ' ' »' '“' '”';
	}

	.blockquote {
		clear: both;
		padding: $base * 2;
		@include from-breakpoint('md') {
			padding: 43px 28px 20px 56px;
		}
		color: $color-tertiary;
		font-weight: 500;
		border-left: 2px solid $color-tertiary;

		.blockquote-body {
			position: relative;
			&::before,
			&::after {
				content: '';
				position: absolute;
				opacity: 0.14;
				height: 36px;
				width: 36px;
				background-size: cover;
			}
			&::before {
				top: 0;
				left: 0;
				transform: translate(-50%, -50%);
				background: svg-load(
						'../svg/quote-left.svg',
						fill=#000,
						stroke=#fff
					)
					no-repeat;
			}

			&::after {
				bottom: 0;
				right: 0;
				transform: translate(5%, 50%);
				background: svg-load(
						'../svg/quote-right.svg',
						fill=#000,
						stroke=#fff
					)
					no-repeat;
			}
		}

		footer {
			font-style: normal;
			text-align: right;
			font-family: $font-secondary;
			font-size: 20px;
			font-weight: 500;
			color: $color-text--muted;
			position: relative;
			margin-top: 30px;

			&::before {
				content: '';
				display: block;
				height: 1px;
				width: 22px;
				background: $color-quaternary;
				margin: 0 0 10px auto;
			}
		}

		.author {
			display: flex;
			justify-content: flex-end;
			align-items: center;

			&__avatar {
				flex: 0 0 auto;
				width: 36px;
				height: 36px;
				border-radius: 50%;
				overflow: hidden;
				position: relative;

				img {
					margin: 0;
					padding: 0;
				}
			}

			&__title {
				font-style: normal;
				margin: 0 0 0 16px;

				cite {
					font-style: normal;
				}
			}
		}
	}

	iframe {
		width: 100%;
	}

	table {
		table-layout: fixed;
		border-collapse: collapse;
		width: 100%;
		$font-size: 18px;
		font-size: $font-size;
		line-height: 1.44;

		caption {
			text-align: center;
			font-weight: bold;
			padding: 12px;
			color: $color-tertiary;
		}

		td,
		th {
			padding: 16px;
			border: 1px solid $color-tertiary;
		}

		td {
			padding-top: 13px;
			padding-bottom: 13px;
		}

		th {
			background: $color-tertiary;
			color: $color-light;
			//border-color: $color-light;
		}

		tr:nth-child(even) {
			background: $color-gray;
		}
	}

	&--light {
		#{$block},
		& {
			// for umb-grid
			color: $color-light;
		}
	}

	&--accessories-light {
		#{$block},
		& {
			h1,
			h2,
			h3 {
				&::after {
					background: $color-light;
				}
			}

			ol,
			ul {
				> li::before {
					color: $color-light;
				}
			}
		}
	}

	&--accessories-primary {
		#{$block},
		& {
			h1,
			h2,
			h3 {
				&::after {
					background: $color-primary;
				}
			}

			ol,
			ul {
				> li::before {
					color: $color-primary;
				}
			}
		}
	}

	&--accessories-secondary {
		#{$block},
		& {
			h1,
			h2,
			h3 {
				&::after {
					background: $color-secondary;
				}
			}

			ol,
			ul {
				> li::before {
					color: $color-secondary;
				}
			}
		}
	}

	&--accessories-tertiary {
		#{$block},
		& {
			h1,
			h2,
			h3 {
				&::after {
					background: $color-tertiary;
				}
			}

			ol,
			ul {
				> li::before {
					color: $color-tertiary;
				}
			}
		}
	}

	&--accessories-quaternary {
		#{$block},
		& {
			h1,
			h2,
			h3 {
				&::after {
					background: $color-quaternary;
				}
			}

			ol,
			ul {
				> li::before {
					color: $color-quaternary;
				}
			}
		}
	}

	&--bigger {
		#{$block},
		& {
			font-size: 20px;
		}
	}

	&--service-page {
		ol {
			padding-left: 0;
		}

		ol > li {
			padding: 20px 20px 20px 40px;
			border-left: 2px solid $color-brand-primary;
		}

		ol > li:before {
			color: black;
		}
	}

	@include from-breakpoint('md') {
		table {
			font-size: 20px;
			line-height: 1.38;
		}

		* {
			+ h1,
			+ h2 {
				margin-top: 50px;
			}

			+ h3 {
				margin-top: 30px;
			}
		}

		h1 {
			font-size: 46px;
		}

		h2 {
			font-size: 32px;
		}

		h3 {
			font-size: 24px;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			+ * {
				margin-top: 30px;
			}
		}
	}
	.text-highlight {
		border-left: 2px solid $color-tertiary;
		color: $color-tertiary;
		font-weight: 500;
		padding: $base * 2;
		@include from-breakpoint('md') {
			padding: 28px;
			padding-left: 56px;
		}
	}
}

code[class*='language-'],
pre[class*='language-'] {
	font-size: 16px;
	tab-size: 2;
}

pre[class*='language-'] {
	border-radius: 5px;
}

code[class*='language-'] {
	white-space: pre-wrap;
	word-break: break-word;
}

.is-small-hidden {
	@include before-breakpoint('sm') {
		display: none;
	}
}
