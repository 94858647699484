.contacts {
	$block: &;

	padding-top: ($custom__header-height + $base);
	margin-bottom: 60px;

	@include from-breakpoint('md') {
		padding-top: $custom__header-height--md + $base;
	}
	@include from-breakpoint('lg') {
		padding-top: $custom__header-height--md + 50px;
	}

	&__title {
		@include h1;
	}

	&__row {
		& + & {
			margin-top: $base * 2;
			@include from-breakpoint('md') {
				margin-top: $base * 3;
			}
			@include from-breakpoint('lg') {
				margin-top: $base * 4;
			}
		}
	}

	&__limiter {
		padding: 0 $base;
		max-width: $custom__limiter;
		margin: 0 auto;
	}
}
