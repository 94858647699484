.homepage {
	&__title {
		@include h2;

		position: relative;
		z-index: 1;
		margin: 0 0 60px;
		text-align: center;
		text-transform: uppercase;

		@include from-breakpoint('lg') {
			margin-bottom: 80px;
		}
	}
}
