@import '../../common/_shared';

$umb-grid__block-vertical-padding: 1.5 * $base;
$umb-grid__block-vertical-padding--md: 50px;

.umb-grid {
	$block: &;
	$gap--mobile: 16px;
	$gap: 30px;

	padding: 0.07px 0;

	&__block {
		overflow: hidden;
		padding: $umb-grid__block-vertical-padding $base;

		@include from-breakpoint('md') {
			padding: $umb-grid__block-vertical-padding--md 15px;
		}

		.service-page & {
			padding: 45px 16px 20px;
		}
	}

	&__limiter {
		margin: auto;
    max-width: $grid-limit;
    
    .wide & {
      max-width: $grid-limit--wide;
    }

    &--landing {
      max-width: $grid-limit--landing;
    }
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		flex: 1 0 auto;
		margin: -$gap--mobile/2;

		& + & {
			margin-top: 50px;
		}

		@include from-breakpoint('md') {
			margin: -$gap/2;
		}
	}

	&__col {
		padding: $gap--mobile/2;
		width: 100%;

		@include from-breakpoint('md') {
			padding: $gap/2;

			&--1 {
				width: 1 / 12 * 100%;
			}
			&--2 {
				width: 2 / 12 * 100%;
			}
			&--3 {
				width: 3 / 12 * 100%;
			}
			&--4 {
				width: 4 / 12 * 100%;
			}
			&--5 {
				width: 5 / 12 * 100%;
			}
			&--6 {
				width: 6 / 12 * 100%;
			}
			&--7 {
				width: 7 / 12 * 100%;
			}
			&--8 {
				width: 8 / 12 * 100%;
			}
			&--9 {
				width: 9 / 12 * 100%;
			}
			&--10 {
				width: 10 / 12 * 100%;
			}
			&--11 {
				width: 11 / 12 * 100%;
			}
			&--12 {
				width: 100%;
			}
		}

		.service-page & {
			@include from-breakpoint('xl') {
				max-width: $service-page-umb-grid-max-width;
			}
		}
	}

	&--ai {
		&-c {
			#{$block}__row {
				align-items: center;
			}
		}
	}

	&--jc {
		&-c {
			#{$block}__row {
				justify-content: center;
			}
		}
		&-sb {
			#{$block}__row {
				justify-content: space-between;
			}
		}
	}
}
