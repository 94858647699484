.contact {
	$block: &;

	&__title {
		@include h1;
		color: #242021;
	}

	&__content {
		margin: 24px 0;
		@include from-breakpoint('md') {
			margin-top: $base 0;
		}
	}

	&__typography {
		> * + * {
			margin-top: 20px;
		}

		p {
			font-family: $font-primary;
			font-size: 14px;
			@include from-breakpoint('md') {
				font-size: 18px;
			}
			line-height: 1.22;
			color: #878787;
			margin: 0;
		}
	}

	&__phone {
		display: block;
		font-family: $font-primary;
		font-size: 16px;
		@include from-breakpoint('md') {
			font-size: 30px;
		}
		font-weight: bold;
		color: #ca132a;
		text-decoration: none;
	}

	&__list {
		padding: 0.07px 0;
	}

	&__list-inner {
		margin: -8px -16px;
		@include from-breakpoint('md') {
			margin: -8px -24px;
		}
		padding: 0;
		list-style: none;
		display: flex;
		flex-flow: row wrap;
	}

	&__item {
		flex: 1 0 100%;
		padding: 8px 16px;
		@include from-breakpoint('md') {
			padding: 8px 24px;
		}
	}
}
