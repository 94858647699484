$breakpoints: (
	xs: 0,
	sm: 576px,
	md: 768px,
	lg: 1024px,
	xl: 1200px,
	xxl: 1440px,
);

@mixin from-breakpoint($breakpoint) {
	$size: map-get($breakpoints, $breakpoint);
	@if ($size == 0) {
		@content;
	} @else {
		@media screen and (min-width: $size) {
			@content;
		}
	}
}

@mixin before-breakpoint($breakpoint) {
	$size: map-get($breakpoints, $breakpoint);
	@media screen and (max-width: calc($size - 1px)) {
		@content;
	}
}

@mixin show-from-breakpoint($breakpoint) {
	@include before-breakpoint($breakpoint) {
		display: none !important;
	}
}

@mixin hide-from-breakpoint($breakpoint) {
	@include from-breakpoint($breakpoint) {
		display: none !important;
	}
}
