.case-page {
	.umb-grid__block {
		padding: 15px;

		@include from-breakpoint('md') {
			padding: 25px 15px;
		}
	}

	.about-case {
		padding: 20px 15px;

		@include from-breakpoint('md') {
			padding: 24px 15px 30px;
		}

		& + .umb-grid__block {
			padding: 0 15px 15px;

			@include from-breakpoint('md') {
				padding: 0 15px 25px;
			}
		}
	}

	.umb-macro-image {
		margin: 0;

		figcaption {
			color: $color-text--muted;
			line-height: 1.2;
			padding: 8px;
		}

		.umb-grid__col--12 figcaption {
			width: 66.6667%;
		}
	}

	.typography dl,
	.typography ol,
	.typography ul {
		padding-left: 0;
	}

	.typography li {
		margin-left: 28px;
	}

	.typography ol>li:before,
	.typography ul>li:before {
		margin-left: -19px;
		margin-right: 0;
		text-align: left;
		width: 19px;
	}

	.typography ol>li:before {
		width: 28px;
		margin-left: -28px;
	}

	.typography p>img {
		margin-left: 0;
		margin-top: 30px;
	}

	.legend .typography {
		background-color: $color-background--muted;
		font-size: 16px;
		line-height: 26px;
		padding: 20px;

		* + a,
		* + br {
			margin-top: 0;
		}

		&:after {
			content: "";
			display: block;
			width: 100%;
			clear: both;
		}

		p>img {
			margin-top: 0;
			margin-right: 12px;
		}

		img + *:not(img) {
			margin-top: 0;
		}
	}

	.legend-flex {
		.typography p {
			display: flex;
			align-items: start;
		}
	}

	.intro-image-large {
		text-align: center;
		margin-bottom: 30px;

		@include from-breakpoint('md') {
			display: none;
			text-align: left;
			margin-bottom: 0;

			.picture {
				width: 345px;
			}

			&.umb-macro-image-with-offset {
				width: 345px;
			}
		}
		@include from-breakpoint('xl') {
			.picture {
				width: 845px;
			}

			&.umb-macro-image-with-offset {
				width: 845px;
				padding-left: 30px;
			}
		}

		.button {
			margin-top: 30px;
		}
	}

	.intro-image-desktop {
		.intro-image-large {
			display: none;
			@include from-breakpoint('md') {
				display: block;
			}
		}
	}

	.carousel__limiter {
		max-width: 100%;
	}

	.carousel__navigation {
		display: none;
		@include from-breakpoint('md') {
			display: block;
		}
	}

	.carousel__navigation-icon {
		@include from-breakpoint('md') {
			width: 72px;
			height: 72px;
			margin: -36px;
		}
	}

	.swiper-container {
		padding-bottom: 23px;
		@include from-breakpoint('md') {
			padding-bottom: 30px;
		}
	}

	.swiper-pagination {
		bottom: 0;
		line-height: 8px;
	}

	.swiper-pagination-bullet {
		background-color: transparent;
		border: 1px solid $color-dark;
		opacity: 1;
	}

	.swiper-pagination-bullet-active {
		background-color: $color-brand-primary;
		border: none;
	}

	.achievements__item {
		margin-right: auto;
		margin-left: auto;
		max-width: 177px;
		color: $color-dark;
	}

	.achievements__subtitle {
		text-transform: none;
	}

	.achievements__summary {
		line-height: 18px;
	}
}

.umb-macro-highlights {
	background-color: $color-background--muted;
	padding: 20px;
	border-left: 2px solid $color-quaternary;
	color: $color-dark;
	font-size: 16px;
	font-style: italic;
	font-weight: 400;
	line-height: 26px;
	margin: 30px 0;

	&:first-child {
		margin-top: 0;
	}

	&:last-child {
		margin-bottom: 0;
	}

	@include from-breakpoint('md') {
		font-size: 18px;
		line-height: 27px;
	}
}

.case-header {
	.tags {
		margin-bottom: 0;

		@include from-breakpoint('lg') {
			margin-bottom: 15px;
		}
	}

	img {
		margin-left: 0;
	}

	h1 {
		margin-top: 22px;

		@include from-breakpoint('lg') {
			margin-top: 30px;
		}
	}
}

.case-logo {
	margin-top: 20px;
	height: 52px;

	@include from-breakpoint('md') {
		height: 70px;
	}

	img {
		object-position: left;
	}
}

.timeframe {
	&__item {
		@include from-breakpoint('md') {
			display: inline-block;
			margin-right: 30px;
			margin-top: 0;
		}
	}
}

.team {
	&__title {
		display: inline-block;
		border-bottom: 5px solid;
	}

	.typography {
		h3 {
			margin-top: 20px;
			&:first-child {
				margin-top: 0;
			}
		}

		h3 + * {
			margin-top: 20px;
		}

		* + * {
			margin-top: 4px;
		}

		p strong {
			display: block;
			margin-top: 20px;
		}
	}
}

