.content-page {
	$block: &;
	overflow: hidden;

	&--padded {
		padding-bottom: $base * 2;
	}

	&--respect-header {
		padding-top: $custom__header-height;
	}

	&__row {
		position: relative;

		& + & {
			margin-top: $base * 2;

			&--offset-small {
				margin-top: $base;
			}

			&--no-offset {
				margin-top: 0;
			}
		}

		&--narrow {
			position: relative;
			overflow: hidden;
			flex: 1 0 auto;
			margin: 0 auto;
			padding: 5px 35px 0;
			max-width: 960px;
			width: 100%;

			@include from-breakpoint('lg') {
				padding: 0;
			}
		}
	}

	&__content {
		margin-top: 18px;
		padding-bottom: $umb-grid__block-vertical-padding;
	}

	&__title {
		@include h1;
		@include from-breakpoint('lg') {
			font-size: $h2-font-size--lg;
		}
	}

	&__date {
		display: block;
		text-align: right;
		color: $color-gray--d;
		font-size: $base;
		line-height: 1.22;
		margin: 0.5em 0 1em;
	}

	&__back {
		margin: 0 0 20px (-$base/2);

		&--above-image {
			display: none;

			@include from-breakpoint('lg') {
				display: block;
			}
		}

		&--under-image {
			@include from-breakpoint('lg') {
				display: none;
			}
		}
	}

	&__sharing {
		padding: 3 * $base $base;
	}

	&__limiter {
		padding: 0 $base;
		max-width: $custom__limiter;
		margin: 0 auto;

		&--small {
			max-width: $custom__limiter--typography;
		}
	}

	&__cover {
		padding: 0;

		@media screen and (min-width: $custom__limiter--typography) {
			padding: 0 $base;
		}
	}

	&__related {
		background: $color-gray--ll;
		padding: 32px 0;
	}

	@include from-breakpoint('md') {
		&--respect-header {
			padding-top: $custom__header-height--md + 2 * $base;
		}

		&__content {
			padding-bottom: $umb-grid__block-vertical-padding--md;
		}
	}

	@include from-breakpoint('lg') {
		&--padded {
			padding-bottom: $base * 4;
		}

		&__row {
			& + & {
				margin-top: $base * 4;

				&--offset-small {
					margin-top: $base * 2;
				}

				&--no-offset {
					margin-top: 0;
				}
			}
		}

		&__related {
			padding: 64px 0;
		}
	}

	&--without-image {
		padding-top: $custom__header-height + $base;

		@include from-breakpoint('md') {
			padding-top: $custom__header-height--md + 2 * $base;
		}

		#{$block} {
			&__back {
				&--under-image {
					@include from-breakpoint('lg') {
						display: block;
					}
				}
			}
		}
	}
}
