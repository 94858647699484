.cookie-bar {
	z-index: 999;
	position: fixed;
	bottom: 0;
	right: 0;
	left: 0;
	background: $color-brand-primary;
	padding: 8px 16px;
	text-align: left;
	font-size: 14px;
	color: $color-text;
	transform: translateY(100%);
	transition: .3s all linear;

	@include from-breakpoint("md") {
		padding: 16px 36px;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&--visible {
		transform: translateY(0%);
	}

	.button {
		display: block;
		margin-top: 8px;
		@include from-breakpoint("md") {
			margin-top: 0;
		}
	}
}