@import '../common/_shared.scss';

.link {
	display: inline-flex;
	text-decoration: none;
	align-items: center;
	line-height: 1;
	color: inherit;

	&__icon {
		display: block;
		line-height: 0;
		margin-right: $base;
		width: $base;
		height: $base;
		flex: 0 0 auto;
		color: inherit;
	}

	&__text {
		font-family: $font-primary;
		font-weight: 500;
		line-height: 1;
		font-size: $base;
		text-transform: none;
		text-decoration: inherit;
		color: inherit;

		& + & {
			margin-left: $base * 0.5;
		}
	}

	&--padded {
		padding: $base/2;
	}

	&:hover,
	&:focus-visible {
		color: $color-contrast;
	}

	&:active {
		color: $color-brand-primary-new;
	}
}
