.breadcrumbs-wrapper {
	position: relative;
	z-index: 1;
	padding-top: 85px;
	padding-bottom: 25px;

	@include from-breakpoint('md') {
		padding-top: 125px;
		padding-bottom: 0;
	}

	&--services {
		position: relative;
		top: 25px;
		padding: 0 20px;

		@include from-breakpoint('md') {
			top: 85px;
			padding: 0 40px;
		}

		@include from-breakpoint('lg') {
			padding: 0 60px;
		}
	}

	&--service-page {
		position: relative;
		padding: 0;
		top: -120px;

		@include from-breakpoint('md') {
			top: -185px;
		}
	}

	&--content-page {
		padding-top: 45px;

		@include from-breakpoint('md') {
			padding-top: 15px;
			padding-bottom: 25px;
		}

		@include from-breakpoint('lg') {
			padding-bottom: 35px;
		}
	}

	&--articles,
	&--contacts {
		padding-top: 25px;

		@include from-breakpoint('md') {
			padding-bottom: 25px;
		}

		@include from-breakpoint('lg') {
			padding-top: 0;
		}
	}

	&--white {
		padding: 0;

		.breadcrumbs__item {
			color: rgba($color-light, 0.9);

			.link {
				color: rgba($color-light, 0.9);
				transition: color $transition-default;
			}

			&--active {
				color: $color-light;
			}
		}

		.breadcrumbs__slash {
			color: $color-light;
		}
	}
}

.breadcrumbs {
	&__item {
		color: rgba($color-black, 0.3);

		&--active {
			color: $color-black;
		}

		.link {
			color: rgba($color-black, 0.3);
			transition: color $transition-default;

			&:hover,
			&:focus-visible {
				color: $color-contrast;
			}

			&:active {
				color: $color-brand-primary-new;
			}
		}
	}

	&__slash {
		color: rgba($color-black, 0.3);
	}
}
