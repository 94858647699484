.icon {
	pointer-events: none;
	width: 100%;
	height: 100%;
	display: block;
	fill: currentColor;
}

.svg-path {
	clip: rect(0 0 0 0);
	clip-path: inset(50%);
	height: 1px;
	overflow: hidden;
	position: absolute;
	white-space: nowrap;
	width: 1px;
}
