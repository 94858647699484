.discuss {
	$this: &;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 196px;
	height: 196px;
	padding: 0;
	color: inherit;
	background-color: transparent;
	border: none;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		display: block;
		width: 196px;
		height: 196px;
		background-color: $color-brand-primary-new;
		border-radius: 50%;
		z-index: -1;
	}

	&__title {
		margin-bottom: 5px;
		text-align: center;
		font-size: 32px;
		font-weight: 600;
		line-height: 1;
		transition: color 0.3s;

		#{$this}:hover & {
			color: $color-primary;
		}
	}

	&__arrow-wrapper {
		width: 100%;
		height: 20px;
		position: absolute;
		left: 0;
		bottom: 20px;
		overflow: hidden;
	}

	&__arrow {
		transform: translateX(-50%);
		transition: transform 0.3s $easeInQuad;

		#{$this}:hover & {
			transform: translateX(0);
		}
	}
}
