.contact-us-popup {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	background: rgba(255, 255, 255, 0.98);
	z-index: 101;
	display: none;
	max-height: 100vh;
	overflow: auto;

	.form__limiter {
		padding-top: 50px;
	}

	&--active {
		display: block;

		@include from-breakpoint('sm') {
			display: flex;
			justify-content: center;
		}
	}

	&__content {
		width: 100%;
		height: 100%;
		max-width: 960px;
		margin-top: 36px;

		@include from-breakpoint('lg') {
			margin-top: 0;
		}
	}

	&__close {
		position: absolute;
		width: 18px;
		height: 18px;
		top: 60px;
		right: 30px;
		padding: 0;
		background-color: transparent;
		border: none;
		cursor: pointer;

		@include from-breakpoint('lg') {
			top: 30px;
			right: 30px;
		}

		fill: $color-black;
		color: $color-black;
	}

	@include from-breakpoint('md') {
		max-height: 80vh;
	}

	@include from-breakpoint('lg') {
		max-width: 795px;
		margin: 0 auto;
		margin-top: 100px;
	}
}
