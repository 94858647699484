@import '../common/_shared.scss';

.comparison-slider {
	position: relative;
	overflow: visible;
	margin-bottom: 24px;

	@include from-breakpoint('sm') {
		margin-bottom: 40px;
	}

	@include from-breakpoint('md') {
		margin-bottom: 0;
	}

	&__handle {
		position: absolute;
		z-index: 9;
		cursor: pointer;

		width: 32px;
		height: 32px;
		background-color: $color-light;
		border-radius: 50%;
		border: 9px solid $color-dark;
		bottom: -40px;
	}
	&__handle-legend {
		display: none;

		@include from-breakpoint('xl') {
			display: block;
			position: absolute;
			top: 0;
			word-break: normal;
			margin: 0;
			font-size: 14px;

			&::after {
				content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMBAMAAACkW0HUAAAAG1BMVEUAAAAwMDAzMzM1NTU0NDQ0NDQzMzMzMzMyMjJeCf/UAAAACXRSTlMAEO8wz4Bfr5DGIegHAAAAKUlEQVQI12NgYBAXYAACRlcGEBAJwOAwNDGAgVECmGJWw84VAFOMQAwAqcQDt5Ev4BMAAAAASUVORK5CYII=');
				position: absolute;
				top: 2px;
				line-height: 11px;
				vertical-align: middle;
			}

			&--before {
				right: 100%;
				margin-right: 42px;

				&::after {
					left: calc(100% + 13px);
				}
			}
			&--after {
				left: 100%;
				margin-left: 42px;
				&::after {
					right: calc(100% + 13px);
					transform: rotate(180deg);
					top: 1px;
				}
			}
		}
	}
	&__content {
		position: relative;
	}
	&__slide {
		position: absolute;
		width: auto;
		height: auto;
		overflow: hidden;
		top: 0;
		margin: 0;

		img {
			display: block;
		}

		&--before {
			img {
				max-width: none;
			}
		}
		&--after {
			img {
				display: block;
				width: 100%;
			}
		}
	}
	&__border {
		content: '';
		position: absolute;
		right: 0;
		top: -24px;
		bottom: -10px;
		width: 2px;
		background-color: $color-dark;
		box-shadow: 3px 0px 15px rgba(0, 0, 0, 0.37);
    border-radius: 14px;
	}
}
