.products {
	$block: &;

	margin-bottom: 130px;

	@include from-breakpoint('md') {
		margin-bottom: 150px;
	}

	@include from-breakpoint('xl') {
		margin-bottom: 200px;
	}

	&__title {
		margin: 0;
		margin-bottom: 40px;
		max-width: 688px;
		font-size: 40px;
		font-weight: 600;
		letter-spacing: -0.02em;
		line-height: 1;
		text-transform: uppercase;

		@include from-breakpoint('md') {
			font-size: 78px;
		}

		@include from-breakpoint('lg') {
			font-size: 103px;
		}

		@include from-breakpoint('xl') {
			font-size: 136px;
		}
	}

	&__list {
		margin: 0;
		padding: 0;
		max-width: 830px;
		list-style: none;
		display: flex;
		flex-wrap: wrap;
		gap: 8px;

		&--areas {
			margin-bottom: 8px;

			#{$block}__item {
				color: $color-light;
				background-color: $color-black;
			}
		}
	}

	&__item {
		@include body-2;

		padding: 8px 16px;
		border-radius: 40px;
		white-space: nowrap;
		background-color: $color-brand-primary-new;

		@include from-breakpoint('md') {
			@include body-1;
		}
	}
}
