.social {
	margin-left: auto;
	align-self: flex-end;

	&__link {
		display: inline-block;
		position: relative;
		padding: $base * 0.5 $base;
		font-family: $font-primary;
		font-size: $base;
		font-weight: bold;
		line-height: $base;
		text-transform: none;
		text-decoration: none;
		color: $color-tertiary;

		& + &::after {
			content: '';
			display: block;
			position: absolute;
			width: $base * 0.33;
			height: $base * 0.33;
			top: 50%;
			left: 0;
			transform: translate(-$base * 0.25, -50%) rotate(45deg);
			background: $color-text--muted;
		}
	}
}
