.portfolio-hero {
	margin: 100px 0 100px;
	display: flex;
	flex-direction: column;

	@include from-breakpoint('sm') {
		flex-direction: row;
		justify-content: space-between;
	}

	@include from-breakpoint('md') {
		margin: 140px 0 198px;
	}

	@include from-breakpoint('lg') {
		margin: 176px 0 198px;
	}

	&__title {
		display: inline-block;
		margin: 0 auto 0 0;
		text-transform: uppercase;
		font-size: 62px;
		font-weight: 600;
		line-height: 0.94;
		white-space: nowrap;

		@include from-breakpoint('md') {
			display: block;
			font-size: 80px;
			line-height: 0.94;
		}

		@include from-breakpoint('lg') {
			font-size: 126px;
			letter-spacing: 1px;
			line-height: 0.94;
		}

		p {
			margin: 0;
		}

		em,
		i {
			display: inline-block;
			text-indent: -4px;
			margin-right: 4px;

			@include from-breakpoint('lg') {
				text-indent: -6px;
				margin-right: 6px;
			}
		}
	}

	&__title-line2 {
		display: block;
	}

	&__title-icon {
		display: inline-block;
		width: 48px;
		height: 48px;
		background-size: contain;
		background-repeat: no-repeat;

		@include from-breakpoint('md') {
			width: 60px;
			height: 60px;
		}
		@include from-breakpoint('lg') {
			width: 96px;
			height: 96px;
		}

		&--services-1 {
			background-image: url('../images/services-1.svg');
			width: 56px;
			height: 56px;
			@include from-breakpoint('md') {
				width: 68px;
				height: 68px;
			}
			@include from-breakpoint('lg') {
				width: 113px;
				height: 113px;
			}
		}
		&--services-2 {
			position: relative;
			background-image: url('../images/services-2.svg');
			top: 4px;
			@include from-breakpoint('md') {
				top: 6px;
			}
			@include from-breakpoint('lg') {
				top: 9px;
			}
		}
	}

	&__content {
		font-size: $base;
		text-align: left;
		margin: 20px 0 0 0;
		line-height: 1.6;

		@include from-breakpoint('sm') {
			text-align: left;
			margin: 9px 0 0 30px;
		}
		@include from-breakpoint('lg') {
			max-width: 384px;
			margin: 25px 0 0 30px;
		}
	}
}
