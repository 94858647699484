@import '../common/_shared.scss';

.related-project {
	margin-bottom: 27px;

	&__image {
		margin-bottom: 25px;
		position: relative;
		padding-bottom: 79%;

		@include from-breakpoint('sm') {
			margin-bottom: 0;
		}
	}

	&__info {
		height: 100%;
		display: flex;
		flex-flow: column nowrap;
	}

	&__title {
		font-size: 22px;
		line-height: 1.1;
		font-family: $font-secondary;
		margin-bottom: 15px;

		@include from-breakpoint('sm') {
			margin-bottom: 8px;
		}

		@include from-breakpoint('md') {
			font-size: 24px;
			line-height: 0.71;
			margin-bottom: 15px;

			@include from-breakpoint('md') {
				line-height: 0.71;
			}
		}
	}

	&__description {
		color: $color-gray--d;
		margin-bottom: 15px;

		@include from-breakpoint('sm') {
			margin-bottom: 8px;
			font-size: 14px;
		}

		@include from-breakpoint('md') {
			font-size: 16px;
		}
	}

	&__links {
		margin-top: auto;
	}

	&__link {
		color: $color-primary;
		display: inline-block;
		margin-right: 24px;

		&:hover {
			text-decoration: none;
		}
	}
}