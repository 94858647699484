.generic-list {
	$block: &;
	$gap: $base;
	$gap--from-sm: 2 * $gap;

	max-width: 100%;
	padding: ($custom__header-height + $base) 0 3 * $base;
	@include from-breakpoint('md') {
		padding-top: $custom__header-height--md + $base;
	}
	@include from-breakpoint('lg') {
		padding-top: $custom__header-height--md + 45px;
	}

	&__title {
		@include h1;
		@include from-breakpoint('md') {
			margin-bottom: 66px;
		}
	}

	&__items {
		padding: 0.07px 0;

		&--3-in-row {
			// не знаю как назвать - переименуй при желании
			#{$block}__item:nth-child(3) {
				display: none;

				@include from-breakpoint('lg') {
					display: block;
				}
			}
		}

		&--2-in-row {
			#{$block}__item {
				@include from-breakpoint('lg') {
					flex-basis: 50%;
					max-width: 50%;
				}
			}
		}

		.service-page & {
			@include from-breakpoint('md') {
				max-width: 690px;
				margin-right: auto;
				margin-left: auto;
			}

			@include from-breakpoint('lg') {
				max-width: none;
			}
		}
	}

	&__items-inner {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: -$gap/2;

		@include from-breakpoint('sm') {
			margin: -$gap--from-sm/2;
		}

		& + & {
			margin-top: $gap/2;

			@include from-breakpoint('sm') {
				margin-top: $gap--from-sm/2;
			}
		}
	}

	&__item {
		display: flex;
		flex: 1 1 100%;
		max-width: 100%;
		padding: $gap/2;
		flex-grow: 0;

		@include from-breakpoint('sm') {
			flex-basis: 1 / 2 * 100%;
			max-width: 1 / 2 * 100%;
			padding: $gap--from-sm/2;
		}

		@include from-breakpoint('lg') {
			flex-basis: 1 / 3 * 100%;
			max-width: 1 / 3 * 100%;
		}
	}

	&__row {
		margin-top: 1.5 * $base;
		@include from-breakpoint('md') {
			margin-top: 2 * $base;
		}

		& + & {
			@include from-breakpoint('sm') {
				margin-top: 2 * $base;
			}
			@include from-breakpoint('lg') {
				margin-top: 4 * $base;
			}
		}
		&--hide-no-js {
			.no-js & {
				display: none;
			}
		}

		&--hide-js {
			.js & {
				display: none;
			}
		}
	}

	&__pagination {
		margin-top: 2 * $base;
	}

	&__loadmore {
		text-align: center;
	}

	&__container {
		@include from-breakpoint('sm') {
			margin-top: 2 * $base;
			padding: 0 36px;
		}
		@include from-breakpoint('md') {
			margin-bottom: 40px;
		}
		@include from-breakpoint('lg') {
			margin-top: 5 * $base;
		}
	}
}
