.simple-select {
	$block: &;

	font-family: $font-primary;
	font-weight: 500;
	color: $color-dark;
	font-size: 16px;
	border: none;
	border-radius: 0;
	padding: 11px 14px 11px 45px;
	appearance: none;
	background: $color-gray--l svg-load("../svg/arw-simple--down.svg", fill=$color-dark) no-repeat;
	background-position: 14px;
	background-size: 16px 9px;
	width: 100%;
	display: block;

	&::-ms-expand {
		display: none; /* hide the default arrow in ie10 and ie11 */
	}
}
