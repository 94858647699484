@import '../common/_shared.scss';

blockquote.blockquote-new {
	@include from-breakpoint('md') {
		border-left: none;
	}
}

.blockquote-new {
	$block: &;

	margin-top: 0;
	padding-top: 18px;
	position: relative;

	@include from-breakpoint('md') {
		width: 670px;
		margin: auto;
		padding: 20px 56px;
	}

	&__title {
		padding-bottom: 20px;
		border-bottom: 3px solid $color-primary;
		display: inline-block;
	}

	&__body {
		color: $color-tertiary;

		@include before-breakpoint('md') {
			position: relative;
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			opacity: 0.14;
			height: 36px;
			width: 36px;
		}

		&::before {
			left: 0;
			background: center / contain no-repeat
				svg-load('../svg/quote-left.svg', fill=#000, stroke=#fff);

			@include before-breakpoint('md') {
				top: -35px;
			}
		}

		&::after {
			right: 0;
			background: center / contain no-repeat
				svg-load('../svg/quote-right.svg', fill=#000, stroke=#fff);

			@include before-breakpoint('md') {
				bottom: -35px;
			}
		}

		@include from-breakpoint('md') {
			&::before,
			&::after {
				height: 41px;
				width: 44px;
			}
		}
	}

	footer#{$block}__footer {
		position: relative;
		margin-top: 25px;
		font-family: $font-secondary;

		&::before {
			content: '';
			left: 0;
			top: 0;
			display: block;
			height: 2px;
			width: 71px;
			background-color: $color-brand-primary;
			margin: 0 0 20px;
		}
	}
	&__author {
		font-weight: 500;
		font-size: 20px;
		color: $color-gray--d;
	}
	&__author-name {
		font-style: normal;
	}
	&__author-position {
		margin-top: 0;
	}
}
