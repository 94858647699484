.company {
	padding-bottom: $base * 2;
	@include from-breakpoint('md') {
		padding-bottom: $base * 4;
	}

	&__hero {
		display: none;
		@include from-breakpoint('md') {
			display: block;
		}
	}

	&__limiter {
		padding: 0 $base;
		max-width: $custom__limiter;
		margin: 0 auto;

		&--small {
			max-width: $custom__limiter--typography;
		}
	}

	&__content {
		padding-top: $custom__header-height + 40px;
		@include from-breakpoint('md') {
			padding-top: 0;
		}
	}
	&__select {
		@include from-breakpoint('md') {
			display: none;
		}
	}
}
