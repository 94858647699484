.error {
	$block: &;

	font-family: $font-primary;
	text-align: center;
	color: $color-dark;
	padding: 0 $base;
	margin: auto;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
	width: 100%;

	> * {
		margin: 0;

		+ * {
			margin-top: $base;
		}
	}

	&__logo {
		box-sizing: content-box;
		padding: 17px 15px;
		width: 40px;
		background: $color-brand-primary;
		display: block;

		@include from-breakpoint('md') {
			padding: 29px 22px;
			width: 67px;
			height: 23px;
		}
	}

	&__image {
		max-width: 100%;
		display: block;
		margin: auto;
	}

	&__title {
		color: inherit;
		font: 600 32px / normal $font-primary;
	}

	&__description {
		color: $color-dark;
		margin-top: 2 * $base;
		font-size: 20px;

		@include from-breakpoint('md') {
			margin-top: 4 * $base;
		}

		> * {
			margin: 0;

			+ * {
				margin-top: $base;
			}
		}

		ul {
			padding: 0;
			text-align: left;
			list-style: none inside;

			> li {
				&::before {
					content: '– ';
				}

				+ li {
					margin-top: $base/2;
				}
			}
		}

		a {
			&:hover,
			&:focus {
				text-decoration: none;
			}
		}
	}

	&__footer {
		padding: $base 0;
		font: 500 16px / 1.5 $font-primary;

		@include from-breakpoint('md') {
			padding: 2 * $base 0;
		}
	}

	&--padded {
		padding: $base;
	}

	&--padded-md {
		padding: 0;
		@include from-breakpoint('md') {
			padding: 0 $base;
		}
	}

	&--respect-header {
		padding-top: $custom__header-height + $base;

		@include from-breakpoint('md') {
			padding-top: $custom__header-height--md + $base;
		}
	}

	@include from-breakpoint('md') {
		> * + * {
			margin-top: 1.5 * $base;
		}
	}

	&--primary {
		color: $color-primary;

		#{$block} {
			&__description {
				a {
					color: $color-primary;
				}
			}
		}
	}

	&--tertiary {
		color: $color-tertiary;

		#{$block} {
			&__description {
				a {
					color: $color-tertiary;
				}
			}
		}
	}
}
