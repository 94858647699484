@import '../common/_shared.scss';

.switch-images {

  &__switch {

  }
  &__images {

  }
  &__image {

    &--default {
  
    }
    &--switched {
      display: none;
    }
  }
}