@import '~swiper/dist/css/swiper.min.css';
@import '../common/colors';
@import '../common/fonts';

.our-products {
	padding: 2vh 0 0;

	&__about {
		margin-bottom: $base * 2;
		p {
			font-family: $font-primary;
			line-height: 1.25;
			font-size: 18px;
			color: $color-text--muted;
			white-space: pre-line;
			margin: 1em 0 0;
		}

		h2 {
			@include h2;
		}

		hr {
			content: '';
			display: block;
			width: $base * 2;
			height: 2px;
			margin: 1.5em auto 0 0;
			border: 0;
			background: $color-tertiary;
		}
	}

	&__item {
		margin-bottom: $base * 2;

		h3 {
			@include h3;
			color: $color-tertiary;
			margin: 0 0 $base;
		}

		p {
			margin: 0 0 $base * 0.67;
		}

		a {
			position: relative;
			display: inline-block;
			font-size: $base * 0.88;
			font-weight: 500;
			color: $color-primary;

			&:focus,
			&:hover {
				text-decoration: none;
			}
		}
	}

	&__spotlight {
		position: relative;
		height: 100%;

		$padding: 22px;

		&::before,
		&::after {
			content: '';
			display: block;
			position: absolute;
			top: 0;
			bottom: 0;
			width: $padding;
			z-index: 1;
			@include from-breakpoint('lg') {
				content: none;
			}
		}

		&::before {
			background: linear-gradient(90deg, white, rgba(white, 0));
			left: 0;
		}

		&::after {
			background: linear-gradient(-90deg, white, rgba(white, 0));
			right: 0;
		}
	}

	&__spotlight-background {
		display: none;

		img {
			display: block;
			height: 100%;
			max-width: 100%;
		}
	}

	&__spotlight-list {
		display: flex;
		flex-wrap: nowrap;
		list-style: none;
		overflow: auto;
		// чтобы плашки плавно скроллились
		-webkit-overflow-scrolling: touch;
		margin: auto;
		padding: 0;
		height: 100%;
	}

	&__spotlight-highlight {
		padding: 22px;
		flex: 1 0 auto;
	}

	@include from-breakpoint('lg') {
		display: flex;
		align-items: center;
		width: 100%;
		position: relative;
		padding: 10vh 0 0;

		&__spotlight-list {
			overflow: visible;
			//	display: block;
			//	position: absolute;
			//	top: 0;
			//	bottom: 0;
			//	left: 0;
			//	width: 100% * 4 / 12;
			//	background: url('/media/1038/fill-1-copy-3.png') left 33% no-repeat;
			//	background-size: contain;
			//	margin: 0;
			//	//max-width: 430px;
		}

		&__spotlight-highlight {
			position: absolute;
			padding: 0;

			& + & {
				margin: 0;
			}

			&:nth-child(1) {
				left: 8%;
				top: 45%;
				transform: rotate(5deg) translateZ(0) scale(1.2);
			}

			&:nth-child(2) {
				left: 5%;
				top: 10%;
				transform: rotate(-5deg) translateZ(0);
			}
		}

		&__spotlight-background {
			display: block;
			position: absolute;
			right: 0;
			top: 0;
			width: 145%;
			height: 100%;
		}
	}
}
