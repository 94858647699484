@import url('~swiper/dist/css/swiper.min.css');

.spotlight {
	$block: &;

	position: relative;
	overflow: hidden; // to prevent x overflow pagination
	padding: 24px;
	height: 500px;
	background-color: $color-brand-primary-new;
	display: flex;
	align-items: center;

	@include from-breakpoint('md') {
		height: 536px;
		padding: 35px;
	}

	@include from-breakpoint('lg') {
		height: 731px;
	}

	&__background {
		position: absolute;
		overflow: hidden;
		width: 100%;
		height: 100%;
		top: 0;
		bottom: 0;
		left: 0;
		right: 100%;
		color: white;
		z-index: 0;

		@include picture__img() {
			object-position: left center !important;
		}
	}

	&__icons-wrapper {
		display: flex;
		height: 100%;
		min-width: fit-content;
		align-items: center;
	}

	&__svg {
		margin-left: -1px;
		width: calc(2476px * 0.75);
		height: 434px;

		@include from-breakpoint('lg') {
			height: 578px;
			width: 2476px;
			animation: spotlight 30s infinite linear;
		}
	}

	&__limiter {
		max-width: 800px;
		margin: 0 auto;
		position: relative;
		z-index: 1;

		@include from-breakpoint('lg') {
			max-width: 1024px;
			perspective: 200px;
		}
	}

	&__title {
		@include h1;

		position: relative;
		color: $color-dark;
		text-align: center;
		text-transform: uppercase;
		transition: transform 0.2s ease;
		will-change: transform;
		transform-style: preserve-3d;

		&::nth-child(3) {
			color: red;
		}
	}
}

@keyframes spotlight {
	100% {
		transform: translateX(-100%);
	}
}
