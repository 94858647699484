.social-icons {
	display: flex;
	justify-content: space-around;

	&__link {
		display: block;
		width: $base * 1.5;
		height: $base * 1.5;
		color: $color-text--muted;
		transition: 0.1s linear color;

		&:hover {
			color: $color-text;
		}
	}

	&__text {
		@include hidden-accessible;
	}

	&--black {
		.social-icons__link {
			color: $color-text;

			&:hover {
				color: $color-text--muted;
			}
		}
	}
}
