$base: 16px;
$base--xs: 12px;
$base--sm: 14px;
$base--md: 18px;

$h1-font-size--xs: 36px;
$h1-font-size--md: 72px;
$h1-font-size--lg: 90px;
$h1-font-size--xxl: 110px;
$h1-line-height: 1.1;

$h2-font-size--xs: 28px;
$h2-font-size--md: 42px;
$h2-font-size--lg: 56px;
$h2-line-height: 1;
$h2-line-height--xs: 1.2;

$h3-font-size--xs: 24px;
$h3-font-size--lg: 32px;
$h3-font-size--xl: 40px;
$h3-line-height: 1.1;

$h4-font-size--xs: 24px;
$h4-font-size--lg: 32px;
$h4-line-height: 1.1;

$h5-font-size--xs: 20px;
$h5-font-size--lg: 24px;
$h5-line-height: 1.2;

$h6-font-size: 20px;
$h6-line-height: 1.2;

$font-primary: 'TT-Firs-Neue', sans-serif;
$font-secondary: 'TT-Firs-Neue', sans-serif;

@mixin h1 {
	font: 600 #{$h1-font-size--xs}/#{$h1-line-height} $font-primary;
	letter-spacing: -0.02em;
	margin: 0;
	text-transform: uppercase;
	font-feature-settings: inherit;

	@include from-breakpoint('md') {
		font-size: $h1-font-size--md;
	}

	@include from-breakpoint('lg') {
		font-size: $h1-font-size--lg;
	}

	@include from-breakpoint('xxl') {
		font-size: $h1-font-size--xxl;
	}
}

@mixin h2 {
	font: 600 #{$h2-font-size--xs}/#{$h2-line-height--xs} $font-primary;
	letter-spacing: -0.02em;
	text-transform: uppercase;
	font-feature-settings: inherit;

	@include from-breakpoint('md') {
		font-size: $h2-font-size--md;
		line-height: $h2-line-height;
	}

	@include from-breakpoint('xl') {
		font-size: $h2-font-size--lg;
		line-height: $h2-line-height;
	}
}

@mixin h3 {
	font: 600 #{$h3-font-size--xs}/#{$h3-line-height} $font-primary;
	letter-spacing: -0.01em;
	font-feature-settings: inherit;

	@include from-breakpoint('lg') {
		font-size: $h3-font-size--lg;
	}

	@include from-breakpoint('xl') {
		font-size: $h3-font-size--xl;
	}
}

@mixin h4 {
	font: 600 #{$h4-font-size--xs}/#{$h4-line-height} $font-primary;
	font-feature-settings: inherit;

	@include from-breakpoint('lg') {
		font-size: $h4-font-size--lg;
	}
}

@mixin h5 {
	font: 600 #{$h5-font-size--xs}/#{$h5-line-height} $font-primary;
	font-feature-settings: inherit;

	@include from-breakpoint('lg') {
		font-size: $h5-font-size--lg;
	}
}

@mixin h6 {
	font: 600 #{$h6-font-size}/#{$h6-line-height} $font-primary;
	text-transform: uppercase;
	font-feature-settings: inherit;
}

@mixin body-1 {
	font: 400 #{$base--md}/#{1.4} $font-primary;
	font-feature-settings: inherit;
}

@mixin body-2 {
	font: 400 #{$base}/#{1.5} $font-primary;
	font-feature-settings: inherit;
}

@mixin button-text {
	font: 600 #{$base}/#{1.2} $font-primary;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	font-feature-settings: inherit;
}

@mixin subtitle {
	font: 600 #{$base--md}/#{1.4} $font-primary;
	font-feature-settings: inherit;
}

@mixin subtitle-2 {
	font: 400 #{$base--sm}/#{1.1} $font-primary;
	font-feature-settings: inherit;
}

@mixin caption {
	font: 400 #{$base--xs}/#{1.3} $font-primary;
	font-feature-settings: inherit;
}
