@import '../common/_shared.scss';

.image-map {
	$area-color: #4371f2;

	position: relative;

	&__image {
		max-width: 100%;
	}

	&__area {
		display: none;

		&:after {
			position: absolute;
			content: '';
			top: -2px;
			right: -2px;
			bottom: -2px;
			left: -2px;
			z-index: 1;

			border: 2px solid $area-color;

			opacity: 0;
			transition-property: opacity;
			transition-duration: 100ms;
			transition-timing-function: ease-in-out;
		}

		@include from-breakpoint('lg') {
			display: block;
			position: absolute;
			margin: 0;

			border-width: 2px;
			border-style: solid;
			border-color: $color-gray;

			animation: blinkingArea 2000ms infinite ease-in-out;
		}

		&:hover:after {
			opacity: 1;
		}
	}

	&__content {
		display: none;

		@include from-breakpoint('lg') {
			position: absolute;
			content: '';
			margin: 0 0 10px;

			background-color: $area-color;
			font-weight: 600;
			font-size: 16px;
			line-height: 1.37;
			letter-spacing: 0.01em;
			color: $color-text--contrast;
			padding: 10px 15px;
			min-height: 42px;
			max-width: 284px;
		}
	}
}

@keyframes blinkingArea {
	0% {
		border-color: $color-gray;
	}
	50% {
		border-color: $color-gray--ll;
	}
	100% {
		border-color: $color-gray;
	}
}
