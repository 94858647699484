@import '../common/_shared.scss';

$lime-color: #97c11f;
$fuse8-color: $color-brand-primary;

.forseti-case-header {
	.tags {
		margin-bottom: 0;

		@include from-breakpoint('lg') {
			margin-bottom: 15px;
		}
	}

	img {
		margin-top: 20px;
		margin-left: 0;

		@include from-breakpoint('lg') {
			margin-top: 0;
		}
	}

	h1 {
		margin-top: 22px;

		@include from-breakpoint('lg') {
			margin-top: 30px;
		}
	}
}

.forseti-intro-image {
	img {
		box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
	}

	@include from-breakpoint('md') {
		.picture {
			width: 345px;
		}

		.umb-macro-image-with-offset {
			width: 345px;
		}
	}
	@include from-breakpoint('xl') {
		.picture {
			width: 735px;
		}

		.umb-macro-image-with-offset {
			width: 735px;
		}
	}
}

.forseti-about {
	&__breakpoints {
		@include from-breakpoint('lg') {
			display: flex;
			justify-content: space-between;
			align-items: center;
			flex-flow: row nowrap;
		}
	}
	&__start {
	}
	&__final {
    @include from-breakpoint('lg') {
      margin-right: 34px;
			margin-top: 0;
		}
	}
	&__timeline {
		display: none;

		@include from-breakpoint('lg') {
			font-size: 16px;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: flex-start;
		}
	}
	&__timeline-start {
		height: 67px;
		width: 252px;

		background-image: url('../images/forseti/roadmap-1.png');
		background-repeat: no-repeat;
		background-position: top left;
		background-size: contain;

		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 5px;
	}
	&__timeline-final {
		height: 67px;
		margin-top: 0;
		flex-grow: 1;

		background-image: url('../images/forseti/roadmap-2.png');
		background-repeat: no-repeat;
		background-position: top left;
		background-size: contain;

		display: flex;
		justify-content: center;
		align-items: center;
		padding-top: 5px;

		span + span {
			position: relative;
			margin-left: 34px;
			margin-top: 0;

			&:before {
				position: absolute;
				content: '';
				top: 50%;
				left: -19px;
				transform: translateY(-50%);
				width: 4px;
				height: 4px;
				background-color: $color-brand-primary;
			}
		}
	}
}

.forseti-team {
	$team: &;

	@include before-breakpoint('md') {
		padding-top: 0;
		padding-bottom: 0;
	}

	&__headless-column {
		margin-top: 0;

		@include from-breakpoint('md') {
			margin-top: 4.3em;
		}

		@include from-breakpoint('lg') {
			margin-top: 4.9em;
		}

		& + & {
			margin-top: 16px;

			@include from-breakpoint('md') {
				margin-top: calc(7.4em + 34px);
			}
		}
	}

	&__title {
		display: inline-block;
		border-bottom: 5px solid;
		margin-top: 16px;

		&--lime {
			border-bottom-color: $lime-color;

			@include from-breakpoint('lg') {
				margin-top: 30px;
			}
		}

		&--fuse8 {
			border-bottom-color: $fuse8-color;

			@include from-breakpoint('md') {
				margin-top: 34px;
			}
		}
	}

	&__position {
		font-weight: 800;

		#{$team}__teammate + & {
			margin-top: 16px;

			@include from-breakpoint('md') {
				margin-top: 27px;
			}
		}
	}

	&__teammate {
		margin-top: 12px;
	}

	&__coin {
		display: none;

		@include from-breakpoint('lg') {
			display: block;
		}
	}
}

.forseti-preparation {
	@include before-breakpoint('lg') {
		padding-bottom: 0;

		& + * {
			padding-top: 0;
			padding-bottom: 0;
		}
	}
}

.forseti-note {
	color: $color-gray--d;

	@include before-breakpoint('md') {
		margin-top: 10px;
		padding: 10px 0;

		font-style: italic;
		border-top: 1px solid $color-gray--d;
		border-bottom: 1px solid $color-gray--d;
	}
}

.forseti-goal {
	@include from-breakpoint('lg') {
		display: flex;
		flex-flow: row nowrap;
	}

	&__arrow {
		display: none;

		@include from-breakpoint('lg') {
			display: block;
			min-width: 358px;
			width: 358px;
			margin-right: 38px;
		}
	}

	&__content {
		padding: 36px 18px 32px 30px;
		border: 2px solid $color-dark;
		box-shadow: -7px -8px 4px 0px $fuse8-color;
		box-sizing: border-box;

		@include from-breakpoint('lg') {
			margin-top: 0;
			padding: 46px 38px 26px 46px;
		}
	}
}

.forseti-specs-title {
	@include before-breakpoint('md') {
		padding-bottom: 0;
	}
}

.forseti-specs {
	@include before-breakpoint('md') {
		padding-top: 0;
		padding-bottom: 0;
	}
}

.forseti-spec {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
	align-items: flex-start;

	&__column {
		& + & {
			margin-top: 0;
			margin-left: 10px;
		}
	}
	&__image {
		box-shadow: 0px 0px 10px 3px rgba(104, 104, 104, 0.14);

		@include before-breakpoint('md') {
			& + & {
				margin-top: 8px;
			}
		}
	}
}

.forseti-technologies {
	@include before-breakpoint('md') {
		padding-bottom: 0;
	}
}

.forseti-switch {
	margin: 30px 0;
}

.forseti-logo-progress {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: space-between;
	margin-top: 16px;

	&__stage {
		@include before-breakpoint('md') {
			width: 92px;
			height: 92px;
		}

		& + & {
			margin-top: 0;
			position: relative;

			@include from-breakpoint('lg') {
				&:before {
					position: absolute;
					top: 50%;
					right: calc(100% + 39px);
					width: 54px;
					height: 20px;
					content: '';
					background-image: url('../images/forseti/logo-progress-arrow.png');
				}
			}
		}
	}
}

.forseti-goals-achieved {
	padding: 0 0 4px 12px;

	@include from-breakpoint('md') {
		padding: 30px 0px 16px 12px;
	}
}

.forseti-review {
	&__title {
		padding-bottom: 20px;
		border-bottom: 3px solid $color-primary;
		display: inline-block;
	}
}

.forseti-femida {
	display: none;
	@include from-breakpoint('lg') {
		display: block;
		width: 362px;
		position: absolute;
	}
}
