$project-per-row-breakpoints: (
	xs: 1,
	sm: 1,
	md: 2,
	lg: 3,
	xl: 3,
	xxl: 4,
);

.project-list {
	$block: &;

	display: flex;
	flex-flow: row wrap;
	transform-style: preserve-3d;
	perspective: 1400px;

	&__card {
		position: relative;
		display: block;
		overflow: hidden;
		color: $color-light;
		text-decoration: none;
		min-height: 50vh;

		@each $breakpoint, $count in $project-per-row-breakpoints {
			@include from-breakpoint($breakpoint) {
				flex: 1 1 percentage(1 / $count);
				max-width: percentage(1 / $count);
			}
		}

		@include from-breakpoint('md') {
			min-height: auto;
		}
	}

	&__background {
		position: absolute;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	&__logo {
		@include absolute-center;
		width: 50%;
		height: 50%;
		text-align: center;
		display: flex;
		align-items: center;
		justify-content: center;

		@include picture__img {
			object-fit: contain;
		}
	}

	&__overlay {
		display: flex;
		flex-flow: column nowrap;
		justify-content: flex-end;
		background: rgba($color-dark, 0.82);
		transition-duration: 0.38s;
		transition-property: transform;
		// transition out
		transition-timing-function: $easeInCubic;
		transform: translate(0, 0);

		@include from-breakpoint('lg') {
			transform: translate(0, 101%); // pixel rounding fix
		}

		#{$block}__card:hover &,
		#{$block}__card:focus &{
			transform: translate(0, 0);
			// transition in
			transition-timing-function: $easeOutCubic;
		}
	}

	&__overlay-inner {
		overflow: auto;
		padding: $base * 1.5 $base;

		@include from-breakpoint('md') {
			padding: $base * 1.5 $base * 1.25;
		}
	}

	&__title {
		font-family: $font-primary;
		font-size: $base * 1.5;
		font-weight: bold;
		line-height: 1.2;
		margin: 0;
	}

	&__tags {
		margin-top: $base;
	}

	&__loadmore {
		margin: $base * 3 0;
	}

	&__awards-list {
		position: absolute;
		width: 100%;
		top: 0;
		transition-duration: 0.38s;

		transition-property: transform;
		// transition out
		transition-timing-function: $easeInQuart;
		display: flex;
		padding-bottom: 9px; // равен высоте уголочка ленты
		padding-left: 20px;


		#{$block}__card:hover &,
		#{$block}__card:focus & {
			@include from-breakpoint("lg") {
				transform: translate(0, -100%);
				// transition in
				transition-delay: 0.038s;
				transition-timing-function: $easeOutQuart;
			}
		}
	}

	&__awards-icon {
		width: 48px;
		height: 48px;
		max-width: 80%;
		max-height: 80%;
		color: $color-text--contrast;
	}

	&__awards-item {
		width: 15%;
		min-width: 60px;
		min-height: 70px;
		max-width: 72px;
		max-height: 88px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-light;
		box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.16);
		margin-right: $base;
		position: relative;

		&::before,
		&::after {
			height: 9px;
			position: absolute;
			content: '';
			width: 50%;
			bottom: -9px;
			border-top: 9px solid white;
		}
		&::before {
			left: 0;
			border-right: 35px solid transparent;
		}
		&::after {
			right: 0;
			border-left: 35px solid transparent;
		}
	}

	&__awards-img {
		height: 100%;
		object-fit: cover;
		object-position: center;
		width: 100%;
	}

	&--4-in-row {
		// не знаю как назвать - переименуй при желании
		#{$block}__card {
			&:nth-child(3) {
				display: none;

				@include from-breakpoint('md') {
					display: block;
				}
			}

			&:nth-child(4) {
				display: none;

				@include from-breakpoint('md') {
					content: 'md';
					display: block;
				}

				@include from-breakpoint('lg') {
					content: 'lg';
					display: none;
				}

				@include from-breakpoint('xxl') {
					content: 'xxl';
					display: block;
				}
			}
		}
	}
}
