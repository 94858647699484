@import '../common/_shared.scss';

.service-page {
	$block: &;

	&__hero {
		margin-bottom: 80px;
		padding-top: 160px;

		@include from-breakpoint('md') {
			margin-bottom: 100px;
			padding-top: 200px;
		}
	}

	&__hero-wrapper {
		position: relative;
	}

	&__title {
		@include h1;

		margin-bottom: 14px;
		text-transform: uppercase;

		@include from-breakpoint('md') {
			margin-bottom: 40px;
		}

		@include from-breakpoint('lg') {
			margin-bottom: 80px;
		}

		@include from-breakpoint('xl') {
			margin-bottom: 40px;
		}
	}

	&__first-line,
	&__second-line {
		display: flex;
		align-items: center;
		gap: 16px;

		@include from-breakpoint('md') {
			gap: 24px;
		}

		@include from-breakpoint('xl') {
			gap: 32px;
		}
	}

	&__first-line {
		#{$block}__title-icon {
			width: 56px;
			height: 56px;
			background-image: url('../images/services-1.svg');

			@include from-breakpoint('md') {
				margin-bottom: 5px;
				width: 80px;
				height: 80px;
			}

			@include from-breakpoint('lg') {
				width: 113px;
				height: 113px;
			}
		}
	}

	&__second-line {
		white-space: nowrap;

		#{$block}__title-icon {
			background-image: url('../images/services-2.svg');
		}
	}

	&__title-icon {
		width: 50px;
		height: 50px;
		flex-shrink: 0;
		background-repeat: no-repeat;
		background-size: contain;

		@include from-breakpoint('md') {
			width: 72px;
			height: 72px;
		}

		@include from-breakpoint('lg') {
			width: 96px;
			height: 96px;
		}
	}

	&__hero-content {
		@include from-breakpoint('lg') {
			display: flex;
			justify-content: space-between;
			gap: 47px;
		}

		@include from-breakpoint('xl') {
			align-items: center;
		}
	}

	&__description {
		@include body-2;

		margin-bottom: 28px;
		padding-left: 68px;

		@include from-breakpoint('md') {
			margin-bottom: 48px;
			padding-left: unset;
		}

		@include from-breakpoint('lg') {
			margin-bottom: 0;
			order: 2;
		}

		@include from-breakpoint('xl') {
			@include body-1;

			margin-bottom: 5px;
		}
	}

	&__hero-cta {
		text-decoration: none;
		color: inherit;

		@include from-breakpoint('lg') {
			order: 1;

			&:hover,
			&:focus-visible {
				#{$block}__hero-image:nth-of-type(2) {
					z-index: 4;
					transform: scale(1.7);
				}

				#{$block}__cta-text {
					opacity: 1;
				}
			}
		}
	}

	&__hero-image {
		position: relative;
		width: 145px;
		height: 145px;
		flex-shrink: 0;
		overflow: hidden;
		cursor: pointer;
		transition: transform $transition-default;

		@include from-breakpoint('md') {
			width: 190px;
			height: 190px;
		}

		@include from-breakpoint('lg') {
			width: 170px;
			height: 170px;
		}

		@include from-breakpoint('xl') {
			width: 240px;
			height: 240px;
		}

		img {
			position: relative;
			z-index: 1;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&:nth-of-type(1) {
			width: 120px;
			z-index: 3;
			clip-path: url(#svgPathTriangle);

			@include from-breakpoint('md') {
				width: 140px;
			}

			@include from-breakpoint('xl') {
				width: 190px;
			}
		}

		&:nth-of-type(2n) {
			z-index: 2;
			margin-left: -38px;
			clip-path: url(#svgPathRhombus);

			@include from-breakpoint('xl') {
				margin-left: -67px;
			}
		}

		&:nth-of-type(3n) {
			z-index: 1;
			margin-left: -32px;
			clip-path: url(#svgPathCircle);

			@include from-breakpoint('xl') {
				margin-left: -52px;
			}
		}
	}

	&__cta-text {
		@include subtitle-2;

		position: absolute;
		top: 50%;
		left: 50%;
		z-index: 2;
		width: 95px;
		height: 95px;
		display: flex;
		justify-content: center;
		align-items: center;
		color: $color-light;
		text-align: center;
		background-color: $color-black;
		opacity: 0;
		border-radius: 50%;
		transform: translateX(-50%) translateY(-50%);
		transition: opacity $transition-default;
		cursor: pointer;
	}

	&__images-wrapper {
		margin-bottom: 15px;
		display: flex;
		align-items: center;
		justify-content: center;

		@include from-breakpoint('md') {
			margin-bottom: 10px;
			justify-content: flex-start;
		}
	}

	&__hero-text {
		@include button-text;

		display: flex;
		align-items: center;
		justify-content: center;
		gap: 16px;
		text-transform: uppercase;

		svg {
			width: 21px;
			height: 26px;
		}

		@include from-breakpoint('md') {
			justify-content: flex-start;
		}

		@include from-breakpoint('lg') {
			display: none;
		}
	}

	&__load-more-wrapper {
		@include from-breakpoint('lg') {
			margin-top: 34px;
		}
	}

	&__load-more {
		width: 100%;
		padding: 20px;
		font-size: 18px;
		line-height: 1.5;
		font-weight: 400;
		color: $color-dark;
		border: 2px solid $color-brand-primary;
		border-radius: 8px;
		text-transform: initial;
		transition: all 0.1s linear;

		&:hover {
			color: $color-light;
			background-color: $color-brand-primary;
			border: 2px solid $color-brand-primary;
			transition: all 0.1s linear;
		}
	}

	&__subtitle {
		& > h2 {
			font: 600 24px/1.33 $font-secondary;
			margin-bottom: 24px;
			margin-top: 0;

			@include from-breakpoint('md') {
				max-width: 690px;
				margin-right: auto;
				margin-left: auto;
				font-size: $h2-font-size--lg;
				margin-bottom: 32px;
			}

			@include from-breakpoint('lg') {
				max-width: none;
			}
		}
	}

	&__wrapper {
		max-width: 690px;
		margin: 16px auto 0;

		@include from-breakpoint('md') {
			margin-top: 35px;
		}

		@include from-breakpoint('xl') {
			max-width: initial;
			margin: 30px 0 0;
		}
	}

	&__related-articles {
		padding: 0;

		@include from-breakpoint('lg') {
			padding: 40px 0;
			background: #fdfdfd;
		}
	}

	&__related-services {
		padding: 40px 0;

		@include from-breakpoint('lg') {
			padding: 60px 0;
		}

		.service-page & {
			@include from-breakpoint('md') {
				max-width: 690px;
				margin-right: auto;
				margin-left: auto;
			}

			@include from-breakpoint('lg') {
				max-width: none;
			}
		}
	}
}
