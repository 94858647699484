.hero {
	$block: &;

	position: relative;
	padding: (25px + $custom__header-height) $base 1.5 * $base;
	display: flex;
	flex-flow: column nowrap;
	color: $color-background;

	&__fade {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
	}

	&__background {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
	}

	&__background-part {
		position: absolute;

		&--projects {
			left: 0;
			bottom: 0;
			width: 100%;
		}
	}

	&__fixer {
		// https://github.com/philipwalton/flexbugs#flexbug-3
		display: flex;
		flex-flow: column nowrap;

		&--show-from-md {
			display: none;

			@include from-breakpoint('md') {
				// https://github.com/philipwalton/flexbugs#flexbug-3
				display: flex;
				flex-flow: column nowrap;
			}
		}
	}

	&__row {
		position: relative;
		overflow: hidden;
		flex: 1 0 auto;
		margin: 0 auto;
		max-width: 960px;
		width: 100%;

		&--footer,
		&--middle {
			display: flex;
		}

		&--middle {
			padding: 30px 0;
		}

		&--footer {
			flex-flow: column nowrap;
			justify-content: flex-end;
		}
	}

	&__img {
		margin: auto;
		max-width: 50%;
		max-height: 25vh;
		height: 100%;
		width: 100%;
		text-align: center; // for amp-img

		> * {
			max-height: 25vh;
		}

		@include picture__img {
			object-fit: contain;
		}

		&--hidden {
			@include hidden-accessible;
		}
	}

	&__back {
		margin: 0 0 $base (-$base/2);
	}

	&__title {
		@include h1;
	}

	&__tags {
		margin-top: 15px;
		position: relative;
	}

	$padding: 4px;

	&__tags-inner {
		list-style: none;
		margin: 0 -$padding;
		padding: 0;

		display: flex;
		flex-wrap: wrap;

		&--scroll {
			overflow-x: auto;
			flex-wrap: nowrap;

			&::before,
			&::after {
				content: '';
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				width: $padding;
				z-index: 1;
			}

			&::before {
				background: linear-gradient(90deg, white, rgba(white, 0));
				left: -$padding;
			}

			&::after {
				background: linear-gradient(-90deg, white, rgba(white, 0));
				right: -$padding;
			}
		}
	}

	&__tag {
		padding: $padding;
		white-space: nowrap;
	}

	&__details {
		margin-top: $base;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	&--scene {
		min-height: 50vh;
		padding: (35px + $custom__header-height) 35px 35px;
		transition: min-height 0.2s ease-in;
	}

	&--dark {
		color: $color-dark;
	}

	&--light {
		color: $color-light;
	}

	@include from-breakpoint('md') {
		padding: (35px + $custom__header-height--md) 35px 35px;
		min-height: calc(
			#{(556px / map_get($breakpoints, 'lg') * 100vh)} + #{$custom__header-height--md}
		);

		&__tags {
			margin-top: 52px;
		}

		&__background-part {
			&--projects {
				@include picture__img() {
					object-position: 85% bottom;
				}

				height: 480px;
			}
		}
	}

	@include from-breakpoint('lg') {
		min-height: 556px + $custom__header-height--md;

		&__background-part {
			&--projects {
				@include picture__img() {
					object-position: right bottom;
				}
			}
		}
	}
	@include from-breakpoint('xl') {
		&--scene {
			min-height: 100vh;
		}
	}

	@include from-breakpoint('xxl') {
		&__background-part {
			&--projects {
				@include picture__img() {
					object-position: unset;
				}
			}
		}
	}
}
