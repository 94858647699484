@import '../common/colors';
@import '../common/fonts';

.button {
	$block: &;
	$border-offset: 1px;
	$border-width: 1px;

	@mixin hover-state {
		&::before {
			opacity: 0;
		}
		&::before,
		&::after {
			transform: translate(0, 0);
		}
	}

	@include button-text;

	padding: 19px 24px;
	position: relative;
	display: inline-block;
	text-decoration: none;
	background: transparent;
	border: none;
	cursor: pointer;
	user-select: none;
	white-space: nowrap;
	text-align: center;
	transition: color $transition-default, background-color $transition-default;

	&__loader {
		position: absolute;
		display: block;
		overflow: hidden;
		bottom: 0;
		top: 0;
		left: 0;
		right: 0;

		&::before {
			content: '';
			display: block;
			height: 100%;
			width: 100%;
			opacity: 0.55;
			animation: loading 2s infinite;
			background: currentColor;

			#{$block}--services & {
				background: $color-brand-primary;
			}
		}

		&[hidden] {
			display: none;
		}
	}

	&__text {
		transform: translateZ(0);

		+ #{$block}__icon,
		#{$block}__icon + & {
			margin-left: $base / 2;
		}
	}

	&__icon {
		margin: -3px 0 -3px 0;
		width: 20px;
		height: 16px;
		display: inline-block;
		color: currentColor;

		&[hidden] {
			display: none;
		}
	}

	&--contured {
		color: $color-text;
		padding: $base * 0.625 $base;
		transition: color 0.1s linear;

		&::before,
		&::after {
			position: absolute;
			content: '';
			border: $border-width solid $color-text--muted;
			transition-property: transform, background, top, left, right, bottom,
				opacity;
			transition-duration: 0.1s;
			transition-timing-function: linear;
			pointer-events: none;
			top: 0;
			right: 0;
			bottom: 0;
			left: 0;
		}

		&::before {
			opacity: 1;
			transform: translate(-$border-offset, $border-offset);
		}

		&::after {
			transform: translate($border-offset, -$border-offset);
		}

		&:hover {
			@include hover-state;
		}
	}

	&--share {
		color: $color-light;
		text-transform: none;
		display: inline-flex;
		align-items: center;
		justify-content: center;

		&::after {
			border: none;
			opacity: 0.85;
		}

		#{$block} {
			&__icon,
			&__text {
				position: relative;
				z-index: 1;
			}

			&__text {
				// for vertical centering
				margin-top: 1px;
				margin-bottom: -1px;
				font-weight: 500;
			}
		}

		&#{$block} {
			&--twitter {
				&::after {
					background: $color-social--twitter;
				}
			}

			&--facebook {
				&::after {
					background: $color-social--facebook;
				}
			}

			&--vk {
				&::after {
					background: $color-social--vk;
				}
			}

			&--google {
				&::after {
					background: $color-social--google;
				}
			}
		}

		&:hover {
			&::after {
				opacity: 1;
			}
		}
	}

	&--solid {
		color: #fff;
		padding: 10px 18px;
	}

	&--filled {
		padding: 19px 24px;
		color: $color-light;
		background-color: $color-contrast;
		transition: color $transition-default background-color
			$transition-default;

		&:hover,
		&:focus-visible,
		&:active {
			color: $color-contrast;
			background-color: transparent;
			border: 1px solid $color-contrast;
		}
	}

	&--filled-black {
		padding: 19px 24px;
		color: $color-light;
		background-color: $color-black;
		border: 1px solid $color-black;
		transition: color $transition-default,
			background-color $transition-default;

		&:hover,
		&:focus-visible,
		&:active {
			color: $color-black;
			background-color: transparent;
		}
	}

	&--primary {
		color: $color-black;
		background-color: $color-brand-primary-new;

		&:hover,
		&:focus-visible {
			background-color: rgba($color-brand-primary-new, 0.7);
		}

		&:disabled {
			pointer-events: none;
			background-color: rgba($color-brand-primary-new, 0.7);
		}

		&#{$block}--contured {
			&:hover {
				color: $color-primary;
			}

			&::after {
				border-color: $color-primary;
			}
		}
	}

	&--secondary {
		&#{$block}--contured::after {
			border-color: $color-secondary;
		}
	}

	&--tertiary {
		&#{$block}--contured::after {
			border-color: $color-tertiary;
		}
	}

	&--quaternary {
		&#{$block}--contured::after {
			border-color: $color-quaternary;
		}

		&#{$block}--solid {
			background: $color-quaternary;

			&:hover,
			&:focus {
				background: $color-quaternary--d;
			}
		}
	}

	&--cta {
		padding: 10px 26px;
		border: 1px solid $color-contrast;
		background-color: $color-contrast;
		text-transform: uppercase;
	}

	&:disabled {
		cursor: not-allowed;

		&#{$block}--contured {
			color: $color-disabled;

			&,
			&:hover,
			&:focus,
			&::after {
				border-color: $color-disabled;
			}

			@include hover-state;
		}

		&#{$block}--solid {
			&,
			&:hover,
			&:focus {
				background: $color-disabled;
			}
		}
	}

	&:disabled#{$block}--sending,
	&--sending {
		color: $color-black;

		&#{$block}--contured:after {
			border-color: $color-black;
		}
	}

	&--full {
		display: block;
	}

	@include from-breakpoint('sm') {
		&--contured {
			padding: $base * 0.625 $base * 1.4375;
		}
	}

	&--project {
		&#{$block}--contured {
			color: $color-primary;
			font-size: 16px;
			transition: color 0.1s linear, background-color 0.1s linear;
			padding: 19px 0 15px;
			width: 100%;

			@include from-breakpoint('sm') {
				width: auto;
				padding: 19px 50px 15px;
			}

			@include from-breakpoint('md') {
				font-size: 18px;
				padding: 17px 32px;
			}

			&:hover {
				color: $color-light;
				background-color: $color-primary;
			}

			&::after {
				border-color: $color-primary;
			}
		}
	}
}

@keyframes loading {
	0% {
		transform: translateX(-100%);
	}
	20% {
		transform: translateX(-100%);
	}
	55% {
		transform: translateX(0);
	}
	65% {
		transform: translateX(0);
	}
	100% {
		transform: translateX(100%);
	}
}
