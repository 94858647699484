@import '../common/_shared.scss';

.feedback {
	$this: &;

	padding: 0;

	.row {
		position: relative;
	}

	&__container {
		max-width: 100%;
		margin-bottom: 60px;
		padding-bottom: 90px;
	}

	&__list {
		list-style: none;
		padding: 0;
		flex-wrap: nowrap;
		align-items: stretch;

		@include from-breakpoint('md') {
			padding-left: 15px;
			padding-right: 15px;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			gap: 24px;
			align-content: flex-start;
			max-height: 1840px;
			align-items: unset;
		}
	}

	&__navigation {
		position: absolute;
		bottom: 0;
		left: 50%;
		width: 138px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		transform: translateX(-50%);

		@include from-breakpoint('md') {
			display: none;
		}
	}

	&__chevron {
		width: 40px;
		height: 50px;
		color: $color-black;
	}

	&__prev,
	&__next {
		position: static;
		padding: 0;
		width: 40px;
		height: 50px;
		color: $color-black;
		border: none;
		background-color: transparent;
		background-image: none;
	}

	&__prev {
		svg {
			transform: rotate(180deg);
		}
	}

	&__item-wrapper {
		align-self: stretch;

		@include from-breakpoint('md') {
			flex-grow: unset;
			width: 47%;
			height: auto;
		}

		@include from-breakpoint('lg') {
			width: 48%;
		}

		&.is-overflowed {
			#{$this}__text {
				max-height: 285px;
				overflow: hidden;
			}

			#{$this}__text::after {
				display: block;
			}
		}
	}

	&__item {
		display: flex;
		flex-direction: column;
		padding: 24px;
		margin: 0 auto;
		width: calc(100% - 30px);
		min-height: 530px;
		height: 100%;
		border: 1px solid $color-border;

		@include from-breakpoint('md') {
			width: unset;
			height: min-content;
			min-height: unset;
		}
	}

	&__picture {
		margin-bottom: 25px;
		width: 140px;
		height: 60px;
		object-fit: contain;
		object-position: top left;

		@include from-breakpoint('md') {
			position: relative;
			width: 150px;
			height: 70px;
		}

		.picture--static.picture,
		.picture--static .picture__el,
		.picture--static img {
			object-fit: contain;
			object-position: top left;
			margin: unset;
		}
	}

	&__text,
	&__popup-text {
		@include body-2;

		position: relative;
		margin: 0 0 16px;

		@include from-breakpoint('lg') {
			@include body-1;
		}

		&::after {
			content: '';
			display: none;
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			height: 80px;
			background: linear-gradient(180deg, transparent, $color-light);
		}
	}

	&__text-wrapper {
		margin-bottom: 20px;

		@include from-breakpoint('md') {
			margin-bottom: 0;
		}
	}

	&__popup-text {
		margin-top: 75px;
	}

	&__btn {
		@include body-2;

		display: none;
		padding: 0;
		margin: 0;
		background-color: transparent;
		border: none;
		cursor: pointer;

		&.is-shown {
			display: block;
		}
	}

	&__name {
		@include body-2;

		margin-top: auto;

		@include from-breakpoint('md') {
			margin-top: 50px;
		}

		@include from-breakpoint('lg') {
			@include body-1;
		}
	}

	&__position {
		@include body-2;

		@include from-breakpoint('lg') {
			@include body-1;
		}
	}

	&__popup {
		position: fixed;
		z-index: 101;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;
		padding: 24px 24px 50px;
		background-color: $color-light;
		border: 1px solid $color-border;

		&.is-active {
			display: block;
		}

		#{$this}__picture {
			position: absolute;
			left: 24px;
			top: 24px;
		}

		@include from-breakpoint('md') {
			display: none;
		}
	}

	&__popup-content {
		max-height: 520px;
		overflow-y: auto;
	}

	&__popup-close {
		position: absolute;
		right: 0;
		top: 0;
		z-index: 102;
		width: 68px;
		height: 70px;
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $color-black;
		color: $color-light;
		transition: background-color $transition-default;

		svg {
			width: 35px;
			height: 35spx;
		}

		&:hover,
		&:focus-visible {
			background-color: rgba($color-black, 0.7);
		}
	}
}
