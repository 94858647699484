.block {
	position: relative;

	&__limiter {
		margin: auto;
		padding: 0 $base;
		max-width: 1200px;

		&--small {
			max-width: 960px;
		}

		&--text {
			max-width: 690px + 2 * $base;
		}
	}
}
