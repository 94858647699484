.portfolio-banner {
	$this: &;

	margin-bottom: 86px;
	width: 100%;

	@include from-breakpoint('md') {
		margin: 0 auto 86px;
		width: 688px;
	}

	img, picture {
		max-width: 100%;
	}
}
