[id].anchor {
	$block: &;

	@include hidden-accessible;
	margin-top: -$custom__header-height !important;

	@include from-breakpoint("md") {
		margin-top: -$custom__header-height--md !important;
	}
}
