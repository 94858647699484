.features-list {
	margin-bottom: 80px;

	@include from-breakpoint('md') {
		margin-bottom: 100px;
	}

	&__title {
		@include h2;

		margin: 0;

		@include from-breakpoint('md') {
			margin-bottom: 48px;
			max-width: 550px;
		}

		@include from-breakpoint('lg') {
			max-width: 730px;
			font-size: $h2-font-size--lg;
		}
	}

	&__list {
		@include reset-list;
	}

	&__item {
		padding: 30px 0;
		border-bottom: 1px solid $color-border;

		&:last-child {
			border: none;
		}

		@include from-breakpoint('md') {
			display: flex;
			gap: 48px;
			justify-content: space-between;
		}
	}

	&__subtitle {
		margin: 0;
		margin-bottom: 40px;
		max-width: 249px;
		font-size: 24px;
		font-weight: 600;
		line-height: 1.2;

		@include from-breakpoint('md') {
			margin-bottom: 0;
		}

		@include from-breakpoint('lg') {
			max-width: 300px;
		}

		@include from-breakpoint('xl') {
			max-width: 420px;
		}
	}

	&__copy {
		@include body-1;

		@include from-breakpoint('md') {
			margin-left: auto;
			width: 332px;
			flex-grow: 0;
		}

		@include from-breakpoint('lg') {
			width: 440px;
		}

		@include from-breakpoint('xl') {
			margin-right: 153px;
		}
	}
}
