.soar-enter-active,
.soar-leave-active {
	transition-property: opacity, transform;
	transition-duration: $timing * 2.5;
}
.soar-enter {
	opacity: 0;
	transition-timing-function: $easeInCubic;
	transform: translate(0, $base * 2);
}
.soar-leave-to {
	opacity: 0;
	transition-timing-function: $easeOutCubic;
	transform: translate(0, $base * -2);
}

.fade-zoom-enter-active,
.fade-zoom-leave-active {
	transition-property: opacity, transform;
	transition-duration: $timing * 2.5;
}

.fade-zoom-enter {
	opacity: 0;
	transition-timing-function: $easeInCubic;
	transform: scale(.95);
}

.fade-zoom-leave-to {
	opacity: 0;
	transition-timing-function: $easeOutCubic;
	transform: scale(.95);
}

.fade-in-zoom-enter-active {
	transition-property: opacity, transform;
	transition-duration: $timing
}

.fade-in-zoom-enter {
	opacity: 0;
	transition-timing-function: $easeInCubic;
	transform: scale(.95);
}
